import React, { useEffect } from 'react'
import { Grid, useToggle } from 'react-md'
import { FormContext, useForm } from 'react-hook-form'

import { handleSubmit, InputDatePicker, InputDecimal, InputNumber, InputTime, Select } from 'components/form'
import { TechnicianAutoComplete } from 'components/auto-complete'
import { PurgersTestedOptions } from 'types/sale/client-equipment'
import { VoltageOptions } from 'types/core/voltage'
import { Modal } from 'components/core'

import { handleGetEquipment, handleCreateOrUpdateTechnicalStart } from './actions'
import { defaultValues, validationSchema } from './config'

interface ComponentProps {
  clientId: string
  id: string | null
  onSuccess: () => void
  onClose: () => void
}

const Component: React.FC<ComponentProps> = ({ clientId, id, onSuccess, onClose }) => {
  const form = useForm({ defaultValues })
  const [visibleModal, enableModal, disableModal] = useToggle(false)

  useEffect(() => {
    loadEquipment()
  }, [clientId, id])

  const loadEquipment = async () => {
    if (clientId && id) {
      let data = await handleGetEquipment(clientId, id)
      form.reset(data, { dirty: false })
      enableModal()
    }
  }

  return (
    <Modal
      title={'Partida Técnica'}
      visible={visibleModal}
      confirmTitle={'Salvar'}
      confirmDisabled={!form.formState.dirty}
      confirmAction={async () => {
        await handleSubmit(form, validationSchema, async () => {
          await handleCreateOrUpdateTechnicalStart({ clientId, equipmentId: id, ...form.getValues() })
          form.reset(defaultValues, { dirty: false })
          disableModal()
          onSuccess()
        })
      }}
      closeTitle={'Fechar'}
      closeAction={async () => {
        form.reset(defaultValues, { dirty: false })
        disableModal()
        onClose()
      }}>
      <Grid>
        <FormContext {...form}>
          <TechnicianAutoComplete name='responsibleTechnician' label='Técnico Responsável *' tabletSize={4} />
          <InputNumber name='workRegime' label='Regime de Trabalho *' desktopSize={4} tabletSize={4} />
          <InputDatePicker name='date' label='Data da Partida Técnica *' desktopSize={4} tabletSize={4} />
          <Select name='voltage' label='Voltagem' options={VoltageOptions} desktopSize={4} tabletSize={4} />
          <InputDecimal name='chainR' label='Corrente R' desktopSize={4} tabletSize={4} />
          <InputDecimal name='chainS' label='Corrente S' desktopSize={4} tabletSize={4} />
          <InputDecimal name='chainT' label='Corrente T' desktopSize={4} tabletSize={4} />
          <InputDecimal name='motorThermalRegulation' label='Regulagem Térmico do Motor' desktopSize={4} tabletSize={4} />
          <InputDecimal name='compressorCableGauge' label='Bitola do Cabo do Compressor' desktopSize={4} tabletSize={4} />
          <InputDecimal name='generatorPurityDegree' label='Grau de Pureza da Geradora' desktopSize={4} tabletSize={4} />
          <InputNumber name='compressorTemperatureInRelief' label='Temperatura do Compressor em Alívio' desktopSize={6} tabletSize={4} />
          <InputNumber name='compressorTemperatureAtLoad' label='Temperatura do Compressor em Carga' desktopSize={6} tabletSize={4} />
          <InputTime name='airReservoirFillingTime' label='Tempo de Enchimento do Reservatório de Ar' desktopSize={6} tabletSize={4} />
          <InputTime name='airReservoirDischargeTime' label='Tempo de Descarga do Reservatório de Ar' desktopSize={6} tabletSize={4} />
          <InputTime name='oxygenReservoirFillingTime' label='Tempo de Enchimento do Reservatório de Oxigênio' desktopSize={6} tabletSize={4} />
          <Select name='purgersTested' label='Pergadores Testados' options={PurgersTestedOptions} desktopSize={6} tabletSize={4} />
          <InputNumber name='dryerDewPoint' label='Ponto de Orvalho do Secador' desktopSize={6} tabletSize={4} />
          <InputNumber name='generatorDewPoint' label='Ponto de Orvalho da Geradora' desktopSize={6} tabletSize={4} />
        </FormContext>
      </Grid>
    </Modal>
  )
}

export default Component
