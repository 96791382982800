import React from 'react'
import { TextField } from 'react-md'

import Cell from 'components/core/cell'
import { FieldProps } from 'components/form/types'
import { formatCurrency } from 'types/core/currency'

interface LabelProps extends FieldProps {
  label: string
  value: number | null | undefined
}

export const DecimalLabel = React.memo(({ name, label, value, desktopSize, tabletSize }: LabelProps) => {
  return (
    <Cell tabletSize={tabletSize} desktopSize={desktopSize}>
      <TextField id={name} name={name} theme={'underline'} disabled={true} label={label} value={formatCurrency(Number(value ?? 0))} />
    </Cell>
  )
})
