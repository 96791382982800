import { AxiosRequestConfig } from 'axios'
import { trackPromise } from 'react-promise-tracker'

import api from 'utils/api'
import { ApiResponse } from 'utils/types'

const headerConfig: AxiosRequestConfig = {
  responseType: 'arraybuffer',
}

const url = '/v1/inventory/report'
const Api = {
  part: (request: any): Promise<any> => trackPromise<ApiResponse>(api.post(`${url}/part`, request, headerConfig)),
  equipment: (request: any): Promise<any> => trackPromise<ApiResponse>(api.post(`${url}/equipment`, request, headerConfig)),
}

export default Api
