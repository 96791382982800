import Yup from 'utils/yup'

export const defaultValues = {
  companyId: null,
  client: {
    id: null,
  },
  supplier: {
    id: null,
  },
  status: null,
  documentNumber: null,
  initialDueDate: null,
  finalDueDate: null,
  initialPaymentOrReceveidDate: null,
  finalPaymentOrReceveidDate: null,
  statusOfPaymentOrReceveidMade: null,
}

export const validationSchema = Yup.object().shape({
  companyId: Yup.string().nullable(),
  client: Yup.object({
    id: Yup.string().nullable(),
  }),
  supplier: Yup.object({
    id: Yup.string().nullable(),
  }),
  status: Yup.string().nullable(),
  documentNumber: Yup.string().nullable(),
  initialDueDate: Yup.date().nullable().required(),
  finalDueDate: Yup.date().nullable().required(),
  initialPaymentOrReceveidDate: Yup.date().nullable(),
  finalPaymentOrReceveidDate: Yup.date().nullable(),
  statusOfPaymentOrReceveidMade: Yup.string().nullable(),
})
