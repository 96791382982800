import React from 'react'
import { Grid } from '@react-md/utils'
import { Text } from 'react-md'

import { Cell } from 'components/core'
import Address from 'components/address'

import { FormContextValues } from 'react-hook-form'

interface ComponentProps {
  form: FormContextValues<any>
}

const Component: React.FC<ComponentProps> = ({ form }) => {
  return (
    <Grid>
      <Cell>
        <Text type='headline-6' margin='none'>
          Endereço
        </Text>
      </Cell>
      <Cell>
        <Grid>
          <Address form={form} />
        </Grid>
      </Cell>
    </Grid>
  )
}

export default Component
