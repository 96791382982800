import Yup from 'utils/yup'

export const defaultValues = {
  name: null,
  email: null,
  login: null,
  profileId: null,
  password: null,
  confirmPassword: null,
}

export const validationSchema = Yup.object().shape({
  name: Yup.string().nullable().required().min(3, 'O nome deve ter no mínimo 3 caracteres').max(40, 'O nome deve ter no máximo 40 caracteres'),
  email: Yup.string().nullable().required().email('E-mail inválido').max(40, 'O email deve ter no máximo 40 caracteres'),
  login: Yup.string().nullable().required().min(5, 'O login deve ter no mínimo 5 caracteres').max(20, 'O login deve ter no máximo 20 caracteres'),
  profileId: Yup.string().nullable().required(),
  password: Yup.string()
    .required()
    .min(6, 'A senha deve ter no mínimo 6 caracteres')
    .max(20, 'A senha deve ter no máximo 20 caracteres')
    .test('passwords-match', 'A confirmação de senha está incorreta', function (value) {
      return this.parent.confirmPassword === value
    }),
  confirmPassword: Yup.string()
    .required()
    .min(6, 'A senha deve ter no mínimo 6 caracteres')
    .max(20, 'A senha deve ter no máximo 20 caracteres')
    .test('passwords-match', 'A confirmação de senha está incorreta', function (value) {
      return this.parent.password === value
    }),
})
