import React, { useEffect, useState } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FontIcon,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Text,
  Tooltipped,
} from 'react-md'

import { handleSubmit, InputArea, InputNumber, Select } from 'components/form'
import { ButtonSuccess, Modal } from 'components/core'
import ButtonError from 'components/core/button-error'
import { useAuth } from 'contexts/auth/auth-context'
import { TextLabel } from 'components/label'
import { Theme } from 'contexts/auth/types'

import { ServicePerformedOptions, VisitTypeOptions } from 'types/technical-visit/technical-visit'

import { defaultValues, validationSchema } from './config'
import { handleSave } from './actions'

interface ComponentProps {
  technicalVisitId: string
  equipment: any
  visibleModal: boolean
  disableModal: () => void
  loadTechnicalVisit: () => Promise<void>
}

const Component: React.FC<ComponentProps> = ({ technicalVisitId, equipment, visibleModal, disableModal, loadTechnicalVisit }) => {
  const { theme } = useAuth()
  const form = useForm({ defaultValues })
  const [actions, setActions] = useState<Array<any>>()

  useEffect(() => {
    let equipmentValues = equipment
    delete equipmentValues.maintenanceAction
    form.reset(equipmentValues)
  }, [equipment])

  useEffect(() => {
    setActions(equipment.maintenanceActions.map((x: any) => ({ technicalVisitMaintenanceActionId: x.id, done: x.done })))
  }, [equipment.maintenanceActions])

  const actionIsDone = (id: string) => actions?.find(x => x.technicalVisitMaintenanceActionId === id)?.done

  const setActionAllStatus = (done: boolean) => setActions(actions?.map((x: any) => ({ ...x, done: done })))

  const setActionStatus = (id: string) =>
    setActions(actions?.map((x: any) => ({ ...x, done: x.technicalVisitMaintenanceActionId === id ? !x.done : x.done })))

  return (
    <Modal
      title={'Finalização de Equipamento'}
      visible={visibleModal}
      confirmTitle={'Salvar'}
      confirmDisabled={equipment.done || !form.formState.dirty}
      confirmAction={async () => {
        await handleSubmit(form, validationSchema, async () => {
          if (actions?.every(x => x.done !== undefined)) {
            await handleSave({ technicalVisitId, equipmentId: equipment.equipmentId, maintenanceActions: actions, ...form.getValues() })
            form.reset(defaultValues, { dirty: false })
            loadTechnicalVisit()
            disableModal()
          } else {
            toast.warn('Informe a realização das manutenções para prosseguir')
          }
        })
      }}
      closeTitle={'Fechar'}
      closeAction={async () => {
        form.reset(defaultValues, { dirty: false })
        disableModal()
      }}>
      <Grid>
        <FormContext {...form}>
          <TextLabel name='equipmentName' label='Equipamento' value={equipment.equipmentModel} />
          <Select name='visitType' label='Tipo da Visita *' disabled={equipment.done} options={VisitTypeOptions} desktopSize={6} tabletSize={4} />
          <Select
            name='servicePerformed'
            label='Serviço Realizado *'
            disabled={equipment.done}
            options={ServicePerformedOptions}
            desktopSize={6}
            tabletSize={4}
          />
          <InputNumber name='hourmeter' label='Horímetro *' disabled={equipment.done} desktopSize={6} tabletSize={4} />
          <InputNumber
            name='adjustmentHours'
            label='Horas de Ajuste'
            type='number'
            negativeNumbers={true}
            disabled={equipment.done}
            desktopSize={6}
            tabletSize={4}
          />
          <InputArea name='observation' label='Observações' disabled={equipment.done} rows={3} maxRows={3} desktopSize={12} tabletSize={8} />
        </FormContext>
      </Grid>
      {equipment?.maintenanceActions && equipment.maintenanceActions.length > 0 && (
        <Card fullWidth>
          <CardHeader>
            <Text type='headline-6' margin='none'>
              Ações de Manutenção
            </Text>
          </CardHeader>
          <CardContent>
            <TableContainer>
              <Table fullWidth>
                <TableBody>
                  {equipment.maintenanceActions?.map((action: any, index: number) => (
                    <TableRow key={index}>
                      <TableCell grow>{action.description}</TableCell>
                      <TableCell align='right'>
                        {actionIsDone(action.id) === undefined && (
                          <Tooltipped id={`action-done-${index}`} tooltip={'Ação não Definida'} position={'above'}>
                            <Button
                              id={`button-done-${index}`}
                              buttonType='icon'
                              themeType='contained'
                              style={
                                theme === Theme.Dark
                                  ? { backgroundColor: '#ff8f00', color: '#FFFFFF', margin: 2 }
                                  : { backgroundColor: '#ffd54f', color: '#212121', margin: 2 }
                              }
                              disabled={equipment.done}
                              onClick={() => setActionStatus(action.id)}>
                              <FontIcon>do_not_disturb_on</FontIcon>
                            </Button>
                          </Tooltipped>
                        )}
                        {actionIsDone(action.id) === true && (
                          <Tooltipped id={`action-done-${index}`} tooltip={'Ação Realizada'} position={'above'}>
                            <Button
                              id={`button-done-${index}`}
                              buttonType='icon'
                              themeType='contained'
                              style={
                                theme === Theme.Dark
                                  ? { backgroundColor: '#00695C', color: '#FFFFFF', margin: 2 }
                                  : { backgroundColor: '#4DB6AC', color: '#212121', margin: 2 }
                              }
                              disabled={equipment.done}
                              onClick={() => setActionStatus(action.id)}>
                              <FontIcon>thumb_up</FontIcon>
                            </Button>
                          </Tooltipped>
                        )}

                        {actionIsDone(action.id) === false && (
                          <Tooltipped id={`action-done-${index}`} tooltip={'Ação não Realizada'} position={'above'}>
                            <Button
                              id={`button-not-done-${index}`}
                              buttonType='icon'
                              themeType='contained'
                              style={
                                theme === Theme.Dark
                                  ? { backgroundColor: '#d32f2f', color: '#FFFFFF', margin: 2 }
                                  : { backgroundColor: '#e57373', color: '#212121', margin: 2 }
                              }
                              disabled={equipment.done}
                              onClick={() => setActionStatus(action.id)}>
                              <FontIcon>thumb_down</FontIcon>
                            </Button>
                          </Tooltipped>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
          <CardActions>
            <ButtonSuccess
              text='Todos Realizados'
              theme='secondary'
              icon={<FontIcon>thumb_up</FontIcon>}
              onClick={async () => setActionAllStatus(true)}
              disabled={equipment.done}
            />
            <ButtonError
              text='Todos Não Realizados'
              theme='warning'
              icon={<FontIcon>thumb_down</FontIcon>}
              onClick={async () => setActionAllStatus(false)}
              disabled={equipment.done}
            />
          </CardActions>
        </Card>
      )}
    </Modal>
  )
}

export default Component
