import React, { useEffect, useState } from 'react'
import { TableCell, TableRow } from 'react-md'

import { DataTableResult } from 'components/data-table/types'
import { DataTable } from 'components/data-table'

import { handleGetMaintenanceActions } from './actions'

interface ComponentProps {
  id: string
}

const Component: React.FC<ComponentProps> = ({ id }) => {
  const [filter, setFilter] = useState<any>()
  const [result, setResult] = useState<DataTableResult>({ total: 0, results: [] })

  useEffect(() => {
    setFilter({ equipmentId: id })
  }, [id])

  return (
    <DataTable
      filter={filter}
      header={[{ label: 'Ação de Manutenção' }, { label: 'Próxima Manutenção em Horas' }, { label: 'Próxima Manutenção em Dias' }]}
      result={result}
      setResult={setResult}
      search={handleGetMaintenanceActions}
      total={result ? result.total : 0}
      showEmptyMessageAlert={false}
      emptyMessage={'Nenhuma manutenção cadastrada'}
      wrapEmptyMessageWithCard={true}>
      {result &&
        result.results &&
        result.results.map((data: any, index) => (
          <TableRow key={index}>
            <TableCell>{data.maintenanceActionDescription}</TableCell>
            <TableCell>
              {data.nextMaintenanceInHours === 1 ? `${data.nextMaintenanceInHours} hora` : `${data.nextMaintenanceInHours} horas`}
            </TableCell>
            <TableCell>{data.nextMaintenanceInDays === 1 ? `${data.nextMaintenanceInDays} dia` : `${data.nextMaintenanceInDays} dias`}</TableCell>
          </TableRow>
        ))}
    </DataTable>
  )
}

export default Component
