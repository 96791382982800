import React, { useEffect, useState } from 'react'
import { format, parseISO } from 'date-fns'
import { ExpansionPanel, Grid } from 'react-md'

import { Cell } from 'components/core'

import PanelInfo from './panel-info'

interface PanelProps {
  data: any
  toogleReset: boolean
}

const Component: React.FC<PanelProps> = ({ data, toogleReset }) => {
  const [expanded, setExpanded] = useState<boolean>(false)

  useEffect(() => {
    setExpanded(false)
  }, [toogleReset])

  return (
    <ExpansionPanel
      id='panel-audit'
      header={`${format(parseISO(data.dateTime), "dd'/'MM'/'yyyy' 'HH:mm:ss")} - ${data.userName ?? 'Sistema'} - ${data.eventName}`}
      expanded={expanded}
      onExpandClick={() => setExpanded(!expanded)}>
      <Grid>
        <Cell>
          {data.values.map((data: any, index: any) => (
            <PanelInfo key={index} data={data.itens} sectionName={data.sectionName} />
          ))}
        </Cell>
      </Grid>
    </ExpansionPanel>
  )
}

export default Component
