import { DataTableRequest } from 'components/data-table/types'
import api from 'services/inventory/equipment'

export const handleGetTechnicalVisitDones = async (request: DataTableRequest) => {
  let res = await api.searchTechnicalVisitDone(request)

  return res?.data ?? []
}

export const handleGet = async (id: string) => {
  let res = await api.getFinishEquipment(id)

  return res?.data?.data
}

export const handleEdit = (id: string) => {
  window.open(`/technical-visit/edit/${id}`)
}
