import React, { useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { TextArea, FormMessage } from 'react-md'
import { usePromiseTracker } from 'react-promise-tracker'

import Cell from 'components/core/cell'

import { FieldProps } from './types'

interface InputAreaProps extends FieldProps {
  rows: number
  maxRows: number
}

export const InputArea = ({ name, desktopSize, tabletSize, disabled, rows, maxRows, ...rest }: InputAreaProps) => {
  const [text, setText] = useState<string | null>('')
  const { promiseInProgress } = usePromiseTracker()
  const form = useFormContext()
  const formValue = form.watch(name)

  useEffect(() => {
    form.register({ name })
  }, [form.register])

  useEffect(() => {
    setText(formValue ? String(formValue) : '')
  }, [formValue])

  return (
    <Cell tabletSize={tabletSize} desktopSize={desktopSize}>
      <TextArea
        id={name}
        name={name}
        theme={'underline'}
        rows={rows}
        maxRows={maxRows}
        error={form.errors[name] ? true : false}
        disabled={disabled || promiseInProgress}
        value={text ?? ''}
        onChange={selected => {
          if (form.errors[name]) form.clearError(name)
          if (selected.target.value) {
            form.setValue(name, selected.target.value)
            setText(selected.target.value)
          } else {
            form.setValue(name, null)
            setText(null)
          }
        }}
        {...rest}
      />
      {form.errors[name] && (
        <FormMessage id={name} error style={{ paddingLeft: 0 }}>
          {form.errors[name].message}
        </FormMessage>
      )}
    </Cell>
  )
}
