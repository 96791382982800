import Yup from 'utils/yup'

export const infoDefaultValues = {
  responsibleTechnician: {
    id: null,
  },
  fantasyName: null,
  companyName: null,
  document: null,
  ie: null,
  region: null,
  businessModel: null,
  situation: null,
}

export const infoValidationSchema = Yup.object().shape({
  responsibleTechnician: Yup.object({
    id: Yup.string().nullable(),
  }),
  fantasyName: Yup.string().nullable().required().max(60, 'O nome fantasia deve ter no máximo 60 caracteres'),
  companyName: Yup.string().nullable().required().max(60, 'O nome fantasia deve ter no máximo 60 caracteres'),
  document: Yup.string().nullable().required().max(18, 'O cpf/cnpj deve ter no máximo 18 caracteres'),
  ie: Yup.string().nullable().max(14, 'A inscrição estadual deve ter no máximo 14 caracteres'),
  region: Yup.string().nullable().required(),
  businessModel: Yup.string().nullable().required(),
  situation: Yup.string().nullable().required(),
})
