import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { FormContext, useForm } from 'react-hook-form'
import { Card, CardActions, CardContent, FontIcon, Grid, useToggle } from 'react-md'

import { ButtonLog, Cell, PageTitle, ButtonSuccess, ButtonEdit, Modal } from 'components/core'
import { handleSubmit, Input, InputNumber } from 'components/form'

import { defaultValues, quantityDefaultValues, quantityValidationSchema, validationSchema } from './config'
import { handleAddQuantity, handleGet, handleRemoveQuantity, handleSave } from './actions'
import { NumberLabel } from 'components/label'
import ButtonError from 'components/core/button-error'

const Component: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const form = useForm({ defaultValues })
  const quantityForm = useForm({ defaultValues: quantityDefaultValues })
  const [visibleAddModal, enableAddModal, disableAddModal] = useToggle(false)
  const [visibleRemoveModal, enableRemoveModal, disableRemoveModal] = useToggle(false)
  const [part, setPart] = useState<any>({})

  useEffect(() => {
    document.title = 'Edição de Peça'
  }, [])

  useEffect(() => {
    load()
  }, [id])

  const load = async () => {
    const data = await handleGet(id)
    if (data) {
      form.reset(data)
      setPart(data)
    }
  }

  return (
    <>
      <Grid>
        <Cell>
          <PageTitle title='Edição de Peça' />
          <Card fullWidth>
            <CardContent>
              <FormContext {...form}>
                <Grid>
                  <Input name='name' label='Nome *' desktopSize={3} tabletSize={4} />
                  <Input name='code' label='Código/Part Number *' desktopSize={2} tabletSize={4} />
                  <Input name='manufacturerCode' label='Código do Fabricante *' desktopSize={2} tabletSize={4} />
                  <Input name='manufacturerName' label='Nome do Fabricante *' desktopSize={3} tabletSize={4} />
                  <Input name='address' label='Endereço' desktopSize={2} tabletSize={4} />
                  <NumberLabel name='quantity' label='Quantidade *' value={part.quantity} desktopSize={2} tabletSize={4} />
                  <Input name='equipmentModels' label='Modelos de Equipamento *' desktopSize={10} tabletSize={4} />
                </Grid>
              </FormContext>
            </CardContent>
            <CardActions>
              <ButtonSuccess
                text='Salvar'
                icon={<FontIcon>done</FontIcon>}
                disabled={!form.formState.dirty}
                onClick={async () =>
                  await handleSubmit(form, validationSchema, async () => {
                    if (await handleSave({ ...form.getValues(), id: id })) form.reset(form.getValues(), { dirty: false })
                  })
                }
              />
              <ButtonEdit text='Adicionar Quantidade' theme='secondary' icon={<FontIcon>add</FontIcon>} onClick={async () => enableAddModal()} />
              <ButtonError text='Remover Quantidade' theme='warning' icon={<FontIcon>remove</FontIcon>} onClick={async () => enableRemoveModal()} />
              <ButtonLog entity='Part' id={id} />
            </CardActions>
          </Card>
        </Cell>
      </Grid>

      <Modal
        title={'Adicionar Quantidade'}
        width={300}
        visible={visibleAddModal}
        confirmTitle={'Salvar'}
        confirmDisabled={!quantityForm.formState.dirty}
        confirmAction={async () => {
          await handleSubmit(quantityForm, quantityValidationSchema, async () => {
            if (await handleAddQuantity({ id, ...quantityForm.getValues() })) {
              quantityForm.reset(quantityDefaultValues, { dirty: false })
              load()
              disableAddModal()
            }
          })
        }}
        closeTitle={'Fechar'}
        closeAction={async () => {
          quantityForm.reset(quantityDefaultValues, { dirty: false })
          disableAddModal()
        }}>
        <Grid>
          <FormContext {...quantityForm}>
            <InputNumber name='quantity' label='Quantidade *' />
          </FormContext>
        </Grid>
      </Modal>

      <Modal
        title={'Remover Quantidade'}
        width={300}
        visible={visibleRemoveModal}
        confirmTitle={'Salvar'}
        confirmDisabled={!quantityForm.formState.dirty}
        confirmAction={async () => {
          await handleSubmit(quantityForm, quantityValidationSchema, async () => {
            if (await handleRemoveQuantity({ id, ...quantityForm.getValues() })) {
              quantityForm.reset(quantityDefaultValues, { dirty: false })
              load()
              disableRemoveModal()
            }
          })
        }}
        closeTitle={'Fechar'}
        closeAction={async () => {
          quantityForm.reset(quantityDefaultValues, { dirty: false })
          disableRemoveModal()
        }}>
        <Grid>
          <FormContext {...quantityForm}>
            <InputNumber name='quantity' label='Quantidade *' />
          </FormContext>
        </Grid>
      </Modal>
    </>
  )
}

export default Component
