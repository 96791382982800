import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { toast } from 'react-toastify'
import Cookies from 'universal-cookie'

const getBaseUrl = () => (window.location.host.indexOf('localhost') >= 0 ? 'http://localhost:5000' : 'https://api.oxynit.ind.br')

const cookies = new Cookies()

const api = axios.create({
  baseURL: getBaseUrl(),
})

api.defaults.headers = {
  'Content-Type': 'application/json',
}

api.defaults.validateStatus = status => status >= 200 && status < 300

api.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    if (!config.url!.endsWith('token') && !config.url!.endsWith('forgot-password') && !config.url!.endsWith('change-password-by-code')) {
      let token = cookies.get('token')
      if (token) {
        let data = JSON.parse(atob(token.split('.')[1]))
        if (new Date() > new Date(data.exp * 1000)) return Promise.reject({ response: { status: 401, data: 'Token Expirado' } })
        else config.headers.Authorization = `Bearer ${token}`
      } else {
        return Promise.reject({
          response: { status: 401, data: 'Acesso Negado' },
        })
      }
    }
    return config
  },
  error => {
    return Promise.reject(error)
  },
)

api.interceptors.response.use(
  (response: AxiosResponse) => {
    if (response.status === 200 && response?.data?.success && response?.data?.message && (response.data.message as string))
      toast.success(response.data.message)

    return response
  },
  error => {
    if (error && error.response) {
      const { status, data } = error.response

      if (status === 401 && data === 'Token Expirado')
        toast.error('Token Expirado', {
          onClose: () => {
            cookies.remove('token', { path: '/', sameSite: 'strict' })
            window.location.href = '/'
          },
        })
      else if (status === 401 && data === 'Acesso Negado')
        toast.error('Acesso Negado', {
          onClose: () => {
            cookies.remove('token', { path: '/', sameSite: 'strict' })
            window.location.href = '/'
          },
        })
      else if (status === 400 && data && Array.isArray(data.data)) data.data.map((x: any) => toast.warn(x.message))
      else if (status === 400 && data && data.message) toast.warn(data.message)
      else if (status === 429 && data) toast.warn(data)
      else if (status === 500) toast.error('Um erro ocorreu ao processar a requisição')
    } else if (error.message == 'Network Error') {
      toast.error('Não foi possível conectar, verifique sua internet')
    }
  },
)

export default api
