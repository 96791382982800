import React, { useEffect, useState } from 'react'
import { GridCell, useAppSize } from 'react-md'

interface Props {
  phoneSize?: number | null
  phoneStart?: number | null
  phoneEnd?: number | null
  tabletSize?: number | null
  tabletStart?: number | null
  tabletEnd?: number | null
  desktopSize?: number | null
  desktopStart?: number | null
  desktopEnd?: number | null
  style?: React.CSSProperties
  [x: string]: any
}

const Cell: React.FC<Props> = ({
  phoneSize,
  phoneStart,
  phoneEnd,
  tabletSize,
  tabletStart,
  tabletEnd,
  desktopSize,
  desktopStart,
  desktopEnd,
  style,
  children,
  ...rest
}) => {
  const appSize = useAppSize()
  const [sizeColumn, setSizeColumn] = useState<number>()
  const [startColumn, setStartColumn] = useState<number>()
  const [endColumn, setEndColumn] = useState<number>()

  useEffect(() => {
    if (appSize.isPhone) {
      setSizeColumn(phoneSize ? phoneSize : 4)
      setStartColumn(phoneStart ?? undefined)
      setEndColumn(phoneEnd ?? undefined)
    } else if (appSize.isTablet || (appSize.isTablet && appSize.isLandscape)) {
      setSizeColumn(tabletSize ? tabletSize : 8)
      setStartColumn(tabletStart ?? undefined)
      setEndColumn(tabletEnd ?? undefined)
    } else {
      setSizeColumn(desktopSize ? desktopSize : 12)
      setStartColumn(desktopStart ?? undefined)
      setEndColumn(desktopEnd ?? undefined)
    }
  }, [appSize.isPhone, appSize.isTablet, appSize.isDesktop, appSize.isLargeDesktop])

  return (
    <GridCell colSpan={sizeColumn} colStart={startColumn} colEnd={endColumn} {...rest} style={{ ...style, margin: 5 }}>
      {children}
    </GridCell>
  )
}

export default Cell
