export interface PurgersTestedOption {
  value: PurgersTestedEnum | null
  label: string
}

export enum PurgersTestedEnum {
  AllOk = 'AllOk',
  SomeOK = 'SomeOK',
}

export const PurgersTestedOptions: Array<PurgersTestedOption> = [
  { value: null, label: '' },
  { value: PurgersTestedEnum.AllOk, label: 'Todos OK' },
  { value: PurgersTestedEnum.SomeOK, label: 'Alguns OK' },
]
