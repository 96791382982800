import Yup from 'utils/yup'

export const defaultValues = {
  equipment: {
    id: null,
  },
  equipmentStatus: null,
}

export const validationSchema = Yup.object().shape({
  equipment: Yup.object({
    id: Yup.string().nullable().required(),
  }),
  equipmentStatus: Yup.string().nullable().required(),
})
