import { LayoutNavigationItem, LayoutNavigationTree } from 'react-md'

import { MenuPermission } from 'components/layout/components/menu/types'

interface getMenuProps {
  permissions: MenuPermission
  menu: LayoutNavigationTree<LayoutNavigationItem>
  getMenu: (id: string, parent: string | null, title: string, icon: string, path: string | null) => LayoutNavigationItem
}

export const getRegisterMenu = ({ permissions, menu, getMenu }: getMenuProps) => {
  if (
    permissions.inventory.maintenanceSchedule ||
    permissions.sale.client ||
    permissions.financial.company ||
    permissions.financial.supplier ||
    permissions.system.profile ||
    permissions.technicalVisit.technician ||
    permissions.system.user
  ) {
    menu['/register'] = getMenu('/register', null, 'Cadastros', 'manage_accounts', null)

    if (permissions.inventory.maintenanceSchedule) {
      menu['/inventory/maintenance-schedule'] = getMenu(
        '/inventory/maintenance-schedule',
        '/register',
        'Agenda de Manutenção',
        'pending_actions',
        null,
      )
      menu['/inventory/maintenance-schedule/new'] = getMenu(
        '/inventory/maintenance-schedule/new',
        '/inventory/maintenance-schedule',
        'Nova',
        'add',
        '/inventory/maintenance-schedule/new',
      )
      menu['/inventory/maintenance-schedule/search'] = getMenu(
        '/inventory/maintenance-schedule/search',
        '/inventory/maintenance-schedule',
        'Pesquisa',
        'search',
        '/inventory/maintenance-schedule/search',
      )
    }

    if (permissions.sale.client) {
      menu['/sale/client'] = getMenu('/sale/client', '/register', 'Cliente', 'contacts', null)
      menu['/sale/client/new'] = getMenu('/sale/client/new', '/sale/client', 'Novo', 'add', '/sale/client/new')
      menu['/sale/client/search'] = getMenu('/sale/client/search', '/sale/client', 'Pesquisa', 'search', '/sale/client/search')
    }

    if (permissions.financial.company) {
      menu['/financial/company'] = getMenu('/financial/company', '/register', 'Empresa', 'apartment', null)
      menu['/financial/company/new'] = getMenu('/financial/company/new', '/financial/company', 'Nova', 'add', '/financial/company/new')
      menu['/financial/company/search'] = getMenu(
        '/financial/company/search',
        '/financial/company',
        'Pesquisa',
        'search',
        '/financial/company/search',
      )
    }

    if (permissions.financial.supplier) {
      menu['/financial/supplier'] = getMenu('/financial/supplier', '/register', 'Fornecedor', 'person', null)
      menu['/financial/supplier/new'] = getMenu('/financial/supplier/new', '/financial/supplier', 'Novo', 'add', '/financial/supplier/new')
      menu['/financial/supplier/search'] = getMenu(
        '/financial/supplier/search',
        '/financial/supplier',
        'Pesquisa',
        'search',
        '/financial/supplier/search',
      )
    }

    if (permissions.system.profile) {
      menu['/system/profile'] = getMenu('/system/profile', '/register', 'Perfil', 'recent_actors', null)
      menu['/system/profile/new'] = getMenu('/system/profile/new', '/system/profile', 'Novo', 'group_add', '/system/profile/new')
      menu['/system/profile/search'] = getMenu('/system/profile/search', '/system/profile', 'Pesquisa', 'search', '/system/profile/search')
    }

    if (permissions.technicalVisit.technician) {
      menu['/technical-visit/technician'] = getMenu('/technical-visit/technician', '/register', 'Técnico', 'engineering', null)
      menu['/technical-visit/technician/new'] = getMenu(
        '/technical-visit/technician/new',
        '/technical-visit/technician',
        'Novo',
        'add',
        '/technical-visit/technician/new',
      )
      menu['/technical-visit/technician/search'] = getMenu(
        '/technical-visit/technician/search',
        '/technical-visit/technician',
        'Pesquisa',
        'search',
        '/technical-visit/technician/search',
      )
    }

    if (permissions.system.user) {
      menu['/system/user'] = getMenu('/system/user', '/register', 'Usuário', 'person', null)
      menu['/system/user/new'] = getMenu('/system/user/new', '/system/user', 'Novo', 'add', '/system/user/new')
      menu['/system/user/search'] = getMenu('/system/user/search', '/system/user', 'Pesquisa', 'search', '/system/user/search')
    }
  }
}
