import React, { useEffect } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { Card, CardActions, CardContent, FontIcon, Grid } from 'react-md'

import { Cell, PageTitle, ButtonSuccess } from 'components/core'
import { handleSubmit, InputDatePicker, InputTime } from 'components/form'

import { defaultValues, validationSchema } from './config'
import { handleSave } from './actions'
import { ClientAutoComplete, TechnicianAutoComplete } from 'components/auto-complete'

const Component: React.FC = () => {
  const form = useForm({ defaultValues })

  useEffect(() => {
    document.title = 'Nova Visita Técnica'
  }, [])

  return (
    <Grid>
      <Cell>
        <PageTitle title='Nova Visita Técnica' />
        <Card fullWidth>
          <CardContent>
            <FormContext {...form}>
              <Grid>
                <ClientAutoComplete name='client' label='Cliente *' desktopSize={4} tabletSize={8} />
                <TechnicianAutoComplete name='technician' label='Técnico *' desktopSize={4} tabletSize={8} />
                <InputDatePicker name='visitDate' label='Data da Visita *' desktopSize={2} tabletSize={4} />
                <InputTime name='visitTime' label='Horário da Visita *' desktopSize={2} tabletSize={4} />
              </Grid>
            </FormContext>
          </CardContent>
          <CardActions>
            <ButtonSuccess
              text='Salvar'
              icon={<FontIcon>done</FontIcon>}
              disabled={!form.formState.dirty}
              onClick={async () =>
                await handleSubmit(form, validationSchema, async () => {
                  let res = await handleSave(form.getValues())
                  if (res?.success) {
                    form.reset(defaultValues, { dirty: false })
                    window.open(`/technical-visit/edit/${res.data}`, '_self')
                  }
                })
              }
            />
          </CardActions>
        </Card>
      </Cell>
    </Grid>
  )
}

export default Component
