import Yup from 'utils/yup'

export const defaultValues = {
  client: {
    id: null,
  },
  technician: {
    id: null,
  },
  technicalVisitStatus: null,
  number: null,
  initialVisitDate: null,
  finalVisitDate: null,
}

export const validationSchema = Yup.object().shape({
  client: Yup.object({
    id: Yup.string().nullable(),
  }),
  technician: Yup.object({
    id: Yup.string().nullable(),
  }),
  technicalVisitStatus: Yup.string().nullable(),
  number: Yup.number().nullable(),
  initialVisitDate: Yup.date().nullable(),
  finalVisitDate: Yup.date().nullable(),
})
