import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { FormContext, useForm } from 'react-hook-form'
import { Card, CardActions, CardContent, FontIcon, Grid, useToggle } from 'react-md'

import { getReceivableStatusDescription, ReceivableDocumentTypeOptions, ReceivableStatusEnum } from 'types/financial/receivable'
import { ButtonLog, Cell, PageTitle, Modal, ConfirmDialog, ButtonSuccess, ButtonEdit } from 'components/core'
import { handleSubmit, Input, InputDatePicker, InputDecimal, Select } from 'components/form'
import { DateLabel, DecimalLabel, NumberLabel, TextLabel } from 'components/label'

import { defaultValues, receiveDefaultValues, receiveValidationSchema, validationSchema } from './config'
import { handleDelayed, handleGet, handleReceived, handleSave } from './actions'

import ButtonError from 'components/core/button-error'

const Component: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const form = useForm({ defaultValues })
  const receiveform = useForm({ defaultValues: receiveDefaultValues })
  const [visibleDelayModal, enableDelayModal, disableDelayModal] = useToggle(false)
  const [visibleReceiveModal, enableReceiveModal, disableReceiveModal] = useToggle(false)
  const [receivable, setReceivable] = useState<any>({})
  const [totalValue, setTotalValue] = useState<number>(0)
  const status = form.watch('status')
  const discount = form.watch('discount')
  const interest = form.watch('interest')
  const fine = form.watch('fine')

  useEffect(() => {
    document.title = 'Edição de Lançamento - Contas a Receber'
  }, [])

  useEffect(() => {
    load()
  }, [id])

  useEffect(() => setTotalValue((receivable?.value ?? 0) - (discount ?? 0) + (interest ?? 0) + (fine ?? 0)), [discount, interest, fine])

  const load = async () => {
    const data = await handleGet(id)
    if (data) {
      form.reset(data)
      setReceivable(data)
    }
  }

  return (
    <>
      <Grid>
        <Cell>
          <PageTitle title='Edição de Lançamento - Contas a Receber' />
          <Card fullWidth>
            <CardContent>
              <FormContext {...form}>
                <Grid>
                  <TextLabel name='companyFantasyName' label='Empresa' value={receivable.companyFantasyName} desktopSize={3} tabletSize={4} />
                  <TextLabel name='clientFantasyName' label='Cliente' value={receivable.clientFantasyName} desktopSize={3} tabletSize={4} />
                  <Select name='documentType' label='Tipo do Documento *' options={ReceivableDocumentTypeOptions} desktopSize={3} tabletSize={4} />
                  <Input name='documentNumber' label='Número do Documento' desktopSize={3} tabletSize={4} />
                  <NumberLabel name='installment' label='Número da Parcela' value={receivable.installment} desktopSize={3} tabletSize={4} />
                  <DateLabel
                    name='originalDueDate'
                    label='Data de Vencimento Original'
                    value={receivable.originalDueDate}
                    desktopSize={3}
                    tabletSize={4}
                  />
                  <InputDatePicker name='dueDate' label='Data de Vencimento' desktopSize={3} tabletSize={4} />
                  <DateLabel name='receiptDate' label='Data de Recebimento' value={receivable.receiptDate} desktopSize={3} tabletSize={4} />
                  <DecimalLabel name='value' label='Valor' value={receivable.value} desktopSize={2} tabletSize={4} />
                  <InputDecimal name='discount' label='Desconto' desktopSize={2} tabletSize={4} />
                  <InputDecimal name='interest' label='Juros' desktopSize={2} tabletSize={4} />
                  <InputDecimal name='fine' label='Multa' desktopSize={2} tabletSize={4} />
                  <DecimalLabel name='totalValue' label='Valor Total' value={totalValue} desktopSize={2} tabletSize={4} />
                  <TextLabel name='status' label='Status' value={getReceivableStatusDescription(receivable.status)} desktopSize={2} tabletSize={4} />
                  <Input name='observation' label='Observações' desktopSize={12} tabletSize={8} />
                </Grid>
              </FormContext>
            </CardContent>
            <CardActions>
              <ButtonSuccess
                text='Salvar'
                icon={<FontIcon>done</FontIcon>}
                disabled={!form.formState.dirty || status === ReceivableStatusEnum.Received}
                onClick={async () =>
                  await handleSubmit(form, validationSchema, async () => {
                    if (await handleSave({ ...form.getValues(), id: id })) form.reset(form.getValues(), { dirty: false })
                  })
                }
              />
              <ButtonEdit
                text='Lançamento Recebido'
                theme='secondary'
                icon={<FontIcon>payments</FontIcon>}
                disabled={status === ReceivableStatusEnum.Received}
                onClick={async () => enableReceiveModal()}
              />
              <ButtonError
                text='Lançamento Em Atraso'
                theme='warning'
                icon={<FontIcon>event_busy</FontIcon>}
                disabled={status !== ReceivableStatusEnum.Receivable}
                onClick={async () => enableDelayModal()}
              />
              <ButtonLog entity='Receivable' id={id} />
            </CardActions>
          </Card>
        </Cell>
      </Grid>

      <Modal
        title={'Lançamento Recebido'}
        width={300}
        visible={visibleReceiveModal}
        confirmTitle={'Salvar'}
        confirmDisabled={!receiveform.formState.dirty}
        confirmAction={async () => {
          await handleSubmit(receiveform, receiveValidationSchema, async () => {
            if (await handleReceived({ id, ...receiveform.getValues() })) {
              receiveform.reset(receiveDefaultValues, { dirty: false })
              load()
              disableReceiveModal()
            }
          })
        }}
        closeTitle={'Fechar'}
        closeAction={async () => {
          receiveform.reset(receiveDefaultValues, { dirty: false })
          disableReceiveModal()
        }}>
        <Grid>
          <FormContext {...receiveform}>
            <InputDatePicker name='receiveDate' label='Data de Recebimento *' desktopSize={12} tabletSize={8} />
          </FormContext>
        </Grid>
      </Modal>

      <ConfirmDialog
        text='Deseja realmente alterar o lançamento para o status Em Atraso?'
        visible={visibleDelayModal}
        confirmAction={async () => {
          if (await handleDelayed({ id })) {
            load()
            disableDelayModal()
          }
        }}
        cancelAction={async () => disableDelayModal()}
      />
    </>
  )
}

export default Component
