import { useCallback, useEffect, useRef, useState } from 'react'
import { CircularProgress, FontIcon, FormMessage, List, ListItem, TextField, useResizeObserver } from 'react-md'
import { usePromiseTracker } from 'react-promise-tracker'
import { useFormContext } from 'react-hook-form'
import debounce from 'lodash.debounce'

import Cell from 'components/core/cell'
import { AutocompleteResult } from 'utils/types'

import { FieldProps } from './types'

interface AutoCompleteProps extends FieldProps {
  searchData: (values: any) => Promise<Array<AutocompleteResult>>
  onSelect?: (item: AutocompleteResult) => void
  searchCustomData?: any
}

export const InputAutoComplete = ({
  name,
  searchData,
  onSelect,
  searchCustomData,
  desktopSize,
  tabletSize,
  disabled,
  ...rest
}: AutoCompleteProps) => {
  const { promiseInProgress } = usePromiseTracker()
  const form = useFormContext()
  const [value, setValue] = useState<AutocompleteResult>({ id: null, label: null })
  const [searching, setSearching] = useState<boolean>(false)
  const [dataOptions, setDataOptions] = useState<Array<AutocompleteResult>>([])
  const [width, setWidth] = useState<number | null>(null)
  const minimumCharactersToSearch = 1

  const handleResize = useCallback(({ width }) => setWidth(width), [])
  const [_, refHandler] = useResizeObserver(handleResize)
  const formValue = form.watch(name)

  useEffect(() => {
    form.register({ name })
  }, [form.register])

  useEffect(() => {
    if (!formValue?.id) setValue({ id: null, label: null })
  }, [formValue?.id])

  useEffect(() => {
    setValue(form.getValues()[name])
  }, [form.getValues()[name]?.id])

  const debouncedChange = useRef(
    debounce(async selected => {
      if (selected.target.value.length >= minimumCharactersToSearch) {
        setSearching(true)
        let value: string = selected.target.value
        if (searchCustomData) setDataOptions(await searchData({ text: value, ...searchCustomData }))
        else setDataOptions(await searchData(value))
        setSearching(false)
      }
    }, 300),
  ).current

  return (
    <Cell tabletSize={tabletSize} desktopSize={desktopSize}>
      <TextField
        id={name}
        name={name}
        theme={'underline'}
        error={form.errors[name]?.id || form.errors[name] ? true : false}
        disabled={disabled || promiseInProgress}
        value={value?.label ?? ''}
        onBlur={() => setTimeout(() => setDataOptions([]), 100)}
        onChange={async selected => {
          setValue({ id: null, label: selected.target.value })
          form.setValue(name, { id: null, label: selected.target.value })
          if (form.errors[name]?.id || form.errors[name]) form.clearError(name)
          await debouncedChange(selected)
          return selected
        }}
        rightChildren={searching ? <CircularProgress id={`${name}-circular-progress`} small /> : <FontIcon>search</FontIcon>}
        {...rest}
      />
      {form.errors[name]?.id && (
        <FormMessage id={name} error style={{ paddingLeft: 0 }}>
          {form.errors[name].id.message}
        </FormMessage>
      )}
      {form.errors[name] && (
        <FormMessage id={name} error style={{ paddingLeft: 0 }}>
          {form.errors[name].message}
        </FormMessage>
      )}

      <div ref={refHandler}>
        {dataOptions && dataOptions.length > 0 && (
          <List className='rmd-listbox rmd-listbox--temporary' style={{ position: 'fixed', width: `${width}px`, transformOrigin: '50% 0px' }}>
            {dataOptions.map((item, index) => (
              <ListItem
                key={index}
                id={`${name}-item-${index}`}
                onClick={() => {
                  setValue({ id: item.id, label: item.label })
                  form.setValue(name, { id: item.id, label: item.label })
                  if (onSelect) onSelect(item)
                  setDataOptions([])
                }}
                className='rmd-list-item rmd-list-item--clickable rmd-option'>
                {item.label}
              </ListItem>
            ))}
          </List>
        )}
      </div>
    </Cell>
  )
}
