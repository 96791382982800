import { toast } from 'react-toastify'

import api from 'services/financial/report'

export const getReport = async (values: any) => {
  let res = await api.financial(values)

  if (res?.status === 204) toast.info('Nenhuma informação localizada')
  else download(res?.data)
}

const download = (blob: any) => {
  const url = window.URL.createObjectURL(new Blob([blob]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', 'Relatório Financeiro.csv')
  document.body.appendChild(link)
  link.click()
  link.parentNode?.removeChild(link)
}
