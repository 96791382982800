import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Grid, TabPanel, TabPanels, Tabs, TabsManager } from 'react-md'

import { Cell, PageTitle } from 'components/core'

import GeneralInformation from './components/general-information'
import Contact from './components/contact'
import Equipment from './components/equipment'
import Proposal from './components/proposal'

const Component: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const [loadContacts, setLoadContacts] = useState<boolean>(false)
  const [loadEquipments, setLoadEquipments] = useState<boolean>(false)
  const [loadProposals, setLoadProposals] = useState<boolean>(false)
  const [activeTab, setActiveTab] = useState<number>(0)
  const [toggleReset, setToggleReset] = useState<boolean>(false)

  useEffect(() => {
    document.title = 'Edição de Cliente'
  }, [])

  useEffect(() => {
    if (toggleReset) setToggleReset(false)
  }, [toggleReset])

  useEffect(() => {
    if (activeTab === 1) setLoadContacts(true)
    else if (activeTab === 2) setLoadEquipments(true)
    else if (activeTab === 3) setLoadProposals(true)
  }, [activeTab])

  return (
    <Grid>
      <Cell>
        <PageTitle title='Edição de Cliente' />
      </Cell>
      <Cell>
        {!toggleReset && (
          <TabsManager
            onActiveIndexChange={val => setActiveTab(val)}
            tabs={['Informações Gerais', 'Contatos', 'Equipamentos', 'Propostas']}
            tabsId='client-tabs'>
            <Tabs />
            <TabPanels persistent={true}>
              <TabPanel>
                <GeneralInformation id={id} />
              </TabPanel>
              <TabPanel>{loadContacts && <Contact id={id} />}</TabPanel>
              <TabPanel>{loadEquipments && <Equipment id={id} />}</TabPanel>
              <TabPanel>{loadProposals && <Proposal id={id} />}</TabPanel>
            </TabPanels>
          </TabsManager>
        )}
      </Cell>
    </Grid>
  )
}

export default Component
