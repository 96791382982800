import Yup from 'utils/yup'

export const defaultValues = {
  maintenanceSchedule: {
    id: null,
  },
  numberHoursCurrentMaintenance: null,
  numberDaysCurrentMaintenance: null,
}

export const validationSchema = Yup.object().shape({
  maintenanceSchedule: Yup.object({
    id: Yup.string().nullable().required(),
  }),
  numberHoursCurrentMaintenance: Yup.number().nullable().required().min(1, 'Horas na agenda atual inválida'),
  numberDaysCurrentMaintenance: Yup.number().nullable().required().min(1, 'Dias na agenda atual inválida'),
})
