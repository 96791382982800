import React, { useEffect, useState } from 'react'
import { Select } from 'components/form'

import { getCompanies } from './actions'

interface ComponentProps {
  name: string
  label: string
  desktopSize?: number
  tabletSize?: number
  disabled?: boolean
  [x: string]: any
}

const Component: React.FC<ComponentProps> = ({ name, label, desktopSize, tabletSize, ...rest }) => {
  const [companies, setCompanies] = useState<Array<any>>([])

  useEffect(() => {
    const load = async () => setCompanies([...[{ value: null, label: '' }], ...(await getCompanies())])

    load()

    return () => {}
  }, [])

  return <Select name={name} label={label} options={companies} desktopSize={desktopSize} tabletSize={tabletSize} {...rest} />
}

export default Component
