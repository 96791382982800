import Yup from 'utils/yup'

export const defaultValues = {
  currentPassword: null,
  newPassword: null,
  confirmNewPassword: null,
}

export const validationSchema = Yup.object().shape({
  currentPassword: Yup.string().required().min(6, 'A senha deve ter no mínimo 6 caracteres').max(20, 'A senha deve ter no máximo 20 caracteres'),
  newPassword: Yup.string()
    .required()
    .min(6, 'A senha deve ter no mínimo 6 caracteres')
    .max(20, 'A senha deve ter no máximo 20 caracteres')
    .test('passwords-match', 'A confirmação de senha está incorreta', function (value) {
      return this.parent.confirmNewPassword === value
    }),
  confirmNewPassword: Yup.string()
    .required()
    .min(6, 'A senha deve ter no mínimo 6 caracteres')
    .max(20, 'A senha deve ter no máximo 20 caracteres')
    .test('passwords-match', 'A confirmação de senha está incorreta', function (value) {
      return this.parent.newPassword === value
    }),
})
