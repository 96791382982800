import { FormContextValues } from 'react-hook-form'
import { toast } from 'react-toastify'

import Yup from 'utils/yup'

export async function handleSubmit(form: FormContextValues<any>, validationSchema: Yup.ObjectSchema<any>, call: Function) {
  try {
    form.clearError()
    let data = form.getValues()
    if (validationSchema) await validationSchema.validate(data, { abortEarly: false })
    if (call) call()
  } catch (err) {
    if (err instanceof Yup.ValidationError) {
      const validationErrors: Array<any> = []
      err.inner.forEach(error => {
        validationErrors.push({
          type: error.type,
          name: error.path,
          message: error.message,
        })
      })
      form.setError(validationErrors)
      toast.warn('Corrija os campos para prosseguir')
    }
  }
}

export async function handleValidate(form: FormContextValues<any>, validationSchema: Yup.ObjectSchema<any>): Promise<boolean> {
  try {
    form.clearError()
    let data = form.getValues()
    if (validationSchema) await validationSchema.validate(data, { abortEarly: false })
    return true
  } catch (err) {
    if (err instanceof Yup.ValidationError) {
      const validationErrors: Array<any> = []
      err.inner.forEach(error => {
        validationErrors.push({
          type: error.type,
          name: error.path,
          message: error.message,
        })
      })
      form.setError(validationErrors)
    }
  }
  return false
}
