import React from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { Grid } from 'react-md'

import { handleSubmit, InputNumber } from 'components/form'
import { Modal } from 'components/core'

import { defaultValues, validationSchema } from './config'
import { handleSave } from './actions'

interface ComponentProps {
  technicalVisitId: string
  partId: string
  visibleModal: boolean
  disableModal: () => void
  loadParts: () => void
  onHide: () => void
}

const Component: React.FC<ComponentProps> = ({ technicalVisitId, partId, visibleModal, disableModal, loadParts, onHide }) => {
  const form = useForm({ defaultValues })

  return (
    <Modal
      title={'Exclusão de Quantidade de Peças'}
      width={400}
      visible={visibleModal}
      confirmTitle={'Salvar'}
      confirmDisabled={!form.formState.dirty}
      confirmAction={async () => {
        await handleSubmit(form, validationSchema, async () => {
          await handleSave(technicalVisitId, partId, form.getValues()?.quantity ?? 0)
          form.reset(defaultValues, { dirty: false })
          loadParts()
          onHide()
          disableModal()
        })
      }}
      closeTitle={'Fechar'}
      closeAction={async () => {
        form.reset(defaultValues, { dirty: false })
        onHide()
        disableModal()
      }}>
      <Grid>
        <FormContext {...form}>
          <InputNumber name='quantity' label='Quantidade *' desktopSize={12} tabletSize={8} />
        </FormContext>
      </Grid>
    </Modal>
  )
}

export default Component
