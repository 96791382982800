import Yup from 'utils/yup'

import { ReceivableDocumentTypeEnum } from 'types/financial/receivable'

export const defaultValues = {
  companyId: null,
  documentNumber: null,
  observation: null,
  client: {
    id: null,
  },
  documentType: null,
  installments: [],
}

export const validationSchema = Yup.object().shape({
  companyId: Yup.string().nullable().required(),
  documentNumber: Yup.string().nullable().max(20, 'O número do documento deve ter no máximo 20 caracteres'),
  observation: Yup.string()
    .nullable()
    .max(500, 'As observações devem ter no máximo 500 caracteres')
    .test('observation-required', 'Observações não informadas', function (value) {
      if (!value && this.parent.documentType === ReceivableDocumentTypeEnum.Others) return false
      return true
    }),
  client: Yup.object({
    id: Yup.string().nullable().required(),
  }),
  documentType: Yup.string().nullable().required(),
  installments: Yup.mixed().test('installments', 'Parcelas não informadas', function (value: Array<number>) {
    return value.length > 0 ? true : false
  }),
})
