import { LayoutNavigationItem, LayoutNavigationTree } from 'react-md'

import { MenuPermission } from 'components/layout/components/menu/types'

interface getMenuProps {
  permissions: MenuPermission
  menu: LayoutNavigationTree<LayoutNavigationItem>
  getMenu: (id: string, parent: string | null, title: string, icon: string, path: string | null) => LayoutNavigationItem
}

export const getTechnicalVisitMenu = ({ permissions, menu, getMenu }: getMenuProps) => {
  if (permissions.technicalVisit.technicalVisit) {
    menu['/technical-visit'] = getMenu('/technical-visit', null, 'Visita Técnica', 'calendar_month', null)
    menu['/technical-visit/new'] = getMenu('/technical-visit/new', '/technical-visit', 'Nova', 'add', '/technical-visit/new')
    menu['/technical-visit/search'] = getMenu('/technical-visit/search', '/technical-visit', 'Pesquisa', 'search', '/technical-visit/search')
  }
}
