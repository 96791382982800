import { DataTableRequest } from 'components/data-table/types'
import api from 'services/technical-visit/technical-visit'

export const handleGetEquipments = async (request: DataTableRequest) => {
  let res = await api.searchEquipment(request)

  return res?.data ?? []
}

export const handleRemoveEquipment = async (id: string, equipmentId: string) => {
  let res = await api.removeEquipment(id, equipmentId)

  return res?.data?.success
}

export const handleGetMaintenanceActions = async (id: string, technicalVisitEquipmentId: string) => {
  let res = await api.listMaintenanceAction(id, technicalVisitEquipmentId)

  return res?.data ?? []
}

export const handleGetMaintenanceInspections = async (id: string, technicalVisitEquipmentId: string) => {
  let res = await api.listMaintenanceInspection(id, technicalVisitEquipmentId)

  return res?.data ?? []
}
