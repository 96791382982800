import api from 'services/financial/payable'

export const handleGet = async (id: string) => {
  let res = await api.get(id)

  return res?.data?.data
}

export const handleSave = async (values: any) => {
  let res = await api.update(values)

  return res?.data?.success
}

export const handleDelayed = async (values: any) => {
  let res = await api.delayed(values)

  return res?.data?.success
}

export const handlePaid = async (values: any) => {
  let res = await api.paid(values)

  return res?.data?.success
}
