import Yup from 'utils/yup'

import { ReceivableDocumentTypeEnum } from 'types/financial/receivable'

export const defaultValues = {
  documentNumber: null,
  discount: 0,
  interest: 0,
  fine: 0,
  dueDate: null,
  observation: null,
  documentType: null,
}

export const validationSchema = Yup.object().shape({
  documentNumber: Yup.string().nullable().max(20, 'O número do documento deve ter no máximo 20 caracteres'),
  discount: Yup.number().required(),
  interest: Yup.number().required(),
  fine: Yup.number().required(),
  dueDate: Yup.date().nullable().required(),
  observation: Yup.string()
    .nullable()
    .max(500, 'As observações devem ter no máximo 500 caracteres')
    .test('observation-required', 'Observações não informadas', function (value) {
      if (!value && this.parent.documentType === ReceivableDocumentTypeEnum.Others) return false
      return true
    }),
  documentType: Yup.string().nullable().required(),
})

export const receiveDefaultValues = {
  receiveDate: null,
}

export const receiveValidationSchema = Yup.object().shape({
  receiveDate: Yup.date().nullable().required(),
})
