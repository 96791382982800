import Yup from 'utils/yup'

export const defaultValues = {
  client: null,
  chanceClosing: null,
  proposalStatus: null,
  initialFollowUpDate: null,
  finalFollowUpDate: null,
}

export const validationSchema = Yup.object().shape({
  client: Yup.object({
    id: Yup.string().nullable(),
  }).nullable(),
  chanceClosing: Yup.string().nullable(),
  proposalStatus: Yup.string().nullable(),
  initialFollowUpDate: Yup.date().nullable(),
  finalFollowUpDate: Yup.date().nullable(),
})
