import clientApi from 'services/sale/client'

export const handleGetEquipments = async (id: string) => {
  let res = await clientApi.getEquipmentsByClientId(id)

  return res?.data?.data
}

export const handleRemoveEquipment = async (clientId: string, equipmentId: string, id: string) => {
  let res = await clientApi.removeEquipment(clientId, equipmentId, id)

  return res?.data?.success
}
