import Yup from 'utils/yup'
import { TypeReportEnum } from './types'

export const typeDefaultValues = {
  type: TypeReportEnum.Part,
}

export const partDefaultValues = {
  name: null,
  code: null,
  manufacturerName: null,
  equipmentModels: null,
}

export const partValidationSchema = Yup.object().shape({
  name: Yup.string().nullable().max(60, 'O nome deve ter no máximo 60 caracteres'),
  code: Yup.string().nullable().max(20, 'O código/part number deve ter no máximo 20 caracteres'),
  manufacturerName: Yup.string().nullable().max(60, 'O nome do fabricante deve ter no máximo 60 caracteres'),
  equipmentModels: Yup.string().nullable().max(100, 'Os modelos de equipamento devem ter no máximo 100 caracteres'),
})

export const equipmentDefaultValues = {
  model: null,
  manufacturerName: null,
  voltage: null,
  inicialFlowRate: null,
  finalFlowRate: null,
  serialNumber: null,
  partNumber: null,
  status: null,
}

export const equipmentValidationSchema = Yup.object().shape({
  model: Yup.string().nullable().max(60, 'O modelo deve ter no máximo 60 caracteres'),
  manufacturerName: Yup.string().nullable(),
  voltage: Yup.string().nullable(),
  inicialFlowRate: Yup.number().nullable(),
  finalFlowRate: Yup.number().nullable(),
  serialNumber: Yup.string().nullable().max(20, 'O número de série deve ter no máximo 20 caracteres'),
  partNumber: Yup.string().nullable(),
  status: Yup.string().nullable(),
})
