import React, { useEffect } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { Grid } from 'react-md'

import { EquipmentAutoComplete } from 'components/auto-complete'
import { handleSubmit } from 'components/form'
import { Modal } from 'components/core'

import { defaultValues, validationSchema } from './config'
import { handleSave } from './actions'

interface ComponentProps {
  technicalVisitId: string
  clientId: string
  visibleModal: boolean
  disableModal: () => void
  loadParts: () => void
}

const Component: React.FC<ComponentProps> = ({ technicalVisitId, clientId, visibleModal, disableModal, loadParts }) => {
  const form = useForm({ defaultValues })

  return (
    <Modal
      title={'Inclusão de Equipamento'}
      visible={visibleModal}
      confirmTitle={'Salvar'}
      confirmDisabled={!form.formState.dirty}
      confirmAction={async () => {
        await handleSubmit(form, validationSchema, async () => {
          await handleSave({ id: technicalVisitId, ...form.getValues() })
          form.reset(defaultValues, { dirty: false })
          loadParts()
          disableModal()
        })
      }}
      closeTitle={'Fechar'}
      closeAction={async () => {
        form.reset(defaultValues, { dirty: false })
        disableModal()
      }}>
      <Grid>
        <FormContext {...form}>
          <EquipmentAutoComplete name='equipment' label='Equipamento *' clientId={clientId} desktopSize={12} tabletSize={8} />
        </FormContext>
      </Grid>
    </Modal>
  )
}

export default Component
