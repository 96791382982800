import api from 'services/technical-visit/technical-visit'

export const handleSave = async (values: any) => {
  let res = await api.update(values)

  return res?.data?.success
}

export const handleGetPdf = async (id: string) => {
  let res = await api.getPdf(id)

  download('Ordem de Serviço.pdf', res?.data)
}

export const handleGetIcs = async (id: string) => {
  let res = await api.getIcs(id)

  download('Visita Técnica.ics', res?.data)
}

const download = (fileName: string, blob: any) => {
  const url = window.URL.createObjectURL(new Blob([blob]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
  link.parentNode?.removeChild(link)
}
