import { trackPromise } from 'react-promise-tracker'

import api from 'utils/api'
import { ApiResponse } from 'utils/types'
import { DataTableRequest } from 'components/data-table/types'

const url = '/v1/client'
const Api = {
  get: (id: string): Promise<ApiResponse> => trackPromise<ApiResponse>(api.get(`${url}/${id}`)),
  getContactsByClientId: (id: string): Promise<ApiResponse> => trackPromise<ApiResponse>(api.get(`${url}/${id}/contact`)),
  getTechnicalStartByEquipmentId: (clientId: string, equipmentId: string): Promise<ApiResponse> =>
    trackPromise<ApiResponse>(api.get(`${url}/${clientId}/equipment/${equipmentId}`)),
  getEquipmentsByClientId: (id: string): Promise<ApiResponse> => trackPromise<ApiResponse>(api.get(`${url}/${id}/equipment`)),
  getProposalsByClientId: (id: string): Promise<ApiResponse> => trackPromise<ApiResponse>(api.get(`${url}/${id}/proposal`)),
  create: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(url, request)),
  addContact: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/contact`, request)),
  addEquipment: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/equipment`, request)),
  search: (request: DataTableRequest): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/search`, request)),
  autocomplete: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/autocomplete`, request)),
  update: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.put(url, request)),
  updateContact: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.put(`${url}/contact`, request)),
  delete: (id: string): Promise<ApiResponse> => trackPromise<ApiResponse>(api.delete(`${url}/${id}`)),
  removeContact: (clientId: string, id: string): Promise<ApiResponse> => trackPromise<ApiResponse>(api.delete(`${url}/${clientId}/contact/${id}`)),
  removeEquipment: (clientId: string, equipmentId: string, id: string): Promise<ApiResponse> =>
    trackPromise<ApiResponse>(api.delete(`${url}/${clientId}/equipment/${equipmentId}/${id}`)),
}

export default Api
