import { LayoutNavigationTree, FontIcon } from 'react-md'

const FooterMenu: LayoutNavigationTree = {
  '/system/user/edit': {
    itemId: '/system/user/edit',
    parentId: null,
    children: 'Meus Dados',
    leftAddon: <FontIcon>perm_identity</FontIcon>,
    to: '/system/user/edit',
  },

  '/system/user/update-password': {
    itemId: '/system/user/update-password',
    parentId: null,
    children: 'Alterar Senha',
    leftAddon: <FontIcon>lock</FontIcon>,
    to: '/system/user/update-password',
  },
}

export default FooterMenu
