import React from 'react'
import { Card, CardContent, Grid, Table, TableBody, TableCell, TableContainer, TableHeader, TableRow, Text } from 'react-md'

import { format, parseISO } from 'date-fns'
import { Callout, Cell } from 'components/core'
import { CalloutType } from 'components/core/callout'

interface ComponentProps {
  equipments: Array<any> | undefined
}

const Component: React.FC<ComponentProps> = ({ equipments }) => {
  return (
    <>
      {equipments && equipments.length > 0 && (
        <Card fullWidth>
          <CardContent>
            <Grid>
              <Cell>
                <Text type='headline-6' margin='none'>
                  Próximas Visitas
                </Text>
              </Cell>
              <Cell>
                <Callout
                  text={
                    'A data da próxima visita do equipamento é calculada de forma aproximada com base na agenda de manutenção atual do equipamento e regime de trabalho preenchido na partida técnica do equipamento'
                  }
                  type={CalloutType.Info}
                />
              </Cell>
            </Grid>
            <TableContainer>
              <Table fullWidth>
                <TableHeader>
                  <TableRow>
                    <TableCell>Equipamento</TableCell>
                    <TableCell>Data</TableCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {equipments.map((data: any, index: number) => (
                    <TableRow key={index}>
                      <TableCell>{data.equipmentModel}</TableCell>
                      <TableCell>{data.nextVisitDate ? format(parseISO(data.nextVisitDate), "dd'/'MM'/'yyyy") : '-'}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      )}
    </>
  )
}

export default Component
