import React, { useEffect, useState } from 'react'
import { Route } from 'react-router-dom'

import EquipmentEdit from 'pages/inventory/equipment/edit'
import EquipmentNew from 'pages/inventory/equipment/new'
import EquipmentSearch from 'pages/inventory/equipment/search'

import MaintenanceScheduleEdit from 'pages/inventory/maintenance-schedule/edit'
import MaintenanceScheduleNew from 'pages/inventory/maintenance-schedule/new'
import MaintenanceScheduleSearch from 'pages/inventory/maintenance-schedule/search'

import PartEdit from 'pages/inventory/part/edit'
import PartNew from 'pages/inventory/part/new'
import PartSearch from 'pages/inventory/part/search'

import ReportIventory from 'pages/inventory/report/inventory'

import { MenuPermission } from 'components/layout/components/menu/types'
import { useAuth } from 'contexts/auth/auth-context'

const Routes: React.FC = () => {
  const { user, getMenuPermission } = useAuth()
  const [menuPermission, setMenuPermission] = useState<MenuPermission>()

  useEffect(() => {
    setMenuPermission(getMenuPermission())
  }, [user?.permissions])

  return (
    <>
      {menuPermission?.inventory.equipment && (
        <>
          <Route path='/inventory/equipment/edit/:id' exact component={EquipmentEdit} />
          <Route path='/inventory/equipment/new' exact component={EquipmentNew} />
          <Route path='/inventory/equipment/search' exact component={EquipmentSearch} />
        </>
      )}

      {menuPermission?.inventory.maintenanceSchedule && (
        <>
          <Route path='/inventory/maintenance-schedule/edit/:id' exact component={MaintenanceScheduleEdit} />
          <Route path='/inventory/maintenance-schedule/new' exact component={MaintenanceScheduleNew} />
          <Route path='/inventory/maintenance-schedule/search' exact component={MaintenanceScheduleSearch} />
        </>
      )}

      {menuPermission?.inventory.part && (
        <>
          <Route path='/inventory/part/edit/:id' exact component={PartEdit} />
          <Route path='/inventory/part/new' exact component={PartNew} />
          <Route path='/inventory/part/search' exact component={PartSearch} />
        </>
      )}

      {menuPermission?.inventory.report && (
        <>
          <Route path='/inventory/report/inventory' exact component={ReportIventory} />
        </>
      )}
    </>
  )
}

export default Routes
