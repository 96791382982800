import Yup from 'utils/yup'

export const defaultValues = {
  fantasyName: null,
  companyName: null,
  document: null,
  ie: null,
  active: false,
}

export const validationSchema = Yup.object().shape({
  fantasyName: Yup.string().nullable().required().max(60, 'O nome fantasia deve ter no máximo 60 caracteres'),
  companyName: Yup.string().nullable().required().max(60, 'O nome fantasia deve ter no máximo 60 caracteres'),
  document: Yup.string().nullable().required().max(18, 'O cpf/cnpj deve ter no máximo 18 caracteres'),
  ie: Yup.string().nullable().max(14, 'A inscrição estadual deve ter no máximo 14 caracteres'),
})
