import clientApi from 'services/sale/client'

export const handleGetContacts = async (id: string) => {
  let res = await clientApi.getContactsByClientId(id)

  return res?.data?.data
}

export const handleAddContact = async (values: any) => {
  let res = await clientApi.addContact(values)

  return res?.data?.success
}

export const handleUpdateContact = async (values: any) => {
  let res = await clientApi.updateContact(values)

  return res?.data?.success
}

export const handleRemoveContact = async (clientId: string, id: string) => {
  let res = await clientApi.removeContact(clientId, id)

  return res?.data?.success
}
