import React, { useEffect, useState } from 'react'
import { Grid } from 'react-md'
import { FormContext, useForm } from 'react-hook-form'

import { EquipmentStatusEnum, EquipmentStatusOption } from 'types/inventory/equipment'
import { EquipmentAutoComplete } from 'components/auto-complete'
import { handleSubmit, Select } from 'components/form'
import { Modal } from 'components/core'

import { defaultValues, validationSchema } from './config'
import { handleAddEquipment } from './actions'

interface ComponentProps {
  clientId: string
  onSuccess: () => void
  visibleModal: boolean
  disableModal: () => void
}

const Component: React.FC<ComponentProps> = ({ clientId, onSuccess, visibleModal, disableModal }) => {
  const form = useForm({ defaultValues })
  const [equipmentStatus, setEquipmentStatus] = useState<Array<EquipmentStatusOption>>([])

  useEffect(() => {
    setEquipmentStatus([
      { value: null, label: '' },
      { value: EquipmentStatusEnum.Leased, label: 'Locada' },
      { value: EquipmentStatusEnum.Proprietary, label: 'Proprietária' },
      { value: EquipmentStatusEnum.Sold, label: 'Vendida' },
    ])
  }, [])

  return (
    <Modal
      title={'Adicionar Equipamento'}
      visible={visibleModal}
      confirmTitle={'Salvar'}
      confirmDisabled={!form.formState.dirty}
      confirmAction={async () => {
        await handleSubmit(form, validationSchema, async () => {
          if (await handleAddEquipment({ clientId, ...form.getValues() })) {
            form.reset(defaultValues, { dirty: false })
            onSuccess()
            disableModal()
          }
        })
      }}
      closeTitle={'Fechar'}
      closeAction={async () => {
        form.reset(defaultValues, { dirty: false })
        disableModal()
      }}>
      <Grid>
        <FormContext {...form}>
          <EquipmentAutoComplete name='equipment' label='Equipamento *' desktopSize={9} tabletSize={6} />
          <Select name='equipmentStatus' label='Status *' options={equipmentStatus} desktopSize={3} tabletSize={2} />
        </FormContext>
      </Grid>
    </Modal>
  )
}

export default Component
