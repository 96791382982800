import React, { useEffect, useState } from 'react'

import { Modal } from 'components/core'

import { handleSave, handleListInspections } from './actions'
import { FormContext, useForm } from 'react-hook-form'
import { defaultValues, validationSchema } from './config'
import { handleSubmit, Select } from 'components/form'
import { Grid } from 'react-md'

interface ComponentProps {
  technicalVisitId: string
  technicalVisitEquipmentId: string | undefined
  equipmentId: string | undefined
  visibleModal: boolean
  disableModal: () => void
  loadMaintenanceInspections: () => Promise<void>
}

const Component: React.FC<ComponentProps> = ({
  technicalVisitId,
  technicalVisitEquipmentId,
  equipmentId,
  visibleModal,
  disableModal,
  loadMaintenanceInspections,
}) => {
  const form = useForm({ defaultValues })
  const [inspections, setInspections] = useState<Array<any>>([])

  useEffect(() => {
    const load = async () => {
      if (inspections.length == 0 && equipmentId && visibleModal) {
        setInspections([...[{ value: null, label: '' }], ...(await handleListInspections(equipmentId))])
      }
    }

    load()

    return () => {}
  }, [equipmentId, visibleModal, inspections])

  return (
    <Modal
      title={'Inclusão de Item de Inspeção'}
      visible={visibleModal}
      confirmTitle={'Salvar'}
      confirmDisabled={!form.formState.dirty}
      confirmAction={async () => {
        await handleSubmit(form, validationSchema, async () => {
          await handleSave({ id: technicalVisitId, equipmentId: technicalVisitEquipmentId, ...form.getValues() })
          await loadMaintenanceInspections()
          form.reset(defaultValues, { dirty: false })
          disableModal()
        })
      }}
      closeTitle={'Fechar'}
      closeAction={async () => {
        form.reset(defaultValues, { dirty: false })
        disableModal()
      }}>
      <Grid>
        <FormContext {...form}>
          <Select name='maintenanceInspectionId' label='Item de Inspeção *' options={inspections} desktopSize={12} tabletSize={8} />
        </FormContext>
      </Grid>
    </Modal>
  )
}

export default Component
