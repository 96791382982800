import maintenanceScheduleApi from 'services/inventory/maintenance-schedule'
import technicalVisitApi from 'services/technical-visit/technical-visit'

export const handleListInspections = async (equipmentId: string): Promise<Array<any>> => {
  let res = await maintenanceScheduleApi.listInspectionByEquipmentId(equipmentId)

  return res?.data ?? []
}

export const handleSave = async (values: any) => {
  let res = await technicalVisitApi.addMaintenanceInspection(values)

  return res?.data?.success
}
