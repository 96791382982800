import React from 'react'
import { Overlay } from 'react-md'
import { Route } from 'react-router-dom'

import Logo from 'components/core/logo'
import Layout from 'components/layout/layout'
import { useAuth } from 'contexts/auth/auth-context'
import AuthRoutes from 'pages/auth/routes'
import AuditRoutes from 'pages/audit/routes'
import FinancialRoutes from 'pages/financial/routes'
import InventoryRoutes from 'pages/inventory/routes'
import SaleRoutes from 'pages/sale/routes'
import SystemRoutes from 'pages/system/routes'
import TechnicalVisitRoutes from 'pages/technical-visit/routes'
import Home from 'pages/system/home'

const Routes: React.FC = () => {
  const { logged } = useAuth()

  return (
    <>
      {logged === null && (
        <Layout>
          <Overlay id='simple-overlay' visible={true} onRequestClose={() => {}}>
            <div style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
              <Logo />
            </div>
          </Overlay>
        </Layout>
      )}
      {logged === false && <AuthRoutes />}
      {logged === true && (
        <Layout>
          <AuditRoutes />
          <FinancialRoutes />
          <InventoryRoutes />
          <SaleRoutes />
          <SystemRoutes />
          <TechnicalVisitRoutes />

          <Route path='/' exact component={Home} />
        </Layout>
      )}
    </>
  )
}

export default Routes
