import React, { useEffect } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { Card, CardActions, CardContent, FontIcon, Grid } from 'react-md'

import { handleSubmit, InputDatePicker, Select } from 'components/form'
import { Cell, PageTitle, ButtonSuccess } from 'components/core'
import { ClientAutoComplete } from 'components/auto-complete'
import { ExpenseTypeOptions } from 'types/financial/payable'
import { CompanySelect } from 'components/select'

import { defaultValues, validationSchema } from './config'
import { getReport } from './actions'

const Component: React.FC = () => {
  const form = useForm({ defaultValues })

  useEffect(() => {
    document.title = 'Relatório de Gasto por Cliente'
  }, [])

  return (
    <Grid>
      <Cell>
        <PageTitle title='Relatório de Gasto por Cliente' />
        <Card fullWidth>
          <CardContent>
            <Grid>
              <FormContext {...form}>
                <CompanySelect name='companyId' label='Empresa' desktopSize={6} tabletSize={4} />
                <ClientAutoComplete name='client' label='Cliente' desktopSize={6} tabletSize={4} />
                <InputDatePicker name='initialDueDate' label='Data de Vencimento Inicial *' desktopSize={3} tabletSize={4} />
                <InputDatePicker name='finalDueDate' label='Data de Vencimento Final *' desktopSize={3} tabletSize={4} />
                <Select name='expenseType' label='Tipo de Gasto' options={ExpenseTypeOptions} desktopSize={2} tabletSize={4} />
              </FormContext>
            </Grid>
            <CardActions>
              <ButtonSuccess
                text='Exportar'
                icon={<FontIcon>download</FontIcon>}
                onClick={async () => await handleSubmit(form, validationSchema, () => getReport(form.getValues()))}
              />
            </CardActions>
          </CardContent>
        </Card>
      </Cell>
    </Grid>
  )
}

export default Component
