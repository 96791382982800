import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Card, CardActions, CardContent, FontIcon, Grid } from 'react-md'

import { ButtonLog, Cell, PageTitle, ButtonSuccess } from 'components/core'
import { handleSubmit } from 'components/form'

import { defaultValues, validationSchema } from './components/info/config'
import { handleGet, handleSave } from './actions'

import Info from './components/info'
import File from './components/file'

const Component: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const form = useForm({ defaultValues })
  const [proposal, setProposal] = useState<any>({})

  useEffect(() => {
    document.title = 'Edição de Proposta'
  }, [])

  useEffect(() => {
    const load = async () => {
      const res = await handleGet(id)
      if (res) {
        form.reset(res)
        setProposal(res)
      }
    }

    load()

    return () => {}
  }, [id])

  return (
    <Grid>
      <Cell>
        <PageTitle title='Edição de Proposta' />
        <Card fullWidth>
          <CardContent>
            <Info form={form} proposal={proposal} />
            <File proposalId={id} />
          </CardContent>
          <CardActions>
            <ButtonSuccess
              text='Salvar'
              icon={<FontIcon>done</FontIcon>}
              disabled={!form.formState.dirty}
              onClick={async () =>
                await handleSubmit(form, validationSchema, async () => {
                  if (await handleSave({ ...form.getValues(), id: id })) form.reset(form.getValues(), { dirty: false })
                })
              }
            />
            <ButtonLog entity='Proposal' id={id} />
          </CardActions>
        </Card>
      </Cell>
    </Grid>
  )
}

export default Component
