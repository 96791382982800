import Yup from 'utils/yup'

export const defaultValues = {
  nextTechnicalVisit: null,
  arrivalTime: null,
  exitTime: null,
}

export const validationSchema = Yup.object().shape({
  nextTechnicalVisit: Yup.date().nullable(),
  arrivalTime: Yup.string().nullable().required(),
  exitTime: Yup.string().nullable(),
})
