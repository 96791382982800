import React from 'react'
import { Grid } from 'react-md'
import { FormContext, useForm } from 'react-hook-form'

import { MaintenanceScheduleAutocomplete } from 'components/auto-complete'
import { handleSubmit, InputNumber } from 'components/form'
import { Callout, Cell, Modal } from 'components/core'

import { defaultValues, validationSchema } from './config'
import { handleSave } from './actions'
import { CalloutType } from 'components/core/callout'

interface ComponentProps {
  id: string | null
  onSuccess: () => void
  visibleModal: boolean
  disableModal: () => void
}

const Component: React.FC<ComponentProps> = ({ id, visibleModal, onSuccess, disableModal }) => {
  const form = useForm({ defaultValues })

  return (
    <Modal
      title={'Alteração de Agenda de Manutenção'}
      visible={visibleModal}
      confirmTitle={'Salvar'}
      confirmDisabled={!form.formState.dirty}
      confirmAction={async () => {
        await handleSubmit(form, validationSchema, async () => {
          await handleSave({ id, ...form.getValues() })
          form.reset(defaultValues, { dirty: false })
          disableModal()
          onSuccess()
        })
      }}
      closeTitle={'Fechar'}
      closeAction={async () => {
        form.reset(defaultValues, { dirty: false })
        disableModal()
      }}>
      <Grid>
        <Cell>
          <Callout
            text={
              'Para realizar a alteração da agenda de manutenção é necessário informar a quantidade de horas e dias que devem ser contabilizados nas novas manutenções para que o horímetro não seja afetado'
            }
            type={CalloutType.Warning}
          />
        </Cell>
        <FormContext {...form}>
          <MaintenanceScheduleAutocomplete name='maintenanceSchedule' label='Agenda de Manutenção *' desktopSize={6} tabletSize={8} />
          <InputNumber name='numberHoursCurrentMaintenance' label='Qtd. de Horas *' desktopSize={3} tabletSize={4} />
          <InputNumber name='numberDaysCurrentMaintenance' label='Qtd. de Dias *' desktopSize={3} tabletSize={4} />
        </FormContext>
      </Grid>
    </Modal>
  )
}

export default Component
