import React from 'react'
import { Grid } from '@react-md/utils'
import { Text } from 'react-md'

import { DateLabel, NumberLabel, TextLabel, TimeLabel } from 'components/label'

import { Cell } from 'components/core'

interface ComponentProps {
  technicalVisit: any
}

const Component: React.FC<ComponentProps> = ({ technicalVisit }) => {
  return (
    <Grid>
      <Cell>
        <Text type='headline-6' margin='none'>
          Informações Gerais
        </Text>
      </Cell>
      <TextLabel name='clientFantasyName' label='Cliente' value={technicalVisit.clientFantasyName} desktopSize={3} tabletSize={4} />
      <TextLabel name='technicianName' label='Técnico' value={technicalVisit.technicianName} desktopSize={3} tabletSize={4} />
      <NumberLabel name='number' label='Número' value={technicalVisit.number} desktopSize={2} tabletSize={4} />
      <DateLabel name='visitDate' label='Data da Visita *' value={technicalVisit.visitDate} desktopSize={2} tabletSize={4} />
      <TimeLabel name='visitTime' label='Horário da Visita *' value={technicalVisit.visitTime} desktopSize={2} tabletSize={4} />
    </Grid>
  )
}

export default Component
