import React, { useEffect, useState } from 'react'
import { TableCell, TableRow } from 'react-md'
import { format, parseISO } from 'date-fns'

import { DataTableResult } from 'components/data-table/types'
import { DataTable } from 'components/data-table'
import { ButtonLog } from 'components/core'

import { handleGetTechnicalStarts } from './actions'

interface ComponentProps {
  id: string
}

const Component: React.FC<ComponentProps> = ({ id }) => {
  const [filter, setFilter] = useState<any>()
  const [result, setResult] = useState<DataTableResult>({ total: 0, results: [] })

  useEffect(() => {
    setFilter({ equipmentId: id })
  }, [id])

  return (
    <DataTable
      filter={filter}
      header={[{ label: 'Cliente' }, { label: 'Técnico Responsável' }, { label: 'Data da Partida Técnica' }, { label: '' }]}
      result={result}
      setResult={setResult}
      search={handleGetTechnicalStarts}
      total={result ? result.total : 0}
      showEmptyMessageAlert={false}
      emptyMessage={'Nenhuma partida técnica realizada'}
      wrapEmptyMessageWithCard={true}>
      {result &&
        result.results &&
        result.results.map((data: any, index) => (
          <TableRow key={index}>
            <TableCell>{data.clientName}</TableCell>
            <TableCell>{data.responsibleTechnicianName}</TableCell>
            <TableCell>{format(parseISO(data.date), "dd'/'MM'/'yyyy")}</TableCell>
            <TableCell align='right'>
              <ButtonLog entity='EquipmentTechnicalStart' id={data.id} rounded={true} />
            </TableCell>
          </TableRow>
        ))}
    </DataTable>
  )
}

export default Component
