import React from 'react'
import { SortOrder } from 'react-md'

import { DataTableRequest } from './types'

export const handleOrder = (
  request: DataTableRequest | undefined,
  name: string,
  setRequest: (value: React.SetStateAction<DataTableRequest | undefined>) => void,
) => {
  if (request) {
    if (request.order.field === name) {
      setRequest({
        ...request,
        order: { field: name, desc: !request.order.desc },
      })
    } else {
      setRequest({ ...request, order: { field: name, desc: false } })
    }
  }
}

export const getSorted = (request: DataTableRequest | undefined, field: string): SortOrder => {
  if (request?.order.field === field) {
    if (request?.order.desc) return 'descending'
    else return 'ascending'
  }
  return 'other'
}

export const onChangeRowsPerPage = (
  request: DataTableRequest | undefined,
  rows: number,
  setRequest: (value: React.SetStateAction<DataTableRequest | undefined>) => void,
  setRowsPerPage: (value: React.SetStateAction<number>) => void,
) => {
  if (request) {
    setRowsPerPage(rows)
    setRequest({
      ...request,
      pagination: {
        page: 0,
        length: rows,
      },
    })
  }
}

export const getStartRow = (request: DataTableRequest | undefined, rowsPerPage: number): number => {
  if (request) return request.pagination.page * rowsPerPage + 1
  return 1
}

export const getLastRow = (request: DataTableRequest | undefined, total: number, rowsPerPage: number): number => {
  if (request) return Math.min(total, getStartRow(request, rowsPerPage) - 1 + rowsPerPage)
  return total
}
