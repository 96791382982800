import React, { useEffect, useState } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { FontIcon, Grid, Card, CardActions, CardContent, TabsManager, Tabs, TabPanels, TabPanel } from 'react-md'

import { Cell, PageTitle, ButtonSuccess } from 'components/core'
import { handleSubmit, Input } from 'components/form'

import { defaultValues, validationSchema } from './config'
import { handleSave } from './actions'
import Permissions from './components/permissions'

const Component: React.FC = () => {
  const form = useForm({ defaultValues })
  const [toggleReset, setToggleReset] = useState<boolean>(false)

  useEffect(() => {
    document.title = 'Novo Perfil'
    form.register({ name: 'permissions' })
  }, [form.register])

  useEffect(() => {
    if (toggleReset) setToggleReset(false)
  }, [toggleReset])

  return (
    <Grid>
      <Cell>
        <PageTitle title='Novo Perfil' />
        {!toggleReset && (
          <TabsManager tabs={['Informações Gerais', 'Permissões']} tabsId='profile-tabs'>
            <Tabs />
            <Card fullWidth>
              <CardContent>
                <TabPanels persistent={true}>
                  <TabPanel>
                    <FormContext {...form}>
                      <Grid>
                        <Input name='name' label='Nome *' desktopSize={12} tabletSize={8} />
                      </Grid>
                    </FormContext>
                  </TabPanel>
                  <TabPanel>
                    <Permissions form={form} />
                  </TabPanel>
                </TabPanels>
              </CardContent>
              <CardActions align='end'>
                <ButtonSuccess
                  text='Salvar'
                  icon={<FontIcon>done</FontIcon>}
                  disabled={!form.formState.dirty}
                  onClick={async () => {
                    await handleSubmit(form, validationSchema, async () => {
                      if (await handleSave(form.getValues())) {
                        setToggleReset(true)
                        form.reset(defaultValues, { dirty: false })
                      }
                    })
                  }}
                />
              </CardActions>
            </Card>
          </TabsManager>
        )}
      </Cell>
    </Grid>
  )
}

export default Component
