import React from 'react'

import { InputAutoComplete } from 'components/form'

import { responsibleTechnicianAutocomplete } from './actions'

interface ComponentProps {
  name: string
  label: string
  desktopSize?: number
  tabletSize?: number
  disabled?: boolean
  [x: string]: any
}

const Component: React.FC<ComponentProps> = ({ name, label, desktopSize, tabletSize, ...rest }) => {
  return (
    <InputAutoComplete
      name={name}
      label={label}
      searchData={responsibleTechnicianAutocomplete}
      desktopSize={desktopSize}
      tabletSize={tabletSize}
      {...rest}
    />
  )
}

export default Component
