import { add, sub, getDay, parseISO, isAfter, startOfToday } from 'date-fns'
import { toast } from 'react-toastify'

import { Installment } from './types'

export const handleCalculate = (values: any): Array<Installment> => {
  let installments: Array<Installment> = []
  let value = values.totalValue / values.quantity

  for (let index = 0; index < values.quantity; index++) {
    let dueDate = add(parseISO(values.initialDueDate), {
      days: index * values.interval,
    })
    installments.push({
      dueDate: dueDate,
      dayOfWeek: getDayOfWeek(dueDate),
      value: value,
    })
  }

  return installments
}

export const handleAddDay = (index: number, currentInstallments: Array<Installment>): Array<Installment> => {
  let installments = currentInstallments.map<Installment>((x, i) => {
    if (i === index) {
      let dueDate = add(x.dueDate, { days: 1 })
      x.dueDate = dueDate
      x.dayOfWeek = getDayOfWeek(dueDate)
    }
    return x
  })

  return installments.sort((a: any, b: any) => a.dueDate - b.dueDate)
}

export const handleSubtractDay = (index: number, currentInstallments: Array<Installment>): Array<Installment> => {
  let installments = currentInstallments.map<Installment>((x, i) => {
    if (i === index) {
      let dueDate = sub(x.dueDate, { days: 1 })
      if (isAfter(dueDate, sub(startOfToday(), { days: 1 }))) {
        x.dueDate = dueDate
        x.dayOfWeek = getDayOfWeek(dueDate)
      } else {
        toast.warn('A data de vencimento deve ser igual ou superior a data atual')
      }
    }
    return x
  })

  return installments.sort((a: any, b: any) => a.dueDate - b.dueDate)
}

const getDayOfWeek = (dueDate: Date): string => {
  let day = getDay(dueDate)

  switch (day) {
    case 0:
      return 'Domingo'
    case 1:
      return 'Segunda'
    case 2:
      return 'Terça'
    case 3:
      return 'Quarta'
    case 4:
      return 'Quinta'
    case 5:
      return 'Sexta'
    case 6:
      return 'Sábado'
    default:
      return ''
  }
}
