import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Card, CardActions, CardContent, FontIcon, Grid, useToggle } from 'react-md'

import { ButtonSuccess, Cell, PageTitle } from 'components/core'

import { TechnicalVisitStatusEnum } from 'types/technical-visit/technical-visit'

import { handleGet } from './actions'

import GeneralInformation from './components/general-information'
import Equipment from './components/equipment'
import Finish from './components/finish'

const Component: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const [technicalVisit, setTechnicalVisit] = useState<any>({})
  const [visibleFinish, enableFinish, disableFinish] = useToggle(false)

  useEffect(() => {
    document.title = 'Finalização de Visita Técnica'
  }, [])

  useEffect(() => {
    loadTechnicalVisit()
  }, [id])

  const loadTechnicalVisit = async () => {
    let data = await handleGet(id)
    if (data) {
      setTechnicalVisit(data)
    }
  }

  return (
    <>
      <Grid>
        <Cell>
          <PageTitle title='Finalização de Visita Técnica' />
        </Cell>
        <Cell>
          <Card fullWidth>
            <CardContent>
              <GeneralInformation technicalVisit={technicalVisit} />
              <Equipment technicalVisitId={id} equipments={technicalVisit.equipments} loadTechnicalVisit={loadTechnicalVisit} />
            </CardContent>
            <CardActions>
              <ButtonSuccess
                text='Finalizar Visita'
                icon={<FontIcon>done</FontIcon>}
                disabled={
                  technicalVisit.technicalVisitStatus === TechnicalVisitStatusEnum.Finished || !technicalVisit.equipments?.every((x: any) => x.done)
                }
                onClick={async () => enableFinish()}
              />
            </CardActions>
          </Card>
        </Cell>
      </Grid>

      <Finish
        technicalVisitId={id}
        loadNextVisitDates={technicalVisit.equipments?.every((x: any) => x.done)}
        visibleModal={visibleFinish}
        disableModal={disableFinish}
        loadTechnicalVisit={loadTechnicalVisit}
      />
    </>
  )
}

export default Component
