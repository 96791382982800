import Yup from 'utils/yup'

export const defaultValues = {
  client: {
    id: null,
  },
  technician: {
    id: null,
  },
  visitDate: null,
  visitTime: null,
}

export const validationSchema = Yup.object().shape({
  client: Yup.object({
    id: Yup.string().nullable().required(),
  }),
  technician: Yup.object({
    id: Yup.string().nullable().required(),
  }),
  visitDate: Yup.date().nullable().required(),
  visitTime: Yup.string().nullable().required(),
})
