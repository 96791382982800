import Yup from 'utils/yup'

export const defaultValues = {
  followUpDate: null,
  lastContactDate: null,
  description: null,
  totalValue: null,
  chanceClosing: null,
  proposalStatus: null,
  files: [],
}

export const validationSchema = Yup.object().shape({
  followUpDate: Yup.date().nullable(),
  lastContactDate: Yup.date().nullable(),
  description: Yup.string().nullable().required().max(60, 'A descrição deve ter no máximo 60 caracteres'),
  totalValue: Yup.number().nullable().required(),
  chanceClosing: Yup.string().nullable().required(),
  proposalStatus: Yup.string().nullable().required(),
})
