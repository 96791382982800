import Yup from 'utils/yup'

export const defaultValues = {
  description: null,
  equipmentType: null,
  active: null,
  observation: null,
}

export const validationSchema = Yup.object().shape({
  description: Yup.string().nullable().required().max(60, 'A descrição deve ter no máximo 60 caracteres'),
  equipmentType: Yup.string().nullable().required(),
  active: Yup.bool().nullable().required(),
  observation: Yup.string().nullable(),
})
