import React from 'react'
import { Grid } from 'react-md'

import { NumberLabel, TextLabel } from 'components/label'
import { Modal } from 'components/core'

import { getServicePerformedDescription, getVisitTypeDescription } from 'types/technical-visit/technical-visit'

interface ComponentProps {
  technicalVisitEquipment: any
  visibleModal: boolean
  disableModal: () => void
}

const Component: React.FC<ComponentProps> = ({ technicalVisitEquipment, visibleModal, disableModal }) => {
  return (
    <Modal title={'Finalização do Equipamento'} visible={visibleModal} closeTitle={'Fechar'} closeAction={async () => disableModal()}>
      <Grid>
        <TextLabel
          name='visitType'
          label='Tipo da Visita'
          value={getVisitTypeDescription(technicalVisitEquipment.visitType)}
          desktopSize={4}
          tabletSize={3}
        />
        <TextLabel
          name='servicePerformed'
          label='Serviço Realizado'
          value={getServicePerformedDescription(technicalVisitEquipment.servicePerformed)}
          desktopSize={4}
          tabletSize={3}
        />
        <NumberLabel name='hourmeter' label='Horímetro' value={technicalVisitEquipment.hourmeter} desktopSize={4} tabletSize={2} />
        <TextLabel name='observation' label='Observações' value={technicalVisitEquipment.observation} />
      </Grid>
    </Modal>
  )
}

export default Component
