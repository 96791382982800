import React, { useState, useEffect } from 'react'
import { Text, Grid, Card, CardContent, Table, TableBody, TableRow, TableCell, CardActions, FileInput, FontIcon, Button, Tooltipped } from 'react-md'

import { ButtonCustom, Callout, Cell, ConfirmDialog } from 'components/core'
import { CalloutType } from 'components/core/callout'
import { CustomButton, DeleteButton } from 'components/data-table'

import { handleAddFile, handleGetFile, handleGetFiles, handleRemoveFile } from './actions'

interface ComponentProps {
  proposalId: string
}

interface File {
  id: string
  fileName: string
  fileBase64: string
}

const Component: React.FC<ComponentProps> = ({ proposalId }) => {
  const [files, setFiles] = useState<Array<File>>([])
  const [deleteId, setDeleteId] = useState<string>()

  useEffect(() => {
    GetFiles()
  }, [proposalId])

  const GetFiles = async () => setFiles(await handleGetFiles(proposalId))

  const AddFile = async (e: any) => {
    let file = e.target.files[0]
    let fileName = e.target.files[0].name
    let reader = new FileReader()
    reader.readAsBinaryString(file)
    reader.onload = async () => {
      if (reader && reader.result) {
        if (
          await handleAddFile({
            proposalId: proposalId,
            fileName: fileName,
            fileBase64: btoa(reader.result.toString()),
          })
        ) {
          await GetFiles()
        }
      }
    }
  }

  return (
    <>
      <Grid>
        <Cell>
          <Text type='headline-6' margin='none'>
            Arquivos
          </Text>
        </Cell>
        <Cell>
          <Card fullWidth>
            <CardContent>
              {(!files || files.length === 0) && <Callout text={'Nenhum arquivo localizado'} type={CalloutType.Info} />}
              {files && (
                <Table fullWidth>
                  <TableBody>
                    {files.map((data: File, index) => (
                      <TableRow key={index}>
                        <TableCell>{data.fileName}</TableCell>
                        <TableCell align='right'>
                          <CustomButton
                            id={`download-${index}`}
                            tooltip={'Download'}
                            action={async () => await handleGetFile(data.fileName, proposalId, data.id)}
                            icon={'download'}
                            whiteThemeBackgroundColor={'#64b5f6'}
                            darkThemeBackgroundColor={'#1565c0'}
                          />
                          <DeleteButton id={`delete-${index}`} action={() => setDeleteId(data.id)} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </CardContent>
            <CardActions>
              <FileInput
                id='import-input'
                theme={'secondary'}
                themeType={'contained'}
                buttonType={'text'}
                accept={'.pdf,.doc,.docx,.xls,.xlsx'}
                icon={<FontIcon>description</FontIcon>}
                onChange={async event => {
                  if (event?.target?.files?.[0]) await AddFile(event)
                }}>
                {'Upload de Arquivo'}
              </FileInput>
            </CardActions>
          </Card>
        </Cell>
      </Grid>

      <ConfirmDialog
        text='Deseja realmente excluir o arquivo? Essa ação não poderá ser desfeita'
        visible={deleteId ? true : false}
        confirmAction={async () => {
          if (deleteId && (await handleRemoveFile(proposalId, deleteId))) setFiles(files.filter(x => x.id != deleteId))
          setDeleteId(undefined)
        }}
        cancelAction={async () => setDeleteId(undefined)}
      />
    </>
  )
}

export default Component
