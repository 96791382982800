import Yup from 'utils/yup'

export const defaultValues = {
  companyId: null,
  client: {
    id: null,
  },
  expenseType: null,
  initialDueDate: null,
  finalDueDate: null,
}

export const validationSchema = Yup.object().shape({
  companyId: Yup.string().nullable(),
  client: Yup.object({
    id: Yup.string().nullable(),
  }),
  expenseType: Yup.string().nullable(),
  initialDueDate: Yup.date().nullable().required(),
  finalDueDate: Yup.date().nullable().required(),
})
