import Yup from 'utils/yup'

export const defaultValues = {
  name: '',
  permissions: [],
}

export const defaultValuesAddPermission = {
  permission: '0',
}

export const validationSchema = Yup.object().shape({
  name: Yup.string().required().min(3, 'O nome deve ter no mínimo 3 caracteres').max(40, 'O nome deve ter no máximo 40 caracteres'),
  permissions: Yup.mixed().test('permissions', 'Permissões não informadas', function (value: Array<number>) {
    return value.length > 0 ? true : false
  }),
})

export const validationSchemaAddPermission = Yup.object().shape({
  permission: Yup.string().required(),
})
