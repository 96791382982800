import Yup from 'utils/yup'

export const defaultValues = {
  description: null,
  format: null,
}

export const validationSchema = Yup.object().shape({
  description: Yup.string().nullable().required().max(60, 'A descrição deve ter no máximo 60 caracteres'),
  format: Yup.string().nullable().required().max(15, 'O formato deve ter no máximo 60 caracteres'),
})
