import React, { useEffect } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { Card, CardActions, CardContent, FontIcon, Grid, Text } from 'react-md'

import { addressDefaultValues, addressValidationSchema } from 'components/address/config'
import { Cell, PageTitle, ButtonSuccess } from 'components/core'
import { Input, InputDocument } from 'components/form'
import Address from 'components/address'

import { defaultValues, validationSchema } from './config'
import { handleSave, handleValidateForms } from './actions'

const Component: React.FC = () => {
  const form = useForm({ defaultValues })
  const addressForm = useForm({ defaultValues: addressDefaultValues })

  useEffect(() => {
    document.title = 'Nova Empresa'
  }, [])

  return (
    <Grid>
      <Cell>
        <PageTitle title='Nova Empresa' />
        <Card fullWidth>
          <CardContent>
            <Grid>
              <Cell>
                <Text type='headline-6' margin='none'>
                  Informações Gerais
                </Text>
              </Cell>
              <Cell>
                <Grid>
                  <FormContext {...form}>
                    <Input name='fantasyName' label='Nome Fantasia *' desktopSize={4} tabletSize={4} />
                    <Input name='companyName' label='Razão Social *' desktopSize={4} tabletSize={4} />
                    <InputDocument name='document' label='Cnpj *' maxLength={18} desktopSize={2} tabletSize={4} />
                    <Input name='ie' label='IE' desktopSize={2} tabletSize={4} />
                  </FormContext>
                </Grid>
              </Cell>
              <Cell>
                <Text type='headline-6' margin='none'>
                  Endereço
                </Text>
              </Cell>
              <Cell>
                <Grid>
                  <Address form={addressForm} />
                </Grid>
              </Cell>
            </Grid>
          </CardContent>
          <CardActions>
            <ButtonSuccess
              text='Salvar'
              icon={<FontIcon>done</FontIcon>}
              disabled={!form.formState.dirty && !addressForm.formState.dirty}
              onClick={async () => {
                if (
                  await handleValidateForms(
                    { form: form, validationSchema: validationSchema },
                    { form: addressForm, validationSchema: addressValidationSchema },
                  )
                ) {
                  if (await handleSave({ ...form.getValues(), address: addressForm.getValues() })) {
                    form.reset(defaultValues, { dirty: false })
                    addressForm.reset(addressDefaultValues, { dirty: false })
                  }
                }
              }}
            />
          </CardActions>
        </Card>
      </Cell>
    </Grid>
  )
}

export default Component
