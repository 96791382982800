import React, { useEffect, useState } from 'react'
import { TextField, FormMessage } from 'react-md'
import { useFormContext } from 'react-hook-form'
import { usePromiseTracker } from 'react-promise-tracker'

import { formatCurrency } from 'types/core/currency'
import Cell from 'components/core/cell'

import { FieldProps } from './types'

export const InputDecimal = ({ name, desktopSize, tabletSize, disabled, ...rest }: FieldProps) => {
  const { promiseInProgress } = usePromiseTracker()
  const form = useFormContext()
  const [text, setText] = useState<string>('')
  const formValue = form.watch(name)

  useEffect(() => {
    form.register({ name })
  }, [form.register])

  useEffect(() => {
    setText(formatCurrency(Number(formValue ?? 0)))
  }, [formValue])

  return (
    <Cell tabletSize={tabletSize} desktopSize={desktopSize}>
      <TextField
        id={name}
        name={name}
        theme={'underline'}
        error={form.errors[name] ? true : false}
        disabled={disabled || promiseInProgress}
        value={text}
        maxLength={12}
        onChange={selected => {
          if (form.errors[name]) form.clearError(name)
          if (selected.target.value) form.setValue(name, parseFloat(selected.target.value.replace(/\D/g, '')) / 100)
          else form.setValue(name, null)
        }}
        {...rest}
      />
      {form.errors[name] && (
        <FormMessage id={name} error style={{ paddingLeft: 0 }}>
          {form.errors[name].message}
        </FormMessage>
      )}
    </Cell>
  )
}
