import React, { useEffect, useState } from 'react'
import { Route } from 'react-router-dom'

import ProfileEdit from 'pages/system/profile/edit'
import ProfileNew from 'pages/system/profile/new'
import ProfileSearch from 'pages/system/profile/search'

import UserEditAdmin from 'pages/system/user/edit-admin'
import UserEditMe from 'pages/system/user/edit-me'
import UserNew from 'pages/system/user/new'
import UserSearch from 'pages/system/user/search'
import UpdatePassword from 'pages/system/user/update-password'

import { MenuPermission } from 'components/layout/components/menu/types'
import { useAuth } from 'contexts/auth/auth-context'

const Routes: React.FC = () => {
  const { user, getMenuPermission } = useAuth()
  const [menuPermission, setMenuPermission] = useState<MenuPermission>()

  useEffect(() => {
    setMenuPermission(getMenuPermission())
  }, [user?.permissions])

  return (
    <>
      {menuPermission?.system.profile && (
        <>
          <Route path='/system/profile/edit/:id' exact component={ProfileEdit} />
          <Route path='/system/profile/new' exact component={ProfileNew} />
          <Route path='/system/profile/search' exact component={ProfileSearch} />
        </>
      )}

      {menuPermission?.system.user && (
        <>
          <Route path='/system/user/edit/:id' exact component={UserEditAdmin} />
          <Route path='/system/user/new' exact component={UserNew} />
          <Route path='/system/user/search' exact component={UserSearch} />
        </>
      )}

      {menuPermission && (
        <>
          <Route path='/system/user/edit' exact component={UserEditMe} />
          <Route path='/system/user/update-password' exact component={UpdatePassword} />
        </>
      )}
    </>
  )
}

export default Routes
