import React, { useEffect, useState } from 'react'
import { Route } from 'react-router-dom'

import FinishTechnicalVisit from 'pages/technical-visit/finish-technical-visit'

import TechnicalVisitEdit from 'pages/technical-visit/technical-visit/edit'
import TechnicalVisitNew from 'pages/technical-visit/technical-visit/new'
import TechnicalVisitSearch from 'pages/technical-visit/technical-visit/search'

import TechnicianEdit from 'pages/technical-visit/technician/edit'
import TechnicianNew from 'pages/technical-visit/technician/new'
import TechnicianSearch from 'pages/technical-visit/technician/search'

import { MenuPermission } from 'components/layout/components/menu/types'
import { useAuth } from 'contexts/auth/auth-context'

const Routes: React.FC = () => {
  const { user, getMenuPermission } = useAuth()
  const [menuPermission, setMenuPermission] = useState<MenuPermission>()

  useEffect(() => {
    setMenuPermission(getMenuPermission())
  }, [user?.permissions])

  return (
    <>
      {menuPermission?.technicalVisit.finishTechnicalVisit && (
        <>
          <Route path='/technical-visit/finish/:id' exact component={FinishTechnicalVisit} />
        </>
      )}

      {menuPermission?.technicalVisit.technicalVisit && (
        <>
          <Route path='/technical-visit/edit/:id' exact component={TechnicalVisitEdit} />
          <Route path='/technical-visit/new' exact component={TechnicalVisitNew} />
          <Route path='/technical-visit/search' exact component={TechnicalVisitSearch} />
        </>
      )}

      {menuPermission?.technicalVisit.technician && (
        <>
          <Route path='/technical-visit/technician/edit/:id' exact component={TechnicianEdit} />
          <Route path='/technical-visit/technician/new' exact component={TechnicianNew} />
          <Route path='/technical-visit/technician/search' exact component={TechnicianSearch} />
        </>
      )}
    </>
  )
}

export default Routes
