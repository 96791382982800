import React, { useEffect } from 'react'
import { Grid } from 'react-md'

const Component: React.FC = () => {
  useEffect(() => {
    document.title = 'Home'
  }, [])

  return <Grid></Grid>
}

export default Component
