import React from 'react'
import {
  AppBar,
  AppBarAction,
  AppBarNav,
  Dialog,
  DialogContent,
  FontIcon,
  MenuSVGIcon,
  Tooltipped,
  useLayoutConfig,
  useToggle,
  Text,
  DialogFooter,
  Button,
} from 'react-md'

import { useAuth } from 'contexts/auth/auth-context'
import { Loading } from 'components/core'
interface Props {
  userName?: string
}

const ActionBar: React.FC<Props> = ({ userName }) => {
  const [visible, enable, disable] = useToggle(false)
  const { showNav } = useLayoutConfig()
  const { logout } = useAuth()

  return (
    <>
      <AppBar>
        <AppBarNav>
          <MenuSVGIcon onClick={showNav} />
        </AppBarNav>
        <AppBarAction onClick={enable} first>
          <FontIcon>person</FontIcon>
        </AppBarAction>
        <AppBarAction last>
          <Tooltipped id='logout' tooltip='Sair' position='left'>
            <FontIcon onClick={() => logout()}>logout</FontIcon>
          </Tooltipped>
        </AppBarAction>
        <Dialog id='top-app-bar-dialog' visible={visible} disableFocusOnMount={true} onRequestClose={disable} aria-labelledby='dialog-title'>
          <DialogContent>
            <Text margin='none'>Usuário logado: {userName}</Text>
          </DialogContent>
          <DialogFooter>
            <Button theme='primary' onClick={disable}>
              Fechar
            </Button>
          </DialogFooter>
        </Dialog>
      </AppBar>
      <Loading />
    </>
  )
}

export default React.memo(ActionBar)
