import React, { useEffect } from 'react'
import { FormMessage, TextField } from 'react-md'
import { usePromiseTracker } from 'react-promise-tracker'

import Cell from 'components/core/cell'

import { FieldProps } from './types'
import { useFormContext } from 'react-hook-form'

export const InputDatePicker = ({ name, desktopSize, tabletSize, disabled, ...rest }: FieldProps) => {
  const { promiseInProgress } = usePromiseTracker()
  const form = useFormContext()
  const value = form.watch(name)

  useEffect(() => {
    form.register({ name })
  }, [form.register])

  return (
    <Cell tabletSize={tabletSize} desktopSize={desktopSize}>
      <TextField
        id={name}
        name={name}
        type={'date'}
        theme={'underline'}
        value={value ? value.substring(0, 10) : ''}
        error={form.errors[name] ? true : false}
        disabled={disabled || promiseInProgress}
        onChange={selected => {
          if (form.errors[name]) form.clearError(name)
          if (selected.target.value?.length > 0) form.setValue(name, selected.target.value)
          else form.setValue(name, null)
        }}
        {...rest}
      />
      {form.errors[name] && (
        <FormMessage id={name} error>
          {form.errors[name].message}
        </FormMessage>
      )}
    </Cell>
  )
}
