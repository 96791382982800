export const getTechnicalVisitStatusDescription = (value: string): string => {
  return TechnicalVisitStatusOptions.filter(x => x.value === value?.toString())[0]?.label
}

export const getVisitTypeDescription = (value: string): string => {
  return VisitTypeOptions.filter(x => x.value === value?.toString())[0]?.label
}

export const getServicePerformedDescription = (value: string): string => {
  return ServicePerformedOptions.filter(x => x.value === value?.toString())[0]?.label
}

export interface TechnicalVisitStatusOption {
  value: TechnicalVisitStatusEnum | null
  label: string
}

export interface VisitTypeOption {
  value: VisitTypeEnum | null
  label: string
}

export interface ServicePerformedOption {
  value: ServicePerformedEnum | null
  label: string
}

export enum TechnicalVisitStatusEnum {
  Opened = 'Opened',
  Finished = 'Finished',
}

export enum VisitTypeEnum {
  Preventive = 'Preventive',
  Corrective = 'Corrective',
}

export enum ServicePerformedEnum {
  VisualIspection = 'VisualIspection',
  MechanicalIntervention = 'MechanicalIntervention',
}

export const TechnicalVisitStatusOptions: Array<TechnicalVisitStatusOption> = [
  { value: null, label: '' },
  { value: TechnicalVisitStatusEnum.Opened, label: 'Em Processo' },
  { value: TechnicalVisitStatusEnum.Finished, label: 'Finalizado' },
]

export const VisitTypeOptions: Array<VisitTypeOption> = [
  { value: null, label: '' },
  { value: VisitTypeEnum.Preventive, label: 'Preventiva' },
  { value: VisitTypeEnum.Corrective, label: 'Corretiva' },
]

export const ServicePerformedOptions: Array<ServicePerformedOption> = [
  { value: null, label: '' },
  { value: ServicePerformedEnum.VisualIspection, label: 'Inspeção Visual' },
  { value: ServicePerformedEnum.MechanicalIntervention, label: 'Intervenção Mecânica' },
]
