import Yup from 'utils/yup'

export const defaultValues = {
  visitDate: null,
  visitTime: null,
}

export const validationSchema = Yup.object().shape({
  visitDate: Yup.date().nullable().required(),
  visitTime: Yup.string().nullable().required(),
})
