import { useEffect } from 'react'
import { Select as SelectTemplate, FormMessage } from 'react-md'
import { usePromiseTracker } from 'react-promise-tracker'
import { useFormContext } from 'react-hook-form'

import Cell from 'components/core/cell'

import { FieldProps } from './types'

interface SelectProps extends FieldProps {
  labelEmpty?: string
  labelTrue?: string
  labelFalse?: string
}

export const SelectBoolean = ({ name, labelEmpty, labelTrue, labelFalse, desktopSize, tabletSize, disabled, ...rest }: SelectProps) => {
  const { promiseInProgress } = usePromiseTracker()
  const form = useFormContext()
  const value = form.watch(name)

  useEffect(() => {
    form.register({ name })
  }, [form.register])

  const getValue = (value?: any): string => {
    switch (value) {
      case true:
        return 'true'
      case false:
        return 'false'
      default:
        return ''
    }
  }

  return (
    <Cell tabletSize={tabletSize} desktopSize={desktopSize}>
      <SelectTemplate
        id={name}
        name={name}
        theme={'underline'}
        options={[
          { value: '', label: labelEmpty ?? '' },
          { value: 'true', label: labelTrue ?? 'Sim' },
          { value: 'false', label: labelFalse ?? 'Não' },
        ]}
        disableLeftAddon={true}
        value={getValue(value)}
        error={form.errors[name] ? true : false}
        disabled={disabled || promiseInProgress}
        onChange={select => {
          if (form.errors[name]) form.clearError(name)
          if (select === 'true') form.setValue(name, true)
          else if (select === 'false') form.setValue(name, false)
          else form.setValue(name, null)
        }}
        {...rest}
      />
      {form.errors[name] && (
        <FormMessage id={name} error style={{ paddingLeft: 0 }}>
          {form.errors[name].message}
        </FormMessage>
      )}
    </Cell>
  )
}
