import Yup from 'utils/yup'

export const defaultValues = {
  name: null,
  code: null,
  manufacturerCode: null,
  manufacturerName: null,
  equipmentModels: null,
  address: null,
}

export const validationSchema = Yup.object().shape({
  name: Yup.string().nullable().required().max(60, 'O nome deve ter no máximo 60 caracteres'),
  code: Yup.string().nullable().required().max(20, 'O código/part number deve ter no máximo 20 caracteres'),
  manufacturerCode: Yup.string().nullable().required().max(20, 'O código do fabricante deve ter no máximo 20 caracteres'),
  manufacturerName: Yup.string().nullable().required().max(60, 'O nome do fabricante deve ter no máximo 60 caracteres'),
  equipmentModels: Yup.string().nullable().required().max(100, 'Os modelos de equipamento devem ter no máximo 100 caracteres'),
  address: Yup.string().nullable().max(10, 'O endereço deve ter no máximo 10 caracteres'),
})

export const quantityDefaultValues = {
  quantity: null,
}

export const quantityValidationSchema = Yup.object().shape({
  quantity: Yup.number().nullable().required().min(1, 'Quantidade não informada'),
})
