import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { FormContext, useForm } from 'react-hook-form'
import { Card, CardActions, CardContent, FontIcon, Grid, useToggle } from 'react-md'

import { ButtonLog, Cell, PageTitle, Modal, ButtonSuccess, ButtonEdit, ConfirmDialog } from 'components/core'
import { handleSubmit, Input, InputDatePicker, InputDecimal, Select } from 'components/form'
import { DateLabel, DecimalLabel, NumberLabel, TextLabel } from 'components/label'
import {
  getDestinationExpenseDescription,
  getExpenseTypeDescription,
  getPayableStatusDescription,
  PayableDocumentTypeOptions,
  PayableStatusEnum,
} from 'types/financial/payable'

import { defaultValues, paidDefaultValues, paidValidationSchema, validationSchema } from './config'
import { handlePaid, handleGet, handleSave, handleDelayed } from './actions'

import ButtonError from 'components/core/button-error'

const Component: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const form = useForm({ defaultValues })
  const paidform = useForm({ defaultValues: paidDefaultValues })
  const [visibleDelayModal, enableDelayModal, disableDelayModal] = useToggle(false)
  const [visiblePaidModal, enablePaidModal, disablePaidModal] = useToggle(false)
  const [payable, setPayable] = useState<any>({})
  const [totalValue, setTotalValue] = useState<number>(0)
  const status = form.watch('status')
  const discount = form.watch('discount')
  const interest = form.watch('interest')
  const fine = form.watch('fine')

  useEffect(() => {
    document.title = 'Edição de Lançamento - Contas a Pagar'
  }, [])

  useEffect(() => {
    load()
  }, [id])

  useEffect(() => setTotalValue((payable?.value ?? 0) - (discount ?? 0) + (interest ?? 0) + (fine ?? 0)), [discount, interest, fine])

  const load = async () => {
    const data = await handleGet(id)
    if (data) {
      form.reset(data)
      setPayable(data)
    }
  }

  return (
    <>
      <Grid>
        <Cell>
          <PageTitle title='Edição de Lançamento - Contas a Pagar' />
          <Card fullWidth>
            <CardContent>
              <FormContext {...form}>
                <Grid>
                  <TextLabel name='companyFantasyName' label='Empresa' value={payable.companyFantasyName} desktopSize={4} tabletSize={4} />
                  <TextLabel name='supplierFantasyName' label='Fornecedor' value={payable.supplierFantasyName} desktopSize={4} tabletSize={4} />
                  <TextLabel name='clientFantasyName' label='Cliente' value={payable.clientFantasyName} desktopSize={4} tabletSize={4} />
                  <Select name='documentType' label='Tipo do Documento *' options={PayableDocumentTypeOptions} desktopSize={3} tabletSize={4} />
                  <Input name='documentNumber' label='Número do Documento' desktopSize={3} tabletSize={4} />
                  <NumberLabel name='installment' label='Número da Parcela' value={payable.installment} desktopSize={3} tabletSize={4} />
                  <DateLabel
                    name='originalDueDate'
                    label='Data de Vencimento Original'
                    value={payable.originalDueDate}
                    desktopSize={3}
                    tabletSize={4}
                  />
                  <InputDatePicker name='dueDate' label='Data de Vencimento' desktopSize={3} tabletSize={4} />
                  <DateLabel name='paymentDate' label='Data de Pagamento' value={payable.paymentDate} desktopSize={3} tabletSize={4} />
                  <DecimalLabel name='value' label='Valor' value={payable.value} desktopSize={2} tabletSize={4} />
                  <InputDecimal name='discount' label='Desconto' desktopSize={2} tabletSize={4} />
                  <InputDecimal name='interest' label='Juros' desktopSize={2} tabletSize={4} />
                  <InputDecimal name='fine' label='Multa' desktopSize={2} tabletSize={4} />
                  <DecimalLabel name='totalValue' label='Valor Total' value={totalValue} desktopSize={2} tabletSize={4} />
                  <TextLabel name='status' label='Status' value={getPayableStatusDescription(payable.status)} desktopSize={2} tabletSize={4} />
                  <TextLabel
                    name='destinationExpense'
                    label='Destino da Despesa'
                    value={getDestinationExpenseDescription(payable.destinationExpense)}
                    desktopSize={2}
                    tabletSize={4}
                  />
                  <TextLabel
                    name='expenseType'
                    label='Tipo de Gasto'
                    value={getExpenseTypeDescription(payable.expenseType)}
                    desktopSize={2}
                    tabletSize={4}
                  />
                  <Input name='observation' label='Observações' desktopSize={12} tabletSize={8} />
                </Grid>
              </FormContext>
            </CardContent>
            <CardActions>
              <ButtonSuccess
                text='Salvar'
                icon={<FontIcon>done</FontIcon>}
                disabled={!form.formState.dirty || status === PayableStatusEnum.Paid}
                onClick={async () =>
                  await handleSubmit(form, validationSchema, async () => {
                    if (await handleSave({ ...form.getValues(), id: id })) form.reset(form.getValues(), { dirty: false })
                  })
                }
              />
              <ButtonEdit
                text='Lançamento Pago'
                theme='secondary'
                icon={<FontIcon>payments</FontIcon>}
                disabled={status === PayableStatusEnum.Paid}
                onClick={async () => enablePaidModal()}
              />
              <ButtonError
                text='Lançamento Em Atraso'
                theme='warning'
                icon={<FontIcon>event_busy</FontIcon>}
                disabled={status !== PayableStatusEnum.Payable}
                onClick={async () => enableDelayModal()}
              />
              <ButtonLog entity='Payable' id={id} />
            </CardActions>
          </Card>
        </Cell>
      </Grid>

      <Modal
        title={'Lançamento Pago'}
        width={300}
        visible={visiblePaidModal}
        confirmTitle={'Salvar'}
        confirmDisabled={!paidform.formState.dirty}
        confirmAction={async () => {
          await handleSubmit(paidform, paidValidationSchema, async () => {
            if (await handlePaid({ id, ...paidform.getValues() })) {
              paidform.reset(paidDefaultValues, { dirty: false })
              load()
              disablePaidModal()
            }
          })
        }}
        closeTitle={'Fechar'}
        closeAction={async () => {
          paidform.reset(paidDefaultValues, { dirty: false })
          disablePaidModal()
        }}>
        <Grid>
          <FormContext {...paidform}>
            <InputDatePicker name='paymentDate' label='Data de Pagamento *' desktopSize={12} tabletSize={8} />
          </FormContext>
        </Grid>
      </Modal>

      <ConfirmDialog
        text='Deseja realmente alterar o lançamento para o status Em Atraso?'
        visible={visibleDelayModal}
        confirmAction={async () => {
          if (await handleDelayed({ id })) {
            load()
            disableDelayModal()
          }
        }}
        cancelAction={async () => disableDelayModal()}
      />
    </>
  )
}

export default Component
