import { LayoutNavigationItem, LayoutNavigationTree } from 'react-md'

import { MenuPermission } from 'components/layout/components/menu/types'

import { getFinancialMenu } from './components/financial-menu'
import { getInventoryMenu } from './components/inventory-menu'
import { getRegisterMenu } from './components/register-menu'
import { getSaleMenu } from './components/sale-menu'
import { getTechnicalVisitMenu } from './components/technical-visit-menu'

interface BodyProps {
  menuPermissions: MenuPermission
  getMenu: (id: string, parent: string | null, title: string, icon: string, path: string | null) => LayoutNavigationItem
}

export const getBody = ({ menuPermissions, getMenu }: BodyProps) => {
  const body: LayoutNavigationTree<LayoutNavigationItem> = {}

  getRegisterMenu({ permissions: menuPermissions, menu: body, getMenu })
  getSaleMenu({ permissions: menuPermissions, menu: body, getMenu })
  getFinancialMenu({ permissions: menuPermissions, menu: body, getMenu })
  getInventoryMenu({ permissions: menuPermissions, menu: body, getMenu })
  getTechnicalVisitMenu({ permissions: menuPermissions, menu: body, getMenu })

  return body
}
