import React, { useEffect, useState } from 'react'
import { FormContext, FormContextValues, useForm } from 'react-hook-form'
import {
  Button as TemplateButton,
  Card,
  CardActions,
  CardContent,
  FontIcon,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  TextIconSpacing,
} from 'react-md'
import { format } from 'date-fns'

import { handleSubmit, InputDatePicker, InputDecimal, InputNumber } from 'components/form'
import { Cell, Callout, ButtonSuccess } from 'components/core'
import { CalloutType } from 'components/core/callout'
import { formatCurrency } from 'types/core/currency'

import { handleCalculate, handleAddDay, handleSubtractDay } from './actions'
import { defaultValues, validationSchema } from './configs'
import { Installment } from './types'

interface ComponentProps {
  form: FormContextValues<any>
  toogleClear: boolean
}

const Component: React.FC<ComponentProps> = ({ form, toogleClear }) => {
  const formInstallment = useForm({ defaultValues })
  const [installments, setInstallments] = useState<Array<Installment>>([])

  useEffect(() => form.register({ name: 'installments' }), [form.register])

  useEffect(() => {
    form.setValue(
      'installments',
      installments.map(({ dueDate, value }) => {
        return { dueDate: format(dueDate, "yyyy'-'MM'-'dd"), value: value }
      }),
    )
  }, [installments])

  useEffect(() => {
    setInstallments([])
    formInstallment.reset(defaultValues, { dirty: false })
  }, [toogleClear])

  return (
    <Grid>
      <Cell>
        <Card fullWidth>
          <CardContent>
            <Callout text={'Informe os valores abaixo para realizar o cálculo do lançamento'} type={CalloutType.Info} />
            <Grid>
              <FormContext {...form}>
                <InputDecimal name='entryValue' label='Valor de Entrada' desktopSize={2} tabletSize={3} />
              </FormContext>
              <FormContext {...formInstallment}>
                <InputNumber name='quantity' label='Quantidade *' desktopSize={2} tabletSize={2} />
                <InputNumber name='interval' label='Intervalo de Dias *' desktopSize={2} tabletSize={3} />
                <InputDecimal name='totalValue' label='Valor Total do Lançamento *' desktopSize={3} tabletSize={4} />
                <InputDatePicker name='initialDueDate' label='Data de Vencimento Inicial*' desktopSize={3} tabletSize={4} />
              </FormContext>
            </Grid>
            <Grid>
              <Cell>
                {installments.length > 0 && (
                  <Table fullWidth dense>
                    <TableHeader>
                      <TableRow>
                        <TableCell hAlign='center'>Parcela</TableCell>
                        <TableCell hAlign='center'>Vencimento</TableCell>
                        <TableCell hAlign='center'>Dia da Semana</TableCell>
                        <TableCell hAlign='center'>Valor</TableCell>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {installments.map((data: Installment, index) => (
                        <TableRow key={index}>
                          <TableCell hAlign='center'>{index + 1}</TableCell>
                          <TableCell hAlign='center'>
                            <TemplateButton themeType='flat' theme='clear'>
                              <TextIconSpacing
                                icon={<FontIcon onClick={() => setInstallments(handleSubtractDay(index, installments))}>remove</FontIcon>}>
                                <TextIconSpacing
                                  icon={<FontIcon onClick={() => setInstallments(handleAddDay(index, installments))}>add</FontIcon>}
                                  iconAfter>
                                  {format(data.dueDate, "dd'/'MM'/'yyyy")}
                                </TextIconSpacing>
                              </TextIconSpacing>
                            </TemplateButton>
                          </TableCell>
                          <TableCell hAlign='center'>{data.dayOfWeek}</TableCell>
                          <TableCell hAlign='center'>R$ {formatCurrency(data.value)}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
              </Cell>
            </Grid>
          </CardContent>
          <CardActions>
            <ButtonSuccess
              text='Calcular'
              icon={<FontIcon>calculate</FontIcon>}
              disabled={!formInstallment.formState.dirty}
              onClick={async () =>
                await handleSubmit(formInstallment, validationSchema, () => setInstallments(handleCalculate(formInstallment.getValues())))
              }
            />
          </CardActions>
        </Card>
      </Cell>
    </Grid>
  )
}

export default Component
