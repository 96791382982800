import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Card, CardActions, CardContent, FontIcon, Grid } from 'react-md'

import { Cell, PageTitle, ButtonSuccess } from 'components/core'
import { addressDefaultValues, addressValidationSchema } from 'components/address/config'

import { infoDefaultValues, infoValidationSchema } from './components/general-information/info/config'
import { handleSave, handleValidateForms } from './actions'
import Info from './components/general-information/info'
import Address from './components/general-information/address'

const Component: React.FC = () => {
  const infoForm = useForm({ defaultValues: infoDefaultValues })
  const addressForm = useForm({ defaultValues: addressDefaultValues })

  useEffect(() => {
    document.title = 'Novo Cliente'
  }, [])

  return (
    <Grid>
      <Cell>
        <PageTitle title='Novo Cliente' />
      </Cell>
      <Cell>
        <Card fullWidth>
          <CardContent>
            <Info form={infoForm} />
            <Address form={addressForm} />
          </CardContent>
          <CardActions>
            <ButtonSuccess
              text='Salvar'
              icon={<FontIcon>done</FontIcon>}
              disabled={!infoForm.formState.dirty && !addressForm.formState.dirty}
              onClick={async () => {
                if (
                  await handleValidateForms(
                    { form: infoForm, validationSchema: infoValidationSchema },
                    { form: addressForm, validationSchema: addressValidationSchema },
                  )
                ) {
                  let res = await handleSave({ ...infoForm.getValues(), address: addressForm.getValues() })
                  if (res?.success) {
                    infoForm.reset(infoDefaultValues, { dirty: false })
                    addressForm.reset(addressDefaultValues, { dirty: false })
                    window.open(`/sale/client/edit/${res.data}`, '_self')
                  }
                }
              }}
            />
          </CardActions>
        </Card>
      </Cell>
    </Grid>
  )
}

export default Component
