import React, { useEffect } from 'react'
import Bounce from 'react-reveal/Bounce'
import { FormContext, useForm } from 'react-hook-form'
import { Grid, Card, CardContent, FontIcon } from 'react-md'

import { handleSubmit, Input } from 'components/form'
import { Cell, ButtonCustom } from 'components/core'
import { history } from 'utils/history'

import Logo from 'components/core/logo'

import { defaultValues, validationSchema } from './config'
import { handleUpdatePassword } from './actions'

const Component: React.FC = () => {
  const form = useForm({ defaultValues })

  useEffect(() => {
    document.title = 'Esqueci Minha Senha'
  }, [])

  return (
    <Bounce bottom>
      <Grid>
        <Cell tabletSize={6} tabletStart={2} desktopSize={4} desktopStart={5}>
          <Card fullWidth>
            <CardContent>
              <FormContext {...form}>
                <Logo />
                <Input name='email' label='Email *' rightChildren={<FontIcon>person</FontIcon>} />
                <Input name='login' label='Usuário *' rightChildren={<FontIcon>account_box</FontIcon>} />
                <Input name='code' label='Código *' rightChildren={<FontIcon>qr_code</FontIcon>} />
                <Input name='newPassword' label='Nova Senha *' type='password' desktopSize={4} rightChildren={<FontIcon>vpn_key</FontIcon>} />
                <Input
                  name='confirmNewPassword'
                  label='Confirmação de Senha *'
                  type='password'
                  desktopSize={4}
                  rightChildren={<FontIcon>vpn_key</FontIcon>}
                />
                <ButtonCustom
                  text='Alterar Senha'
                  icon={<FontIcon>done</FontIcon>}
                  disabled={!form.formState.dirty}
                  whiteThemeBackgroundColor={'#0277bd'}
                  darkThemeBackgroundColor={'#01579b'}
                  onClick={async () =>
                    await handleSubmit(form, validationSchema, async () => {
                      await handleUpdatePassword(form.getValues())
                      form.reset(defaultValues, { dirty: false })
                    })
                  }
                  style={{ width: '100%' }}
                />
                <ButtonCustom
                  text='Voltar'
                  icon={<FontIcon>arrow_back</FontIcon>}
                  whiteThemeBackgroundColor={'#e0e0e0'}
                  darkThemeBackgroundColor={'#424242'}
                  onClick={async () => history.push('/forgot-password/send')}
                  style={{ width: '100%' }}
                />
              </FormContext>
            </CardContent>
          </Card>
        </Cell>
      </Grid>
    </Bounce>
  )
}

export default Component
