export interface StateOption {
  value: StateEnum | null
  label: string
}

export enum StateEnum {
  AC = 'AC',
  AL = 'AL',
  AP = 'AP',
  AM = 'AM',
  BA = 'BA',
  CE = 'CE',
  DF = 'DF',
  ES = 'ES',
  GO = 'GO',
  MA = 'MA',
  MT = 'MT',
  MS = 'MS',
  MG = 'MG',
  PR = 'PR',
  PB = 'PB',
  PA = 'PA',
  PE = 'PE',
  PI = 'PI',
  RJ = 'RJ',
  RN = 'RN',
  RS = 'RS',
  RO = 'RO',
  RR = 'RR',
  SC = 'SC',
  SE = 'SE',
  SP = 'SP',
  TO = 'TO',
}

export const StateOptions: Array<StateOption> = [
  { value: null, label: '' },
  { value: StateEnum.AC, label: 'AC' },
  { value: StateEnum.AL, label: 'AL' },
  { value: StateEnum.AP, label: 'AP' },
  { value: StateEnum.AM, label: 'AM' },
  { value: StateEnum.BA, label: 'BA' },
  { value: StateEnum.CE, label: 'CE' },
  { value: StateEnum.DF, label: 'DF' },
  { value: StateEnum.ES, label: 'ES' },
  { value: StateEnum.GO, label: 'GO' },
  { value: StateEnum.MA, label: 'MA' },
  { value: StateEnum.MT, label: 'MT' },
  { value: StateEnum.MS, label: 'MS' },
  { value: StateEnum.MG, label: 'MG' },
  { value: StateEnum.PR, label: 'PR' },
  { value: StateEnum.PB, label: 'PB' },
  { value: StateEnum.PA, label: 'PA' },
  { value: StateEnum.PE, label: 'PE' },
  { value: StateEnum.PI, label: 'PI' },
  { value: StateEnum.RJ, label: 'RJ' },
  { value: StateEnum.RN, label: 'RN' },
  { value: StateEnum.RS, label: 'RS' },
  { value: StateEnum.RO, label: 'RO' },
  { value: StateEnum.RR, label: 'RR' },
  { value: StateEnum.SC, label: 'SC' },
  { value: StateEnum.SE, label: 'SE' },
  { value: StateEnum.SP, label: 'SP' },
  { value: StateEnum.TO, label: 'TO' },
]
