import React from 'react'
import { FormContext, FormContextValues } from 'react-hook-form'

import { Input, Select } from 'components/form'

import { ZipCode } from './components/zip-code'
import { StateOptions } from './types'

interface ComponentProps {
  form: FormContextValues<any>
}

const Address: React.FC<ComponentProps> = ({ form }) => {
  return (
    <FormContext {...form}>
      <ZipCode name='zipCode' label='Cep *' maxLength={8} desktopSize={2} tabletSize={4} />
      <Select name='state' label='Estado *' options={StateOptions} desktopSize={2} tabletSize={4} />
      <Input name='city' label='Cidade *' desktopSize={4} tabletSize={4} />
      <Input name='neighborhood' label='Bairro *' desktopSize={4} tabletSize={4} />
      <Input name='street' label='Logradouro *' desktopSize={6} tabletSize={4} />
      <Input name='number' label='Número *' desktopSize={2} tabletSize={2} />
      <Input name='complement' label='Complemento' desktopSize={4} tabletSize={2} />
    </FormContext>
  )
}

export default Address
