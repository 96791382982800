import { toast } from 'react-toastify'

import api from 'services/audit/search'
import { DataTableRequest } from 'components/data-table/types'

export const handleSearch = async (request: DataTableRequest) => {
  let res = await api.search(request)

  return res?.data ?? []
}

export const convertParam = (filter: string) => {
  try {
    return JSON.parse(atob(filter))
  } catch {
    toast.error('Parâmetros inválidos')
    return ''
  }
}
