import Yup from 'utils/yup'

export const defaultValues = {
  login: '',
  password: '',
}

export const validationSchema = Yup.object().shape({
  login: Yup.string().required(),
  password: Yup.string().required(),
})
