import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { FormContext, useForm } from 'react-hook-form'
import { Card, CardActions, CardContent, FontIcon, Grid, TabPanel, TabPanels, Tabs, TabsManager } from 'react-md'

import { ButtonLog, Cell, PageTitle, ButtonSuccess } from 'components/core'
import { handleSubmit, Input, Select, SelectBoolean } from 'components/form'
import { EquipmentTypeOptions } from 'types/inventory/maintenance-schedule'

import { handleGetMaintenanceSchedule, handleSave } from './actions'
import { defaultValues, validationSchema } from './config'

import Inspections from './components/maintenance-inspection'
import Actions from './components/maintenance-action'

const Component: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const form = useForm({ defaultValues })
  const [maintenanceInspections, setMaintenanceInspections] = useState<Array<any> | null>(null)
  const [maintenanceActions, setMaintenanceActions] = useState<Array<any> | null>(null)

  useEffect(() => {
    document.title = 'Edição de Agenda de Manutenção'
  }, [])

  useEffect(() => {
    loadMaintenanceSchedule()
  }, [id])

  const loadMaintenanceSchedule = async () => {
    let res = await handleGetMaintenanceSchedule(id)
    if (res) {
      setMaintenanceInspections(
        res.maintenanceInspections.sort((a: any, b: any) => +(a.description > b.description) || +(a.description === b.description) - 1),
      )
      setMaintenanceActions(
        res.maintenanceActions.sort(
          (a: any, b: any) =>
            +(a.maintenancePeriodInHours > b.maintenancePeriodInHours) || +(a.maintenancePeriodInHours === b.maintenancePeriodInHours) - 1,
        ),
      )
      delete res.maintenanceInspections
      delete res.maintenanceActions
      form.reset(res)
    }
  }

  return (
    <Grid>
      <Cell>
        <PageTitle title='Edição de Agenda de Manutenção' />
        <TabsManager tabs={['Informações Gerais', 'Itens de Inspeção', 'Ações de Manutenção']} tabsId='profile-tabs'>
          <Tabs />
          <TabPanels persistent={true}>
            <TabPanel>
              <Card fullWidth>
                <CardContent>
                  <FormContext {...form}>
                    <Grid>
                      <Input name='description' label='Descrição *' desktopSize={6} tabletSize={8} />
                      <Select name='equipmentType' label='Tipo de Equipamento *' options={EquipmentTypeOptions} desktopSize={3} tabletSize={4} />
                      <SelectBoolean name='active' label='Ativo *' desktopSize={3} tabletSize={4} />
                      <Input name='observation' label='Observação' desktopSize={12} tabletSize={8} />
                    </Grid>
                  </FormContext>
                </CardContent>
                <CardActions>
                  <ButtonSuccess
                    text='Salvar'
                    icon={<FontIcon>done</FontIcon>}
                    disabled={!form.formState.dirty}
                    onClick={async () =>
                      await handleSubmit(form, validationSchema, async () => {
                        if (await handleSave({ ...form.getValues(), id: id })) form.reset(form.getValues(), { dirty: false })
                      })
                    }
                  />
                  <ButtonLog entity='MaintenanceSchedule' id={id} />
                </CardActions>
              </Card>
            </TabPanel>
            <TabPanel>
              <Inspections
                maintenanceScheduleId={id}
                maintenanceInspections={maintenanceInspections}
                loadMaintenanceSchedule={loadMaintenanceSchedule}
              />
            </TabPanel>
            <TabPanel>
              <Actions maintenanceScheduleId={id} maintenanceActions={maintenanceActions} loadMaintenanceSchedule={loadMaintenanceSchedule} />
            </TabPanel>
          </TabPanels>
        </TabsManager>
      </Cell>
    </Grid>
  )
}

export default Component
