import Yup from 'utils/yup'

export const defaultValues = {
  maintenanceSchedule: {
    id: null,
  },
  model: null,
  voltage: null,
  yearManufacture: null,
  flowRate: null,
  dewPoint: null,
  serialNumber: null,
  airReservoirVolume: null,
  oxygenReservoirVolume: null,
  partNumber: null,
  pressure: null,
  degreePurity: null,
  maintenanceContract: null,
  manufacturerName: null,
  address: null,
}

export const validationSchema = Yup.object().shape({
  maintenanceSchedule: Yup.object({
    id: Yup.string().nullable(),
  }),
  model: Yup.string().nullable().required().max(60, 'O modelo deve ter no máximo 60 caracteres'),
  voltage: Yup.string().nullable(),
  yearManufacture: Yup.number().nullable().required().min(1950, 'Ano de fabricação inválido'),
  flowRate: Yup.number().nullable(),
  dewPoint: Yup.number().nullable(),
  serialNumber: Yup.string().nullable().required().max(20, 'O número de série deve ter no máximo 20 caracteres'),
  airReservoirVolume: Yup.number().nullable(),
  oxygenReservoirVolume: Yup.number().nullable(),
  partNumber: Yup.string().nullable(),
  pressure: Yup.number().nullable(),
  degreePurity: Yup.string().nullable().max(30, 'O grau de pureza deve ter no máximo 30 caracteres'),
  maintenanceContract: Yup.bool().nullable().required(),
  manufacturerName: Yup.string().nullable().required(),
  address: Yup.string().nullable().max(10, 'O endereço deve ter no máximo 10 caracteres'),
})
