import clientApi from 'services/sale/client'

export const handleGetProposals = async (id: string) => {
  let res = await clientApi.getProposalsByClientId(id)

  return res?.data?.data
}

export const handleEditProposal = (id: string) => {
  window.open(`/sale/proposal/edit/${id}`)
}
