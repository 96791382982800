import { trackPromise } from 'react-promise-tracker'

import api from 'utils/api'
import { ApiResponse } from 'utils/types'
import { DataTableRequest } from 'components/data-table/types'

const url = '/v1/equipment'
const Api = {
  get: (id: string): Promise<ApiResponse> => trackPromise<ApiResponse>(api.get(`${url}/${id}`)),
  getFinishEquipment: (id: string): Promise<ApiResponse> => trackPromise<ApiResponse>(api.get(`${url}/${id}/finish`)),
  create: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(url, request)),
  search: (request: DataTableRequest): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/search`, request)),
  searchMaintenanceActions: (request: DataTableRequest): Promise<ApiResponse> =>
    trackPromise<ApiResponse>(api.post(`${url}/maintenance-actions/search`, request)),
  searchMaintenanceActionsDone: (request: any): Promise<ApiResponse> =>
    trackPromise<ApiResponse>(api.post(`${url}/maintenance-actions/done/search`, request)),
  searchTechnicalStart: (request: DataTableRequest): Promise<ApiResponse> =>
    trackPromise<ApiResponse>(api.post(`${url}/technical-start/search`, request)),
  searchTechnicalVisitDone: (request: DataTableRequest): Promise<ApiResponse> =>
    trackPromise<ApiResponse>(api.post(`${url}/technical-visit/done/search`, request)),
  autocomplete: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/autocomplete`, request)),
  createOrUpdateTechnicalStart: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/technical-start`, request)),
  update: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.put(url, request)),
  updateMaintenanceSchedule: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.put(`${url}/maintenance-schedule`, request)),
  delete: (id: string): Promise<ApiResponse> => trackPromise<ApiResponse>(api.delete(`${url}/${id}`)),
}

export default Api
