import { toast } from 'react-toastify'

import clientApi from 'services/sale/client'
import { handleValidate } from 'components/form/form-submit'

import { FormList } from './types'

export const handleValidateForms = async (infoForm: FormList, addressForm: FormList): Promise<boolean> => {
  let success = true

  if (!(await handleValidate(infoForm.form, infoForm.validationSchema))) success = false
  if (!(await handleValidate(addressForm.form, addressForm.validationSchema))) success = false

  if (!success) toast.warn('Corrija os campos para prosseguir')

  return success
}

export const handleSave = async (values: any) => {
  let res = await clientApi.create(values)

  return res?.data
}
