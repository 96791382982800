export interface VoltageOption {
  value: VoltageEnum | null
  label: string
}

export enum VoltageEnum {
  None = 'None',
  V127 = 'V127',
  V220 = 'V220',
  V380 = 'V380',
  V440 = 'V440',
}

export const VoltageOptions: Array<VoltageOption> = [
  { value: null, label: '' },
  { value: VoltageEnum.None, label: 'Voltagem não informada' },
  { value: VoltageEnum.V127, label: '127V' },
  { value: VoltageEnum.V220, label: '220V' },
  { value: VoltageEnum.V380, label: '380V' },
  { value: VoltageEnum.V440, label: '440V' },
]
