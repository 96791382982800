import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Card, CardActions, CardContent, FontIcon, Grid } from 'react-md'

import { Cell, PageTitle, ButtonSuccess } from 'components/core'
import { handleSubmit } from 'components/form'

import { defaultValues, validationSchema } from './components/info/config'
import { handleSave } from './actions'

import Info from './components/info'
import File from './components/file'

const Component: React.FC = () => {
  const form = useForm({ defaultValues })
  const [toogleClear, setToogleClear] = useState(false)

  useEffect(() => {
    document.title = 'Nova Proposta'
  }, [])

  return (
    <Grid>
      <Cell>
        <PageTitle title='Nova Proposta' />
        <Card fullWidth>
          <CardContent>
            <Info form={form} />
            <File form={form} toogleClear={toogleClear} />
          </CardContent>
          <CardActions>
            <ButtonSuccess
              text='Salvar'
              icon={<FontIcon>done</FontIcon>}
              disabled={!form.formState.dirty}
              onClick={async () =>
                await handleSubmit(form, validationSchema, async () => {
                  if (await handleSave(form.getValues())) {
                    form.reset(defaultValues, { dirty: false })
                    setToogleClear(!toogleClear)
                  }
                })
              }
            />
          </CardActions>
        </Card>
      </Cell>
    </Grid>
  )
}

export default Component
