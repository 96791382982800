import React, { useEffect, useState } from 'react'
import { Card, CardActions, CardContent, FontIcon, TableCell, TableRow, useToggle } from 'react-md'

import { DataTableResult } from 'components/data-table/types'
import { CustomButton, DataTable } from 'components/data-table'

import { handleGetParts } from './actions'
import { ButtonEdit } from 'components/core'

import AddPart from './components/add-part'
import RemovePart from './components/remove-part'

interface ComponentProps {
  id: string
}

const Component: React.FC<ComponentProps> = ({ id }) => {
  const [filter, setFilter] = useState<any>()
  const [result, setResult] = useState<DataTableResult>({ total: 0, results: [] })
  const [toogleUpdate, setToogleUpdate] = useState<boolean>(false)
  const [deleteId, setDeleteId] = useState<string>()
  const [visibleAddPart, enableAddPart, disableAddPart] = useToggle(false)
  const [visibleRemovePart, enableRemovePart, disableRemovePart] = useToggle(false)

  useEffect(() => {
    setFilter({ technicalVisitId: id })
  }, [id])

  return (
    <>
      <Card fullWidth>
        <CardContent>
          <DataTable
            filter={filter}
            header={[{ label: 'Peça' }, { label: 'Quantidade' }, { label: '' }]}
            result={result}
            setResult={setResult}
            search={handleGetParts}
            total={result ? result.total : 0}
            showEmptyMessageAlert={false}
            emptyMessage={'Nenhuma peça localizada'}
            update={toogleUpdate}
            wrapTableWithCard={false}>
            {result &&
              result.results &&
              result.results.map((data: any, index) => (
                <TableRow key={index}>
                  <TableCell>{data.partName}</TableCell>
                  <TableCell>{data.quantity}</TableCell>
                  <TableCell align='right'>
                    <CustomButton
                      id={`delete-hours-${index}`}
                      icon={'do_not_disturb_on'}
                      tooltip={'Remover Peças'}
                      whiteThemeBackgroundColor={'#ff8a65'}
                      darkThemeBackgroundColor={'#d84315'}
                      action={async () => {
                        setDeleteId(data.partId)
                        enableRemovePart()
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </DataTable>
        </CardContent>
        <CardActions>
          <ButtonEdit text='Adicionar Peças' icon={<FontIcon>add</FontIcon>} onClick={async () => enableAddPart()} />
        </CardActions>
      </Card>

      {deleteId && (
        <RemovePart
          technicalVisitId={id}
          partId={deleteId}
          visibleModal={visibleRemovePart}
          disableModal={disableRemovePart}
          loadParts={() => setToogleUpdate(!toogleUpdate)}
          onHide={() => setDeleteId(undefined)}
        />
      )}

      <AddPart technicalVisitId={id} visibleModal={visibleAddPart} disableModal={disableAddPart} loadParts={() => setToogleUpdate(!toogleUpdate)} />
    </>
  )
}

export default Component
