import React from 'react'
import { Dialog, DialogContent, DialogFooter, Text } from 'react-md'

import ButtonError from './button-error'
import ButtonSuccess from './button-success'

interface Props {
  title?: string
  text: string
  visible: boolean
  confirmTitle?: string
  confirmAction: () => Promise<void>
  cancelTitle?: string
  cancelAction: () => Promise<void>
  onHide?: Function
}

const ConfirmDialog: React.FC<Props> = ({ title, text, visible, confirmTitle, confirmAction, cancelTitle, cancelAction, onHide }) => {
  return (
    <Dialog
      id='confirm-dialog'
      aria-labelledby='dialog-title'
      disableFocusOnMount={true}
      visible={visible}
      onRequestClose={() => (onHide ? onHide() : {})}>
      <DialogContent>
        <Text id='dialog-title' margin='none' color='secondary' type='headline-6'>
          {title ?? 'Confirmação'}
        </Text>
        <Text margin='none' type='body-2' style={{ marginTop: 10 }}>
          {text}
        </Text>
      </DialogContent>
      <DialogFooter>
        <ButtonSuccess text={confirmTitle || 'Sim'} onClick={confirmAction} />
        <ButtonError text={cancelTitle || 'Não'} onClick={cancelAction} />
      </DialogFooter>
    </Dialog>
  )
}

export default ConfirmDialog
