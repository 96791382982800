import api from 'services/sale/proposal'

export const handleGetFiles = async (id: string) => {
  let res = await api.getFilesById(id)

  return res?.data?.data
}

export const handleAddFile = async (values: any) => {
  let res = await api.addFile(values)

  return res?.data?.success
}

export const handleRemoveFile = async (proposalId: string, id: string) => {
  let res = await api.removeFile(proposalId, id)

  return res?.data?.success
}

export const handleGetFile = async (fileName: string, proposalId: string, fileId: string) => {
  let res = await api.getFile(proposalId, fileId)

  download(fileName, res?.data)
}

const download = (fileName: string, blob: any) => {
  const url = window.URL.createObjectURL(new Blob([blob]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
  link.parentNode?.removeChild(link)
}
