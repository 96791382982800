import { LayoutNavigationItem, LayoutNavigationTree } from 'react-md'

import { MenuPermission } from 'components/layout/components/menu/types'

interface getMenuProps {
  permissions: MenuPermission
  menu: LayoutNavigationTree<LayoutNavigationItem>
  getMenu: (id: string, parent: string | null, title: string, icon: string, path: string | null) => LayoutNavigationItem
}

export const getInventoryMenu = ({ permissions, menu, getMenu }: getMenuProps) => {
  if (permissions.inventory.equipment || permissions.inventory.part || permissions.inventory.report) {
    menu['/inventory'] = getMenu('/inventory', null, 'Estoque', 'inventory_2', null)

    if (permissions.inventory.equipment) {
      menu['/inventory/equipment'] = getMenu('/inventory/equipment', '/inventory', 'Equipamento', 'precision_manufacturing', null)
      menu['/inventory/equipment/new'] = getMenu('/inventory/equipment/new', '/inventory/equipment', 'Novo', 'add', '/inventory/equipment/new')
      menu['/inventory/equipment/search'] = getMenu(
        '/inventory/equipment/search',
        '/inventory/equipment',
        'Pesquisa',
        'search',
        '/inventory/equipment/search',
      )
    }

    if (permissions.inventory.part) {
      menu['/inventory/part'] = getMenu('/inventory/part', '/inventory', 'Peça', 'home_repair_service', null)
      menu['/inventory/part/new'] = getMenu('/inventory/part/new', '/inventory/part', 'Nova', 'add', '/inventory/part/new')
      menu['/inventory/part/search'] = getMenu('/inventory/part/search', '/inventory/part', 'Pesquisa', 'search', '/inventory/part/search')
    }

    if (permissions.inventory.report) {
      menu['/inventory/report'] = getMenu('/inventory/report', '/inventory', 'Relatórios', 'receipt_long', null)
      menu['/inventory/report/inventory'] = getMenu(
        '/inventory/report/inventory',
        '/inventory/report',
        'Estoque',
        'receipt_long',
        '/inventory/report/inventory',
      )
    }
  }
}
