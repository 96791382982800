export const getPayableStatusDescription = (value: string): string => {
  return PayableStatusOptions.filter(x => x.value === value?.toString())[0]?.label
}

export const getDestinationExpenseDescription = (value: any): string => {
  return DestinationExpenseOptions.filter(x => x.value === value?.toString())[0]?.label
}

export const getExpenseTypeDescription = (value: any): string => {
  return ExpenseTypeOptions.filter(x => x.value === value?.toString())[0]?.label
}

export interface DestinationExpenseOption {
  value: DestinationExpenseEnum | null
  label: string
}

export enum DestinationExpenseEnum {
  Own = 'Own',
  Client = 'Client',
}

export const DestinationExpenseOptions: Array<DestinationExpenseOption> = [
  { value: null, label: '' },
  { value: DestinationExpenseEnum.Client, label: 'Cliente' },
  { value: DestinationExpenseEnum.Own, label: 'Próprio' },
]

export interface ExpenseTypeOption {
  value: ExpenseTypeEnum | null
  label: string
}

export enum ExpenseTypeEnum {
  None = 'None',
  Airfare = 'Airfare',
  Food = 'Food',
  Fuel = 'Fuel',
  Toll = 'Toll',
  Accommodation = 'Accommodation',
  VehicleRental = 'VehicleRental',
  Parts = 'Parts',
  ThirdPartyLabor = 'ThirdPartyLabor',
  Tools = 'Tools',
  Equipments = 'Equipments',
  Others = 'Others',
  Tax = 'Tax',
  Salary = 'Salary',
}

export const ExpenseTypeOptions: Array<ExpenseTypeOption> = [
  { value: null, label: '' },
  { value: ExpenseTypeEnum.None, label: 'Não Informado' },
  { value: ExpenseTypeEnum.Food, label: 'Alimentação' },
  { value: ExpenseTypeEnum.Fuel, label: 'Combustível' },
  { value: ExpenseTypeEnum.Equipments, label: 'Equipamentos' },
  { value: ExpenseTypeEnum.Tools, label: 'Ferramentas' },
  { value: ExpenseTypeEnum.Accommodation, label: 'Hospedagem' },
  { value: ExpenseTypeEnum.Tax, label: 'Imposto' },
  { value: ExpenseTypeEnum.VehicleRental, label: 'Locação de Veículo' },
  { value: ExpenseTypeEnum.ThirdPartyLabor, label: 'Mão de Obra de Terceiros' },
  { value: ExpenseTypeEnum.Others, label: 'Outros' },
  { value: ExpenseTypeEnum.Airfare, label: 'Passagem Aérea' },
  { value: ExpenseTypeEnum.Parts, label: 'Peças' },
  { value: ExpenseTypeEnum.Toll, label: 'Pedágio' },
  { value: ExpenseTypeEnum.Salary, label: 'Salário' },
]

export interface PayableDocumentTypeOption {
  value: PayableDocumentTypeEnum | null
  label: string
}

export enum PayableDocumentTypeEnum {
  None = 'None',
  Billet = 'Billet',
  Check = 'Check',
  Pix = 'Pix',
  Transfer = 'Transfer',
  Financing = 'Financing',
  Loan = 'Loan',
  Others = 'Others',
}

export const PayableDocumentTypeOptions: Array<PayableDocumentTypeOption> = [
  { value: null, label: '' },
  { value: PayableDocumentTypeEnum.None, label: 'Não Informado' },
  { value: PayableDocumentTypeEnum.Billet, label: 'Boleto' },
  { value: PayableDocumentTypeEnum.Check, label: 'Cheque' },
  { value: PayableDocumentTypeEnum.Loan, label: 'Empréstimo' },
  { value: PayableDocumentTypeEnum.Financing, label: 'Financiamento' },
  { value: PayableDocumentTypeEnum.Others, label: 'Outros' },
  { value: PayableDocumentTypeEnum.Pix, label: 'Pix' },
  { value: PayableDocumentTypeEnum.Transfer, label: 'Transferência' },
]

export interface PayableStatusOption {
  value: PayableStatusEnum | null
  label: string
}

export enum PayableStatusEnum {
  Payable = 'Payable',
  InDelay = 'InDelay',
  Paid = 'Paid',
}

export const PayableStatusOptions: Array<PayableStatusOption> = [
  { value: null, label: '' },
  { value: PayableStatusEnum.Payable, label: 'A Pagar' },
  { value: PayableStatusEnum.InDelay, label: 'Em Atraso' },
  { value: PayableStatusEnum.Paid, label: 'Pago' },
]

export interface StatusOfPaymentMadeOption {
  value: StatusOfPaymentMadeEnum | null
  label: string
}

export enum StatusOfPaymentMadeEnum {
  PerformedInDelay = 'PerformedInDelay',
  PerformedOnDeadline = 'PerformedOnDeadline',
}

export const StatusOfPaymentMadeOptions: Array<StatusOfPaymentMadeOption> = [
  { value: null, label: '' },
  { value: StatusOfPaymentMadeEnum.PerformedInDelay, label: 'Realizado em Atraso' },
  { value: StatusOfPaymentMadeEnum.PerformedOnDeadline, label: 'Realizado no Prazo' },
]
