import React, { useEffect, useState } from 'react'
import { createContext } from 'react'
import { toast } from 'react-toastify'
import Cookies from 'universal-cookie'

import { MenuPermission } from 'components/layout/components/menu/types'
import { PermissionDescriptionEnum } from 'types/system/profile'
import Api from 'services/system/login'

import { AuthContextData, UserData, LoginRequest, Theme } from './types'

const AuthContext = createContext({} as AuthContextData)

export const AuthProvider: React.FC = ({ children }) => {
  const [theme, setTheme] = useState<Theme | null>(null)
  const [user, setUser] = useState<UserData | null>(null)
  const [logged, setLogged] = useState<boolean | null>(null)
  const cookies = new Cookies()

  useEffect(() => {
    let token = cookies.get('token')
    try {
      if (token) {
        let tokenParsed = JSON.parse(atob(token.split('.')[1]))
        setUser({
          name: tokenParsed.n,
          permissions: tokenParsed.p,
        })
        setTheme(tokenParsed.t === 'Dark' ? Theme.Dark : Theme.Light)
        setLogged(true)
      } else {
        setUser(null)
        setTheme(cookies.get('theme') === 'Dark' ? Theme.Dark : Theme.Light)
        setLogged(false)
      }
    } catch (error) {
      setUser(null)
      setLogged(false)
    }
  }, [])

  useEffect(() => {
    if (theme === Theme.Dark) {
      document.getElementsByTagName('html')[0].classList.add('dark-theme')
      document.getElementsByTagName('html')[0].classList.remove('light-theme')
    } else {
      document.getElementsByTagName('html')[0].classList.add('light-theme')
      document.getElementsByTagName('html')[0].classList.remove('dark-theme')
    }
  }, [theme])

  async function login(values: LoginRequest) {
    let token = await Api.login(values)
    if (token) {
      let data = JSON.parse(atob(token.data.split('.')[1]))
      setUser({ name: data.n, permissions: data.p })
      setTheme(data.t === 'Dark' ? Theme.Dark : Theme.Light)
      setLogged(true)
      cookies.remove('token', { path: '/', sameSite: 'strict' })
      cookies.set('token', token.data, { path: '/', expires: new Date(data.exp * 1000), secure: true })
      cookies.set('theme', data.t, { path: '/' })
      toast.success('Login realizado com sucesso')
    }
  }

  function logout() {
    setUser(null)
    setLogged(false)
    cookies.remove('token', { path: '/', sameSite: 'strict' })
  }

  function getMenuPermission(): MenuPermission {
    if (user?.permissions) {
      let permissions: Array<PermissionDescriptionEnum> = JSON.parse(user.permissions.toString())

      return {
        audit: true,
        financial: {
          company: permissions.some(x => x === PermissionDescriptionEnum.Administrator),
          payable: permissions.some(x => x === PermissionDescriptionEnum.Financial),
          receivable: permissions.some(x => x === PermissionDescriptionEnum.Financial),
          report: permissions.some(x => x === PermissionDescriptionEnum.Financial),
          supplier: permissions.some(x => x === PermissionDescriptionEnum.Administrator),
        },
        inventory: {
          equipment: permissions.some(x => x === PermissionDescriptionEnum.Inventory),
          maintenanceSchedule: permissions.some(x => x === PermissionDescriptionEnum.Inventory),
          part: permissions.some(x => x === PermissionDescriptionEnum.Inventory),
          report: permissions.some(x => x === PermissionDescriptionEnum.Inventory),
        },
        sale: {
          client: permissions.some(x => x === PermissionDescriptionEnum.Administrator),
          proposal: permissions.some(x => x === PermissionDescriptionEnum.Administrator),
        },
        system: {
          profile: permissions.some(x => x === PermissionDescriptionEnum.Administrator),
          user: permissions.some(x => x === PermissionDescriptionEnum.Administrator),
        },
        technicalVisit: {
          finishTechnicalVisit: permissions.some(x => x === PermissionDescriptionEnum.TechnicalVisit),
          technicalVisit: permissions.some(x => x === PermissionDescriptionEnum.TechnicalVisit),
          technician: permissions.some(x => x === PermissionDescriptionEnum.TechnicalVisit),
        },
      }
    }
    return {
      audit: false,
      financial: {
        company: false,
        payable: false,
        receivable: false,
        report: false,
        supplier: false,
      },
      inventory: {
        equipment: false,
        maintenanceSchedule: false,
        part: false,
        report: false,
      },
      sale: {
        client: false,
        proposal: false,
      },
      system: {
        profile: false,
        user: false,
      },
      technicalVisit: {
        finishTechnicalVisit: false,
        technicalVisit: false,
        technician: false,
      },
    }
  }

  return <AuthContext.Provider value={{ logged, user, theme, login, logout, setTheme, getMenuPermission }}>{children}</AuthContext.Provider>
}

export function useAuth() {
  const context = React.useContext(AuthContext)
  return context
}
