import { sub, isAfter, startOfToday } from 'date-fns'
import Yup from 'utils/yup'

export const defaultValues = {
  quantity: null,
  interval: null,
  totalValue: null,
  initialDueDate: null,
}

export const validationSchema = Yup.object().shape({
  quantity: Yup.number().nullable().required(),
  interval: Yup.number().nullable().required(),
  totalValue: Yup.number().nullable().required(),
  initialDueDate: Yup.date()
    .nullable()
    .required()
    .test('date-today', 'A data de vencimento inicial deve ser igual ou superior a data atual', function (value: Date | null | undefined) {
      if (value) return isAfter(value, sub(startOfToday(), { days: 1 }))
      return false
    }),
})
