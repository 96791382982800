import api from 'services/technical-visit/finish-technical-visit'

export const handleGetNextVisitEquipment = async (id: string) => {
  let res = await api.getNextVisitEquipment(id)

  return res?.data?.data
}

export const handleSave = async (values: any) => {
  let res = await api.finishTechnicalVisit(values)

  return res?.data?.success
}
