import React, { useEffect, useState } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { Grid, Table, TableBody, TableCell, TableContainer, TableRow, CardContent, Card } from 'react-md'

import { AddButton, DeleteButton } from 'components/data-table'
import { PermissionOptions } from 'types/system/profile'
import { handleSubmit, Select } from 'components/form'
import { CalloutType } from 'components/core/callout'
import { Cell, Callout } from 'components/core'

import { defaultValuesAddPermission, validationSchemaAddPermission } from 'pages/system/profile/edit/config'
import { handleAddPermission, handleDeletePermission, getPermissionDescription } from 'pages/system/profile/edit/actions'

interface ComponentProps {
  id: string
  permissionList: Array<any>
  loadProfile: () => void
}

const Component: React.FC<ComponentProps> = ({ id, permissionList, loadProfile }) => {
  const formPermission = useForm({ defaultValues: defaultValuesAddPermission })
  const [permissions, setPermissions] = useState<Array<any>>([])

  useEffect(() => setPermissions(permissionList), [id, permissionList])

  return (
    <Card fullWidth>
      <CardContent>
        <Callout
          type={CalloutType.Info}
          text='Para incluir as permissões no perfil basta selecionar a permissão desejada abaixo e clique no botão adicionar permissão'
        />
        <FormContext {...formPermission}>
          <Grid>
            <Select name='permission' label='Permissão *' options={PermissionOptions} form={formPermission} desktopSize={10} tabletSize={7} />
            <Cell desktopSize={2} tabletSize={1}>
              <br />
              <AddButton
                id='add-permission'
                tooltip='Adicionar Permissão'
                action={async () =>
                  await handleSubmit(formPermission, validationSchemaAddPermission, async () => {
                    let values = formPermission.getValues()
                    if (values.permission !== '0') {
                      if (permissions.findIndex(x => x.permission.toString() === values.permission.toString()) > -1)
                        toast.warn('Permissão já adicionada')
                      else {
                        if (await handleAddPermission(id, values.permission)) loadProfile()
                      }
                      formPermission.reset()
                    } else {
                      formPermission.setError('permission', 'required', 'Campo obrigatório')
                    }
                  })
                }
              />
            </Cell>
            {permissions && (
              <Cell desktopSize={10} tabletSize={7}>
                <TableContainer>
                  <Table fullWidth>
                    <TableBody>
                      {permissions.map((value: any, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <DeleteButton
                              id='delete-profile-button'
                              action={async () => {
                                if (permissions.length === 1) {
                                  toast.warn('Não é permitido excluir a única permissão do perfil')
                                } else {
                                  if (await handleDeletePermission(id, value.id)) loadProfile()
                                }
                              }}
                            />
                          </TableCell>
                          <TableCell grow>{getPermissionDescription(PermissionOptions, value.permission)}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Cell>
            )}
          </Grid>
        </FormContext>
      </CardContent>
    </Card>
  )
}

export default Component
