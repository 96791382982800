import React, { useEffect, useState } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { Card, CardActions, CardContent, Grid, TableCell, TableRow } from 'react-md'
import { format, parseISO } from 'date-fns'

import { ConfirmDialog, Cell, PageTitle, ButtonSearch } from 'components/core'
import { handleSubmit, Input, InputDatePicker, Select } from 'components/form'
import { DataTable, DeleteButton, EditButton } from 'components/data-table'
import { ClientAutoComplete } from 'components/auto-complete'
import { DataTableResult } from 'components/data-table/types'
import { formatCurrency } from 'types/core/currency'
import { CompanySelect } from 'components/select'
import {
  getReceivableStatusDescription,
  ReceivableDocumentTypeOptions,
  ReceivableStatusOptions,
  StatusOfReceiptMadeOptions,
} from 'types/financial/receivable'

import { handleSearch, handleEdit, handleDelete } from './actions'
import { defaultValues, validationSchema } from './config'

const Component: React.FC = () => {
  const form = useForm({ defaultValues })
  const [filter, setFilter] = useState<any>()
  const [result, setResult] = useState<DataTableResult>({ total: 0, results: [] })
  const [deleteId, setDeleteId] = useState<string>()
  const [toogleUpdate, setToogleUpdate] = useState<boolean>(false)

  useEffect(() => {
    document.title = 'Pesquisa de Contas a Receber'
  }, [])

  return (
    <>
      <Grid>
        <Cell>
          <PageTitle title='Pesquisa de Contas a Receber' />
          <Card fullWidth>
            <CardContent>
              <FormContext {...form}>
                <Grid>
                  <CompanySelect name='companyId' label='Empresa' desktopSize={3} tabletSize={8} />
                  <ClientAutoComplete name='client' label='Cliente' desktopSize={3} tabletSize={8} />
                  <InputDatePicker name='initialDueDate' label='Data de Vencimento Inicial' desktopSize={3} tabletSize={4} />
                  <InputDatePicker name='finalDueDate' label='Data de Vencimento Final' desktopSize={3} tabletSize={4} />
                  <Input name='documentNumber' label='Número do Documento' desktopSize={3} tabletSize={4} />
                  <Select name='documentType' label='Tipo do Documento' options={ReceivableDocumentTypeOptions} desktopSize={3} tabletSize={4} />
                  <InputDatePicker name='initialReceivableDate' label='Data de Recebimento Inicial' desktopSize={3} tabletSize={4} />
                  <InputDatePicker name='finalReceivableDate' label='Data de Recebimento Final' desktopSize={3} tabletSize={4} />
                  <Select name='status' label='Status' options={ReceivableStatusOptions} desktopSize={3} tabletSize={4} />
                  <Select
                    name='statusOfReceiptMade'
                    label='Status do Recebimento Realizado'
                    options={StatusOfReceiptMadeOptions}
                    desktopSize={3}
                    tabletSize={4}
                  />
                </Grid>
              </FormContext>
              <CardActions>
                <ButtonSearch onClick={async () => await handleSubmit(form, validationSchema, () => setFilter(form.getValues()))} />
              </CardActions>
            </CardContent>
          </Card>
        </Cell>
        <Cell>
          <DataTable
            filter={filter}
            header={[
              { order: 'Company.FantasyName', label: 'Company' },
              { order: 'Client.FantasyName', label: 'Cliente' },
              { order: 'DocumentNumber', label: 'Número do Documento' },
              { order: 'Value', label: 'Valor' },
              { label: 'Status' },
              { order: 'DueDate', label: 'Data de Vencimento' },
              { label: '' },
            ]}
            result={result}
            setResult={setResult}
            search={handleSearch}
            update={toogleUpdate}
            total={result ? result.total : 0}>
            {result &&
              result.results &&
              result.results.map((data: any, index) => (
                <TableRow key={index}>
                  <TableCell>{data.companyFantasyName}</TableCell>
                  <TableCell>{data.clientFantasyName}</TableCell>
                  <TableCell>{data.documentNumber}</TableCell>
                  <TableCell>{formatCurrency(data.value)}</TableCell>
                  <TableCell>{getReceivableStatusDescription(data.status)}</TableCell>
                  <TableCell>{format(parseISO(data.dueDate), "dd'/'MM'/'yyyy")}</TableCell>
                  <TableCell align='right'>
                    <EditButton id={`edit-${index}`} action={() => handleEdit(data.id)} />
                    <DeleteButton id={`delete-${index}`} action={() => setDeleteId(data.id)} />
                  </TableCell>
                </TableRow>
              ))}
          </DataTable>
        </Cell>
      </Grid>
      <ConfirmDialog
        text='Deseja realmente excluir o lançamento? Essa ação não poderá ser desfeita'
        visible={deleteId ? true : false}
        confirmAction={async () => {
          if (deleteId) if (await handleDelete(deleteId)) setToogleUpdate(!toogleUpdate)
          setDeleteId(undefined)
        }}
        cancelAction={async () => setDeleteId(undefined)}
      />
    </>
  )
}

export default Component
