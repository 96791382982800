import React, { useState } from 'react'
import { Card, CardActions, CardContent, FontIcon, Table, TableBody, TableCell, TableContainer, TableHeader, TableRow, useToggle } from 'react-md'

import Callout, { CalloutType } from 'components/core/callout'
import { ButtonCustom, ConfirmDialog } from 'components/core'
import { DeleteButton } from 'components/data-table'

import { handleRemoveInspection } from './actions'

import CreateInspection from './components/add'

interface ComponentProps {
  maintenanceScheduleId: string
  maintenanceInspections: Array<any> | null
  loadMaintenanceSchedule: () => Promise<void>
}

const Component: React.FC<ComponentProps> = ({ maintenanceScheduleId, maintenanceInspections, loadMaintenanceSchedule }) => {
  const [visibleAddInspection, enableAddInspection, disableAddInspection] = useToggle(false)
  const [maintenanceInspectionId, setMaintenanceInspectionId] = useState<string | null>()

  return (
    <>
      <Card fullWidth>
        <CardContent>
          {(!maintenanceInspections || maintenanceInspections.length === 0) && (
            <Callout text={'Nenhum item de inspeção localizado'} type={CalloutType.Info} />
          )}
          {maintenanceInspections && maintenanceInspections.length > 0 && (
            <TableContainer>
              <Table fullWidth>
                <TableHeader>
                  <TableRow>
                    <TableCell>Descrição</TableCell>
                    <TableCell>Formato</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {maintenanceInspections?.map((data: any, index: number) => (
                    <TableRow key={index}>
                      <TableCell>{data.description}</TableCell>
                      <TableCell>{data.format}</TableCell>
                      <TableCell align='right'>
                        <DeleteButton id={`delete-${index}`} action={() => setMaintenanceInspectionId(data.id)} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </CardContent>
        <CardActions>
          <ButtonCustom
            text='Novo Item de Inspeção'
            icon={<FontIcon>add_task</FontIcon>}
            whiteThemeBackgroundColor={'#4dd0e1'}
            darkThemeBackgroundColor={'#00838f'}
            onClick={async () => enableAddInspection()}
          />
        </CardActions>
      </Card>

      <CreateInspection
        maintenanceScheduleId={maintenanceScheduleId}
        visibleModal={visibleAddInspection}
        disableModal={disableAddInspection}
        loadMaintenanceSchedule={loadMaintenanceSchedule}
      />

      <ConfirmDialog
        text='Deseja realmente excluir o item de inspeção? Essa ação não poderá ser desfeita'
        visible={maintenanceInspectionId ? true : false}
        confirmAction={async () => {
          if (maintenanceInspectionId)
            if (await handleRemoveInspection(maintenanceScheduleId, maintenanceInspectionId)) await loadMaintenanceSchedule()
          setMaintenanceInspectionId(null)
        }}
        cancelAction={async () => setMaintenanceInspectionId(null)}
      />
    </>
  )
}

export default Component
