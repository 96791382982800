import Yup from 'utils/yup'

export const defaultValues = {
  email: null,
  login: null,
}

export const validationSchema = Yup.object().shape({
  email: Yup.string().nullable().required().email('E-mail inválido').max(40, 'O email deve ter no máximo 40 caracteres'),
  login: Yup.string().nullable().required().min(5, 'O login deve ter no mínimo 5 caracteres').max(20, 'O login deve ter no máximo 20 caracteres'),
})
