import React, { useEffect } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { Card, CardActions, CardContent, FontIcon, Grid } from 'react-md'

import { Cell, PageTitle, ButtonSuccess } from 'components/core'
import { handleSubmit, Input } from 'components/form'
import { useAuth } from 'contexts/auth/auth-context'

import { defaultValues, validationSchema } from './config'
import { handleSave } from './actions'

const Component: React.FC = () => {
  const { logout } = useAuth()
  const form = useForm({ defaultValues })

  useEffect(() => {
    document.title = 'Alteração de Senha'
  }, [])

  return (
    <Grid>
      <Cell>
        <PageTitle title='Alteração de Senha' />
        <Card fullWidth>
          <CardContent>
            <FormContext {...form}>
              <Grid>
                <Input name='currentPassword' label='Senha Atual *' type='password' desktopSize={4} />
                <Input name='newPassword' label='Nova Senha *' type='password' desktopSize={4} />
                <Input name='confirmNewPassword' label='Confirmação de Senha *' type='password' desktopSize={4} />
              </Grid>
            </FormContext>
          </CardContent>
          <CardActions>
            <ButtonSuccess
              text='Salvar'
              icon={<FontIcon>done</FontIcon>}
              disabled={!form.formState.dirty}
              onClick={async () => await handleSubmit(form, validationSchema, async () => await handleSave(form.getValues(), logout))}
            />
          </CardActions>
        </Card>
      </Cell>
    </Grid>
  )
}

export default Component
