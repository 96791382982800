import Yup from 'utils/yup'

export const defaultValues = {
  name: null,
  phone1: null,
  phone2: null,
  phone3: null,
  email1: null,
  email2: null,
}

export const validationSchema = Yup.object().shape({
  name: Yup.string().nullable().required().max(60, 'O nome fantasia deve ter no máximo 60 caracteres'),
  phone1: Yup.string().nullable().required().max(11, 'O telefone deve ter no máximo 11 caracteres'),
  phone2: Yup.string().nullable().max(11, 'O telefone deve ter no máximo 11 caracteres'),
  phone3: Yup.string().nullable().max(11, 'O telefone deve ter no máximo 11 caracteres'),
  email1: Yup.string().nullable().email('E-mail inválido').max(60, 'O e-mail deve ter no máximo 60 caracteres'),
  email2: Yup.string().nullable().email('E-mail inválido').max(60, 'O e-mail deve ter no máximo 60 caracteres'),
})
