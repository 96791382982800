import React from 'react'
import { Text } from 'react-md'

interface Props {
  title: string
}

const PageTitle: React.FC<Props> = ({ title }) => {
  return (
    <Text type='headline-5' margin='none' style={{ marginBottom: 20 }}>
      {title}
    </Text>
  )
}

export default React.memo(PageTitle)
