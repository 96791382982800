import Yup from 'utils/yup'

export const defaultValues = {
  description: null,
  maintenancePeriodInHours: null,
  maintenancePeriodInDays: null,
}

export const validationSchema = Yup.object().shape({
  description: Yup.string().nullable().required().max(60, 'A descrição deve ter no máximo 60 caracteres'),
  maintenancePeriodInHours: Yup.number().nullable().required(),
  maintenancePeriodInDays: Yup.number().nullable().required(),
})
