import React, { useEffect, useState } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { Card, CardActions, CardContent, FontIcon, Grid } from 'react-md'

import { EquipmentStatusOptions } from 'types/inventory/equipment'
import { VoltageOptions } from 'types/core/voltage'
import { Cell, PageTitle, ButtonSearch, ButtonSuccess } from 'components/core'
import { Input, InputDecimal, Select } from 'components/form'

import { typeDefaultValues, partDefaultValues, equipmentDefaultValues } from './config'
import { handleGetReport } from './actions'
import { TypeReportEnum } from './types'

const Component: React.FC = () => {
  const typeForm = useForm({ defaultValues: typeDefaultValues })
  const partForm = useForm({ defaultValues: partDefaultValues })
  const equipmentForm = useForm({ defaultValues: equipmentDefaultValues })
  const typeFormValue = typeForm.watch('type')
  const [type, setType] = useState<string>(TypeReportEnum.Part)

  useEffect(() => {
    document.title = 'Relatório de Estoque'
  }, [])

  useEffect(() => {
    setType(typeFormValue === TypeReportEnum.Part ? TypeReportEnum.Part : TypeReportEnum.Equipment)
  }, [typeFormValue])

  return (
    <Grid>
      <Cell>
        <PageTitle title='Relatório de Estoque' />
        <Card fullWidth>
          <CardContent>
            <Grid>
              <FormContext {...typeForm}>
                <Select
                  name='type'
                  label='Tipo'
                  options={[
                    { value: 'Part', label: 'Peça' },
                    { value: 'Equipment', label: 'Equipamento' },
                  ]}
                  desktopSize={2}
                  tabletSize={4}
                />
              </FormContext>
              {type === TypeReportEnum.Part && (
                <FormContext {...partForm}>
                  <Input name='name' label='Nome' desktopSize={3} tabletSize={4} />
                  <Input name='code' label='Código' desktopSize={2} tabletSize={4} />
                  <Input name='manufacturerName' label='Nome do Fabricante' desktopSize={2} tabletSize={4} />
                  <Input name='equipmentModels' label='Modelos de Equipamento' desktopSize={3} tabletSize={4} />
                </FormContext>
              )}
              {type === TypeReportEnum.Equipment && (
                <FormContext {...equipmentForm}>
                  <Input name='model' label='Modelo' desktopSize={4} tabletSize={4} />
                  <Input name='manufacturerName' label='Fabricante' desktopSize={4} tabletSize={4} />
                  <Select name='voltage' label='Voltagem' options={VoltageOptions} desktopSize={2} tabletSize={4} />
                  <InputDecimal name='inicialFlowRate' label='Presão Inicial' desktopSize={2} tabletSize={4} />
                  <InputDecimal name='finalFlowRate' label='Presão Final' desktopSize={2} tabletSize={4} />
                  <Input name='serialNumber' label='Número de Série' desktopSize={2} tabletSize={4} />
                  <Input name='partNumber' label='Part Number' desktopSize={2} tabletSize={4} />
                  <Select name='status' label='Status' options={EquipmentStatusOptions} desktopSize={2} tabletSize={4} />
                </FormContext>
              )}
            </Grid>
            <CardActions>
              <ButtonSuccess
                text='Exportar'
                icon={<FontIcon>download</FontIcon>}
                onClick={async () => {
                  if (type === TypeReportEnum.Part) await handleGetReport(type, partForm)
                  else if (type === TypeReportEnum.Equipment) await handleGetReport(type, equipmentForm)
                }}
              />
            </CardActions>
          </CardContent>
        </Card>
      </Cell>
    </Grid>
  )
}

export default Component
