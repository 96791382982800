import { LayoutNavigationItem, LayoutNavigationTree } from 'react-md'

import { MenuPermission } from 'components/layout/components/menu/types'

interface getMenuProps {
  permissions: MenuPermission
  menu: LayoutNavigationTree<LayoutNavigationItem>
  getMenu: (id: string, parent: string | null, title: string, icon: string, path: string | null) => LayoutNavigationItem
}

export const getFinancialMenu = ({ permissions, menu, getMenu }: getMenuProps) => {
  if (permissions.financial.payable || permissions.financial.receivable || permissions.financial.report) {
    menu['/financial'] = getMenu('/financial', null, 'Financeiro', 'local_atm', null)

    if (permissions.financial.payable) {
      menu['/financial/payable'] = getMenu('/financial/payable', '/financial', 'Contas a Pagar', 'calculate', null)
      menu['/financial/payable/new'] = getMenu('/financial/payable/new', '/financial/payable', 'Novo', 'add', '/financial/payable/new')
      menu['/financial/payable/search'] = getMenu(
        '/financial/payable/search',
        '/financial/payable',
        'Pesquisa',
        'search',
        '/financial/payable/search',
      )
    }

    if (permissions.financial.receivable) {
      menu['/financial/receivable'] = getMenu('/financial/receivable', '/financial', 'Contas a Receber', 'attach_money', null)
      menu['/financial/receivable/new'] = getMenu('/financial/receivable/new', '/financial/receivable', 'Novo', 'add', '/financial/receivable/new')
      menu['/financial/receivable/search'] = getMenu(
        '/financial/receivable/search',
        '/financial/receivable',
        'Pesquisa',
        'search',
        '/financial/receivable/search',
      )
    }

    if (permissions.financial.report) {
      menu['/financial/report'] = getMenu('/financial/report', '/financial', 'Relatórios', 'receipt_long', null)
      menu['/financial/report/financial'] = getMenu(
        '/financial/report/financial',
        '/financial/report',
        'Financeiro',
        'receipt_long',
        '/financial/report/financial',
      )
      menu['/financial/report/expense-by-client'] = getMenu(
        '/financial/report/expense-by-client',
        '/financial/report',
        'Gasto por Cliente',
        'receipt_long',
        '/financial/report/expense-by-client',
      )
    }
  }
}
