import React, { useEffect, useState } from 'react'
import { Text, Grid, Card, CardContent, CardActions, FontIcon, Table, TableBody, TableRow, TableCell, FileInput } from 'react-md'
import { FormContextValues } from 'react-hook-form'

import { Callout, Cell } from 'components/core'
import { CalloutType } from 'components/core/callout'
import { DeleteButton } from 'components/data-table'
import { makeId } from 'types/sale/proposal'

interface ComponentProps {
  form: FormContextValues<any>
  toogleClear: boolean
}

interface File {
  id: string
  proposalFileName: string
  fileBase64: string
}

const Component: React.FC<ComponentProps> = ({ form, toogleClear }) => {
  const [files, setFiles] = useState<Array<File>>([])

  useEffect(() => form.register({ name: 'files' }), [form.register])

  useEffect(() => form.setValue('files', files), [files])

  useEffect(() => setFiles([]), [toogleClear])

  const getBase64 = (e: any) => {
    let id = makeId()
    let file = e.target.files[0]
    let fileName = e.target.files[0].name
    let reader = new FileReader()
    reader.readAsBinaryString(file)
    reader.onload = () => {
      if (reader && reader.result) {
        setFiles([
          ...files,
          {
            id: id,
            proposalFileName: fileName,
            fileBase64: btoa(reader.result.toString()),
          },
        ])
      }
    }
  }

  const removeFile = (id: string) => {
    if (files) setFiles(files.filter(x => x.id != id))
  }

  return (
    <Grid>
      <Cell>
        <Text type='headline-6' margin='none'>
          Arquivos
        </Text>
      </Cell>
      <Cell>
        <Card fullWidth>
          <CardContent>
            {(!files || files.length === 0) && <Callout text={'Nenhum arquivo selecionado'} type={CalloutType.Info} />}
            {files && (
              <Table fullWidth>
                <TableBody>
                  {files.map((data: File, index) => (
                    <TableRow key={index}>
                      <TableCell>{data.proposalFileName}</TableCell>
                      <TableCell>
                        <DeleteButton id={`delete-${index}`} action={() => removeFile(data.id)} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </CardContent>
          <CardActions>
            <FileInput
              id='import-input'
              theme={'secondary'}
              themeType={'contained'}
              buttonType={'text'}
              accept={'.pdf,.doc,.docx,.xls,.xlsx'}
              icon={<FontIcon>description</FontIcon>}
              onChange={event => {
                if (event?.target?.files?.[0]) getBase64(event)
              }}>
              {'Selecionar Arquivo'}
            </FileInput>
          </CardActions>
        </Card>
      </Cell>
    </Grid>
  )
}

export default Component
