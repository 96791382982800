import React, { useState, useEffect } from 'react'
import { TextField, FormMessage } from 'react-md'
import { usePromiseTracker } from 'react-promise-tracker'
import { useFormContext } from 'react-hook-form'

import Cell from 'components/core/cell'
import { FieldProps } from 'components/form/types'

export const ZipCode = React.memo(({ name, desktopSize, tabletSize, disabled, ...rest }: FieldProps) => {
  const { promiseInProgress } = usePromiseTracker()
  const form = useFormContext()
  const [text, setText] = useState<string>('')
  const [textFormated, setTextFormated] = useState<string>('')
  const ZIPCODE_MAXLENGTH = 8

  useEffect(() => {
    form.register({ name })
  }, [form.register])

  useEffect(() => {
    let formValue = String(form.getValues()[name] ?? '')
    if (formValue) {
      let value = ''
      if (formValue.length < ZIPCODE_MAXLENGTH) {
        if (ZIPCODE_MAXLENGTH - formValue.length > 0) value = `${'0'.repeat(ZIPCODE_MAXLENGTH - formValue.length)}${formValue}`
      } else {
        value = formValue
      }

      setTextFormated(
        formatCepMask(value)
          .replace(/\D/g, '')
          .replace(/^(\d{5})(\d{3})+?$/, '$1-$2'),
      )
    } else {
      setTextFormated('')
    }
  }, [form.getValues()[name]])

  useEffect(() => {
    setTextFormated(
      formatCepMask(text)
        .replace(/\D/g, '')
        .replace(/^(\d{5})(\d{3})+?$/, '$1-$2'),
    )
  }, [text])

  useEffect(() => {
    if (!text || text?.length === 0) form.setValue(name, null)
    if (text?.length === ZIPCODE_MAXLENGTH) form.setValue(name, parseInt(text.replace(/\D/g, '')))
  }, [text])

  const formatCepMask = (value: string): string => `0000000${value ?? ''}`.substring(7, `0000000${value ?? ''}`.length)

  return (
    <Cell tabletSize={tabletSize} desktopSize={desktopSize}>
      <TextField
        id={name}
        name={name}
        theme={'underline'}
        error={form.errors[name] ? true : false}
        disabled={disabled || promiseInProgress}
        value={textFormated}
        onKeyDown={event => {
          if (event.key === 'Backspace' || event.key === 'Delete') {
            form.setValue(name, null)
            setText('')
          }
        }}
        onChange={selected => {
          if (form.errors[name]) form.clearError(name)
          setText(selected.target.value)
        }}
        {...rest}
      />
      {form.errors[name] && (
        <FormMessage id={name} error style={{ paddingLeft: 0 }}>
          {form.errors[name].message}
        </FormMessage>
      )}
    </Cell>
  )
})
