import { toast } from 'react-toastify'

import { handleSubmit } from 'components/form'
import api from 'services/inventory/report'
import { equipmentValidationSchema, partValidationSchema } from './config'
import { TypeReportEnum } from './types'

export const handleGetReport = async (type: TypeReportEnum, form: any) => {
  if (type === TypeReportEnum.Part) await handleSubmit(form, partValidationSchema, async () => await getReportPart(form.getValues()))
  else if (type === TypeReportEnum.Equipment)
    await handleSubmit(form, equipmentValidationSchema, async () => await getReportEquipment(form.getValues()))
}

const getReportPart = async (values: any) => {
  let res = await api.part(values)

  if (res?.status === 204) toast.info('Nenhuma informação localizada')
  else download('Peças', res?.data)
}

const getReportEquipment = async (values: any) => {
  let res = await api.equipment(values)

  if (res?.status === 204) toast.info('Nenhuma informação localizada')
  else download('Equipamentos', res?.data)
}

const download = (typeDescription: string, blob: any) => {
  const url = window.URL.createObjectURL(new Blob([blob]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', `Relatório de Estoque - ${typeDescription}.csv`)
  document.body.appendChild(link)
  link.click()
  link.parentNode?.removeChild(link)
}
