import { trackPromise } from 'react-promise-tracker'

import api from 'utils/api'
import { ApiResponse } from 'utils/types'
import { DataTableRequest } from 'components/data-table/types'

const url = '/v1/audit/search'
const Api = {
  search: (request: DataTableRequest): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(url, request)),
}

export default Api
