import React, { useState, useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { TextField, FormMessage } from 'react-md'
import { usePromiseTracker } from 'react-promise-tracker'

import Cell from 'components/core/cell'

import { FieldProps } from './types'

export const InputTime = ({ name, desktopSize, tabletSize, disabled, ...rest }: FieldProps) => {
  const [text, setText] = useState<string>('')
  const { promiseInProgress } = usePromiseTracker()
  const form = useFormContext()
  const formValue = form.watch(name)

  useEffect(() => {
    form.register({ name })
  }, [form.register])

  useEffect(() => {
    if (formValue?.length > 5) setText(formValue ? sanitizeValue(String(formValue)) : '')
    else setText(formValue ? String(formValue) : '')
  }, [formValue])

  const sanitizeValue = (value: string) => value?.substring(0, 5)

  return (
    <Cell tabletSize={tabletSize} desktopSize={desktopSize}>
      <TextField
        id={name}
        name={name}
        type={'time'}
        theme={'underline'}
        error={form.errors[name] ? true : false}
        disabled={disabled || promiseInProgress}
        value={text}
        onChange={selected => {
          if (form.errors[name]) form.clearError(name)
          if (selected.target.value) form.setValue(name, selected.target.value)
          else form.setValue(name, null)
        }}
        {...rest}
      />
      {form.errors[name] && (
        <FormMessage id={name} error style={{ paddingLeft: 0 }}>
          {form.errors[name].message}
        </FormMessage>
      )}
    </Cell>
  )
}
