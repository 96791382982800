import { trackPromise } from 'react-promise-tracker'

import api from 'utils/api'
import { ApiResponse } from 'utils/types'

const url = '/forgot-password'
const Api = {
  send: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(url, request)),
  updatePassword: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.put(url, request)),
}

export default Api
