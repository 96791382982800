import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Grid, TabPanel, TabPanels, Tabs, TabsManager } from 'react-md'

import { Cell, PageTitle } from 'components/core'

import GeneralInformation from './components/general-information'
import Equipments from './components/equipment'
import Parts from './components/part'
import Files from './components/file'
import { handleGet } from './actions'

const Component: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const [technicalVisit, setTechnicalVisit] = useState<any>({})
  const [loadEquipments, setLoadEquipments] = useState<boolean>(false)
  const [loadParts, setLoadParts] = useState<boolean>(false)
  const [loadFiles, setLoadFiles] = useState<boolean>(false)
  const [activeTab, setActiveTab] = useState<number>(0)

  useEffect(() => {
    document.title = 'Edição de Visita Técnica'
  }, [])

  useEffect(() => {
    load()
  }, [id])

  const load = async () => {
    const data = await handleGet(id)
    if (data) setTechnicalVisit(data)
  }

  useEffect(() => {
    if (activeTab === 1) setLoadEquipments(true)
    else if (activeTab === 2) setLoadParts(true)
    else if (activeTab === 3) setLoadFiles(true)
  }, [activeTab])

  return (
    <Grid>
      <Cell>
        <PageTitle title='Edição de Visita Técnica' />
      </Cell>
      <Cell>
        <TabsManager
          onActiveIndexChange={val => setActiveTab(val)}
          tabs={['Informações Gerais', 'Equipamentos', 'Peças', 'Arquivos']}
          tabsId='technical-visit-tabs'>
          <Tabs />
          <TabPanels persistent={true}>
            <TabPanel>
              <GeneralInformation id={id} technicalVisit={technicalVisit} />
            </TabPanel>
            <TabPanel>{loadEquipments && <Equipments id={id} clientId={technicalVisit.clientId} />}</TabPanel>
            <TabPanel>{loadParts && <Parts id={id} />}</TabPanel>
            <TabPanel>{loadFiles && <Files id={id} />}</TabPanel>
          </TabPanels>
        </TabsManager>
      </Cell>
    </Grid>
  )
}

export default Component
