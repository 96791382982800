export interface PermissionOption {
  value: PermissionEnum
  label: string
}

export enum PermissionEnum {
  None = '0',
  Administrator = '1',
  Inventory = '2',
  Financial = '3',
  TechnicalVisit = '4',
}

export enum PermissionDescriptionEnum {
  Administrator = 'Administrator',
  Inventory = 'Inventory',
  Financial = 'Financial',
  TechnicalVisit = 'TechnicalVisit',
}

export const PermissionOptions: Array<PermissionOption> = [
  { value: PermissionEnum.None, label: 'Selecione a permissão' },
  { value: PermissionEnum.Administrator, label: 'Administrador' },
  { value: PermissionEnum.Inventory, label: 'Estoque' },
  { value: PermissionEnum.Financial, label: 'Financeiro' },
  { value: PermissionEnum.TechnicalVisit, label: 'Visita Técnica' },
]
