import { ReactElement, ReactNode, useEffect, useRef, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import {
  Layout as RMDLayout,
  Configuration,
  useLayoutNavigation,
  LayoutNavigationItem,
  LayoutNavigationTree,
  ENTER,
  useCrossFade,
  FontIcon,
} from 'react-md'

import { useAuth } from 'contexts/auth/auth-context'

import Header from './components/menu/header-menu'
import { getBody } from './components/menu/body-menu'
import Footer from './components/menu/footer-menu'
import ActionBar from './action-bar'

interface LayoutProps {
  children: ReactNode
}

export default function Layout({ children }: LayoutProps): ReactElement {
  const { user, getMenuPermission } = useAuth()
  const { pathname } = useLocation()
  const [menu, setMenu] = useState<LayoutNavigationTree<LayoutNavigationItem>>(Header)
  const [_rendered, transitionProps, dispatch] = useCrossFade()

  useEffect(() => {
    if (user) {
      setMenu({
        ...Header,
        ...getBody({ menuPermissions: getMenuPermission(), getMenu }),
        ...Footer,
      })
    }
  }, [user])

  const getMenu = (id: string, parent: string | null, title: string, icon: string, path: string | null): LayoutNavigationItem => {
    return {
      itemId: id,
      parentId: parent,
      children: title,
      leftAddon: <FontIcon>{icon}</FontIcon>,
      to: path ?? undefined,
    }
  }

  const prevPathname = useRef(pathname)
  if (pathname !== prevPathname.current) {
    prevPathname.current = pathname
    dispatch(ENTER)
  }

  return (
    <Configuration>
      <RMDLayout
        phoneLayout='temporary'
        tabletLayout='temporary'
        landscapeTabletLayout='temporary'
        desktopLayout='clipped'
        largeDesktopLayout='clipped'
        treeProps={useLayoutNavigation(menu, pathname, Link)}
        mainProps={transitionProps}
        appBar={<ActionBar userName={user?.name} />}>
        {children}
      </RMDLayout>
    </Configuration>
  )
}
