import React, { useEffect, useState } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { Card, CardActions, CardContent, Grid, TableCell, TableRow } from 'react-md'
import { format, parseISO } from 'date-fns'

import { ChanceClosingOptions, getChanceClosingDescription, getProposalStatusDescription, ProposalStatusOptions } from 'types/sale/proposal'
import { ConfirmDialog, Cell, PageTitle, ButtonSearch } from 'components/core'
import { DataTable, DeleteButton, EditButton } from 'components/data-table'
import { handleSubmit, InputDatePicker, Select } from 'components/form'
import { ClientAutoComplete } from 'components/auto-complete'
import { DataTableResult } from 'components/data-table/types'
import { formatCurrency } from 'types/core/currency'

import { handleSearch, handleEdit, handleDelete } from './actions'
import { defaultValues, validationSchema } from './config'

const Component: React.FC = () => {
  const form = useForm({ defaultValues })
  const [filter, setFilter] = useState<any>()
  const [result, setResult] = useState<DataTableResult>({ total: 0, results: [] })
  const [deleteId, setDeleteId] = useState<string>()
  const [toogleUpdate, setToogleUpdate] = useState<boolean>(false)

  useEffect(() => {
    document.title = 'Pesquisa de Proposta'
  }, [])

  return (
    <>
      <Grid>
        <Cell>
          <PageTitle title='Pesquisa de Proposta' />
          <Card fullWidth>
            <CardContent>
              <FormContext {...form}>
                <Grid>
                  <ClientAutoComplete name='client' label='Cliente' desktopSize={4} tabletSize={4} />
                  <Select name='chanceClosing' label='Chance de Fechamento' options={ChanceClosingOptions} desktopSize={2} tabletSize={4} />
                  <Select name='proposalStatus' label='Status' options={ProposalStatusOptions} desktopSize={2} tabletSize={4} />
                  <InputDatePicker name='initialFollowUpDate' label='Data Inicial de Follow-Up' desktopSize={2} tabletSize={4} />
                  <InputDatePicker name='finalFollowUpDate' label='Data Final de Follow-Up' desktopSize={2} tabletSize={4} />
                </Grid>
              </FormContext>
              <CardActions>
                <ButtonSearch onClick={async () => await handleSubmit(form, validationSchema, () => setFilter(form.getValues()))} />
              </CardActions>
            </CardContent>
          </Card>
        </Cell>
        <Cell>
          <DataTable
            filter={filter}
            header={[
              { order: 'Client.FantasyName', label: 'Cliente' },
              { label: 'Valor Total' },
              { label: 'Chance de Fechamento' },
              { label: 'Status' },
              { label: 'Data do Último Contato' },
              { label: 'Data de Follow-up' },
              { label: '' },
            ]}
            result={result}
            setResult={setResult}
            search={handleSearch}
            update={toogleUpdate}
            total={result ? result.total : 0}>
            {result &&
              result.results &&
              result.results.map((data: any, index) => (
                <TableRow key={index}>
                  <TableCell>{data.clientFantasyName}</TableCell>
                  <TableCell>{formatCurrency(data.totalValue)}</TableCell>
                  <TableCell>{getChanceClosingDescription(data.chanceClosing)}</TableCell>
                  <TableCell>{getProposalStatusDescription(data.proposalStatus)}</TableCell>
                  <TableCell>{data.lastContactDate ? format(parseISO(data.lastContactDate), "dd'/'MM'/'yyyy' 'HH:mm:ss") : null}</TableCell>
                  <TableCell>{data.followUpDate ? format(parseISO(data.followUpDate), "dd'/'MM'/'yyyy") : null}</TableCell>
                  <TableCell align='right'>
                    <EditButton id={`edit-${index}`} action={() => handleEdit(data.id)} />
                    <DeleteButton id={`delete-${index}`} action={() => setDeleteId(data.id)} />
                  </TableCell>
                </TableRow>
              ))}
          </DataTable>
        </Cell>
      </Grid>

      <ConfirmDialog
        text='Deseja realmente excluir a proposta? Essa ação não poderá ser desfeita'
        visible={deleteId ? true : false}
        confirmAction={async () => {
          if (deleteId) if (await handleDelete(deleteId)) setToogleUpdate(!toogleUpdate)
          setDeleteId(undefined)
        }}
        cancelAction={async () => setDeleteId(undefined)}
      />
    </>
  )
}

export default Component
