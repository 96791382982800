import React, { useEffect, useState } from 'react'
import { Button, FontIcon, Table, TableBody, TableCell, TableContainer, TableRow, Tooltipped } from 'react-md'

import { CalloutType } from 'components/core/callout'
import { useAuth } from 'contexts/auth/auth-context'
import { Callout, Modal } from 'components/core'
import { Theme } from 'contexts/auth/types'

import { handleGetMaintenanceActionsDones } from './actions'

interface ComponentProps {
  technicalVisitId: string
  equipmentId: string | undefined
  visibleModal: boolean
  disableModal: () => void
}

const Component: React.FC<ComponentProps> = ({ technicalVisitId, equipmentId, visibleModal, disableModal }) => {
  const [actions, setActions] = useState<Array<any>>()
  const { theme } = useAuth()

  useEffect(() => {
    if (equipmentId && visibleModal) loadMaintenanceInspections()
  }, [technicalVisitId, visibleModal, equipmentId])

  const loadMaintenanceInspections = async () => {
    setActions(await handleGetMaintenanceActionsDones({ technicalVisitId, equipmentId }))
  }

  return (
    <Modal title={'Ações de Manutenção'} visible={visibleModal} closeTitle={'Fechar'} closeAction={async () => disableModal()}>
      {(!actions || actions.length == 0) && <Callout text={'Nenhuma ação de manutenção realizada'} type={CalloutType.Info} />}
      {actions && actions.length > 0 && (
        <TableContainer>
          <Table fullWidth>
            <TableBody>
              {actions?.map((actions, index) => (
                <TableRow key={index}>
                  <TableCell grow>{actions.description}</TableCell>
                  {actions.done && (
                    <TableCell>
                      <Tooltipped id={`action-done-${index}`} tooltip={'Ação Realizada'} position={'above'}>
                        <Button
                          id={`button-done-${index}`}
                          buttonType='icon'
                          themeType='contained'
                          style={
                            theme === Theme.Dark
                              ? { backgroundColor: '#00695C', color: '#FFFFFF', margin: 2 }
                              : { backgroundColor: '#4DB6AC', color: '#212121', margin: 2 }
                          }>
                          <FontIcon>thumb_up</FontIcon>
                        </Button>
                      </Tooltipped>
                    </TableCell>
                  )}
                  {!actions.done && (
                    <TableCell>
                      <Tooltipped id={`action-done-${index}`} tooltip={'Ação não Realizada'} position={'above'}>
                        <Button
                          id={`button-not-done-${index}`}
                          buttonType='icon'
                          themeType='contained'
                          style={
                            theme === Theme.Dark
                              ? { backgroundColor: '#d32f2f', color: '#FFFFFF', margin: 2 }
                              : { backgroundColor: '#e57373', color: '#212121', margin: 2 }
                          }>
                          <FontIcon>thumb_down</FontIcon>
                        </Button>
                      </Tooltipped>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Modal>
  )
}

export default Component
