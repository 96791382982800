export const getReceivableStatusDescription = (status: string): string => {
  return ReceivableStatusOptions.filter(x => x.value === status?.toString())[0]?.label
}

export interface ReceivableDocumentTypeOption {
  value: ReceivableDocumentTypeEnum | null
  label: string
}

export enum ReceivableDocumentTypeEnum {
  None = 'None',
  Billet = 'Billet',
  Check = 'Check',
  Pix = 'Pix',
  Transfer = 'Transfer',
  Others = 'Others',
}

export const ReceivableDocumentTypeOptions: Array<ReceivableDocumentTypeOption> = [
  { value: null, label: '' },
  { value: ReceivableDocumentTypeEnum.None, label: 'Não Informado' },
  { value: ReceivableDocumentTypeEnum.Billet, label: 'Boleto' },
  { value: ReceivableDocumentTypeEnum.Check, label: 'Cheque' },
  { value: ReceivableDocumentTypeEnum.Others, label: 'Outros' },
  { value: ReceivableDocumentTypeEnum.Pix, label: 'Pix' },
  { value: ReceivableDocumentTypeEnum.Transfer, label: 'Transferência' },
]

export interface ReceivableStatusOption {
  value: ReceivableStatusEnum | null
  label: string
}

export enum ReceivableStatusEnum {
  Receivable = 'Receivable',
  InDelay = 'InDelay',
  Received = 'Received',
}

export const ReceivableStatusOptions: Array<ReceivableStatusOption> = [
  { value: null, label: '' },
  { value: ReceivableStatusEnum.Receivable, label: 'A Receber' },
  { value: ReceivableStatusEnum.InDelay, label: 'Em Atraso' },
  { value: ReceivableStatusEnum.Received, label: 'Recebido' },
]

export interface StatusOfReceiptMadeOption {
  value: StatusOfReceiptMadeEnum | null
  label: string
}

export enum StatusOfReceiptMadeEnum {
  PerformedInDelay = 'PerformedInDelay',
  PerformedOnDeadline = 'PerformedOnDeadline',
}

export const StatusOfReceiptMadeOptions: Array<StatusOfReceiptMadeOption> = [
  { value: null, label: '' },
  { value: StatusOfReceiptMadeEnum.PerformedInDelay, label: 'Realizado em Atraso' },
  { value: StatusOfReceiptMadeEnum.PerformedOnDeadline, label: 'Realizado no Prazo' },
]
