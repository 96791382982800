import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { FormContext, useForm } from 'react-hook-form'
import { Card, CardActions, CardContent, FontIcon, Grid, TabPanel, TabPanels, Tabs, TabsManager, useToggle } from 'react-md'

import { handleSubmit, Input, InputDecimal, InputNumber, Select, SelectBoolean } from 'components/form'
import { ButtonLog, Cell, PageTitle, ButtonSuccess, ButtonEdit } from 'components/core'
import { getEquipmentStatusDescription } from 'types/inventory/equipment'
import { VoltageOptions } from 'types/core/voltage'
import { TextLabel } from 'components/label'

import { defaultValues, validationSchema } from './config'
import { handleGet, handleSave } from './actions'

import NewMaintenanceSchedule from './components/update-maintenance-schedule'
import MaintenanceActions from './components/maintenance-actions'
import TechnicalStart from './components/technical-start'
import TechnicalVisitDone from './components/technical-visit-done'

const Component: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const form = useForm({ defaultValues })
  const [loadTechnicalStarts, setLoadTechnicalStarts] = useState<boolean>(false)
  const [loadMaintenanceActions, setLoadMaintenanceActions] = useState<boolean>(false)
  const [loadTechnicalVisits, setLoadTechnicalVisits] = useState<boolean>(false)
  const [activeTab, setActiveTab] = useState<number>(0)
  const [equipment, setEquipment] = useState<any>({})
  const [visibleNewMaintenanceScheduleModal, enableNewMaintenanceScheduleModal, disableNewMaintenanceScheduleModal] = useToggle(false)

  useEffect(() => {
    document.title = 'Edição de Equipamento'
  }, [])

  useEffect(() => {
    loadEquipment()
  }, [id])

  useEffect(() => {
    if (activeTab === 1) setLoadTechnicalStarts(true)
    else if (activeTab === 2) setLoadMaintenanceActions(true)
    else if (activeTab === 3) setLoadTechnicalVisits(true)
  }, [activeTab])

  const loadEquipment = async () => {
    let res = await handleGet(id)
    if (res) {
      form.reset(res)
      setEquipment(res)
    }
  }

  return (
    <>
      <Grid>
        <Cell>
          <PageTitle title='Edição de Equipamento' />
          <TabsManager
            onActiveIndexChange={val => setActiveTab(val)}
            tabs={['Informações Gerais', 'Partida Técnica', 'Agenda de Manutenção', 'Visitas Técnicas Realizadas']}
            tabsId='profile-tabs'>
            <Tabs />
            <TabPanels persistent={true}>
              <TabPanel>
                <Card fullWidth>
                  <CardContent>
                    <FormContext {...form}>
                      <Grid>
                        <TextLabel
                          name='maintenanceSchedule'
                          label='Agenda de Manutenção'
                          value={equipment.maintenanceScheduleDescription}
                          desktopSize={3}
                          tabletSize={4}
                        />
                        <Input name='model' label='Modelo *' desktopSize={3} tabletSize={4} />
                        <Input name='manufacturerName' label='Fabricante *' desktopSize={3} tabletSize={4} />
                        <SelectBoolean name='maintenanceContract' label='Contrato de Manutenção *' desktopSize={3} tabletSize={4} />
                        <Input name='serialNumber' label='Número de Série *' desktopSize={3} tabletSize={4} />
                        <InputNumber name='yearManufacture' label='Ano de Fabricação *' maxLength={4} desktopSize={3} tabletSize={4} />
                        <Select name='voltage' label='Voltagem' options={VoltageOptions} desktopSize={2} tabletSize={4} />
                        <InputDecimal name='flowRate' label='Vazão M³/m' desktopSize={2} tabletSize={4} />
                        <InputNumber name='dewPoint' label='Ponto de Orvalho' desktopSize={2} tabletSize={4} />
                        <InputNumber name='airReservoirVolume' label='Volume do Reservatório de Ar' desktopSize={3} tabletSize={4} />
                        <InputNumber name='oxygenReservoirVolume' label='Volume do Reservatório de Oxigênio' desktopSize={3} tabletSize={4} />
                        <Input name='partNumber' label='Part Number' desktopSize={2} tabletSize={4} />
                        <InputDecimal name='pressure' label='Presão' desktopSize={2} tabletSize={4} />
                        <Input name='degreePurity' label='Grau de Pureza' desktopSize={2} tabletSize={4} />
                        <Input name='address' label='Endereço' desktopSize={3} tabletSize={4} />
                        <TextLabel
                          name='equipmentStatus'
                          label='Status do Equipamento'
                          value={getEquipmentStatusDescription(equipment.equipmentStatus)}
                          desktopSize={3}
                          tabletSize={4}
                        />
                      </Grid>
                    </FormContext>
                  </CardContent>
                  <CardActions>
                    <ButtonSuccess
                      text='Salvar'
                      icon={<FontIcon>done</FontIcon>}
                      disabled={!form.formState.dirty}
                      onClick={async () =>
                        await handleSubmit(form, validationSchema, async () => {
                          if (await handleSave({ ...form.getValues(), id: id })) form.reset(form.getValues(), { dirty: false })
                        })
                      }
                    />
                    <ButtonEdit
                      text='Atualizar Agenda de Manutenção'
                      icon={<FontIcon>pending_actions</FontIcon>}
                      onClick={async () => enableNewMaintenanceScheduleModal()}
                    />
                    <ButtonLog entity='Equipment' id={id} />
                  </CardActions>
                </Card>
              </TabPanel>
              <TabPanel>{loadTechnicalStarts && <TechnicalStart id={id} />}</TabPanel>
              <TabPanel>{loadMaintenanceActions && <MaintenanceActions id={id} />}</TabPanel>
              <TabPanel>{loadTechnicalVisits && <TechnicalVisitDone id={id} />}</TabPanel>
            </TabPanels>
          </TabsManager>
        </Cell>
      </Grid>

      <NewMaintenanceSchedule
        id={id}
        onSuccess={async () => await loadEquipment()}
        visibleModal={visibleNewMaintenanceScheduleModal}
        disableModal={disableNewMaintenanceScheduleModal}
      />
    </>
  )
}

export default Component
