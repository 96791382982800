import api from 'services/sale/client'
import { AutocompleteResult } from 'utils/types'

export const clientAutocomplete = async (text: string): Promise<Array<AutocompleteResult>> => {
  if (!text) return []

  let res = await api.autocomplete({ text })

  if (!res?.data?.success) return []

  return res?.data?.data
}
