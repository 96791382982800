import { LayoutNavigationTree } from 'react-md'

import LogoMenu from 'components/core/logo-menu'

const HeaderMenu: LayoutNavigationTree = {
  logo: {
    itemId: 'logo',
    parentId: null,
    isCustom: true,
    contentComponent: () => <LogoMenu />,
  },
}

export default HeaderMenu
