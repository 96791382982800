import api from 'services/inventory/equipment'
import { AutocompleteResult } from 'utils/types'

export const equipmentAutocomplete = async (values: any): Promise<Array<AutocompleteResult>> => {
  let { clientId, text } = values
  if (!text) return []

  let res = await api.autocomplete({ clientId, text })

  if (!res?.data?.success) return []

  return res?.data?.data
}
