import React from 'react'
import { Grid } from '@react-md/utils'
import { Text } from 'react-md'
import { FormContext, FormContextValues } from 'react-hook-form'

import { BusinessModelOptions, RegionOptions, SituationOptions } from 'types/sale/client'
import { TechnicianAutoComplete } from 'components/auto-complete'
import { Input, InputDocument, Select } from 'components/form'
import { Cell } from 'components/core'

interface ComponentProps {
  form: FormContextValues<any>
}

const Component: React.FC<ComponentProps> = ({ form }) => {
  return (
    <Grid>
      <Cell>
        <Text type='headline-6' margin='none'>
          Informações Gerais
        </Text>
      </Cell>
      <Cell>
        <Grid>
          <FormContext {...form}>
            <Input name='fantasyName' label='Nome Fantasia *' desktopSize={4} tabletSize={4} />
            <Input name='companyName' label='Razão Social *' desktopSize={4} tabletSize={4} />
            <InputDocument name='document' label='Cnpj *' maxLength={18} desktopSize={2} tabletSize={4} />
            <Input name='ie' label='IE' desktopSize={2} tabletSize={4} />
            <TechnicianAutoComplete name='responsibleTechnician' label='Técnico Responsável' desktopSize={4} tabletSize={8} />
            <Select name='region' label='Região *' options={RegionOptions} desktopSize={2} tabletSize={4} />
            <Select name='businessModel' label='Modelo de Negócio *' options={BusinessModelOptions} desktopSize={2} tabletSize={4} />
            <Select name='situation' label='Situação *' options={SituationOptions} desktopSize={2} tabletSize={4} />
          </FormContext>
        </Grid>
      </Cell>
    </Grid>
  )
}

export default Component
