import React, { useEffect, useState } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import {
  Card,
  CardActions,
  CardContent,
  FontIcon,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader,
  TableRow,
  Text,
  useToggle,
} from 'react-md'

import { ConfirmDialog, Callout, Modal, ButtonSuccess } from 'components/core'
import { handleSubmit, Input, InputPhone } from 'components/form'
import { DeleteButton, EditButton } from 'components/data-table'
import { CalloutType } from 'components/core/callout'

import { handleRemoveContact, handleGetContacts, handleAddContact, handleUpdateContact } from './actions'
import { defaultValues, validationSchema } from './config'

interface ComponentProps {
  id: string
}

const Component: React.FC<ComponentProps> = ({ id }) => {
  const [contacts, setContacts] = useState<Array<any> | null>(null)
  const [contactId, setContactId] = useState<string | null>()
  const [visibleModal, enableModal, disableModal] = useToggle(false)
  const form = useForm({ defaultValues })

  useEffect(() => {
    loadContacts()
  }, [id])

  const loadContacts = async () => setContacts(await handleGetContacts(id))

  const loadContact = (contact: any) => {
    form.reset(contact, { dirty: false })
    setContactId(contact.id)
    enableModal()
  }

  return (
    <>
      <Card fullWidth>
        <CardContent>
          {(!contacts || contacts.length == 0) && <Callout text={'Nenhum contato localizado'} type={CalloutType.Info} />}
          {contacts && contacts.length > 0 && (
            <TableContainer>
              <Table fullWidth>
                <TableHeader>
                  <TableRow>
                    <TableCell>Nome</TableCell>
                    <TableCell>Telefone 1</TableCell>
                    <TableCell>Telefone 2</TableCell>
                    <TableCell>Telefone 3</TableCell>
                    <TableCell>E-mail 1</TableCell>
                    <TableCell>E-mail 2</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {contacts.map((data: any, index) => (
                    <TableRow key={index}>
                      <TableCell>{data.name}</TableCell>
                      <TableCell>{data.phone1}</TableCell>
                      <TableCell>{data.phone2}</TableCell>
                      <TableCell>{data.phone3}</TableCell>
                      <TableCell>{data.email1}</TableCell>
                      <TableCell>{data.email2}</TableCell>
                      <TableCell align='right'>
                        <EditButton id={`edit-${index}`} action={() => loadContact(data)} />
                        <DeleteButton id={`delete-${index}`} action={() => setContactId(data.id)} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </CardContent>
        <CardActions>
          <ButtonSuccess text='Novo Contato' icon={<FontIcon>add_call</FontIcon>} onClick={async () => enableModal()} />
        </CardActions>
      </Card>

      <Modal
        title={contactId ? 'Edição de Contato' : 'Novo Contato'}
        visible={visibleModal}
        confirmTitle={'Salvar'}
        confirmDisabled={!form.formState.dirty}
        confirmAction={async () => {
          await handleSubmit(form, validationSchema, async () => {
            if (contactId) await handleUpdateContact({ clientId: id, id: contactId, ...form.getValues() })
            else await handleAddContact({ clientId: id, ...form.getValues() })

            form.reset(defaultValues, { dirty: false })
            await loadContacts()
            disableModal()
            setContactId(null)
          })
        }}
        closeTitle={'Fechar'}
        closeAction={async () => {
          form.reset(defaultValues, { dirty: false })
          setContactId(null)
          disableModal()
        }}>
        <Grid>
          <FormContext {...form}>
            <Input name='name' label='Nome *' />
            <InputPhone name='phone1' label='Telefone 1 *' desktopSize={4} tabletSize={4} />
            <InputPhone name='phone2' label='Telefone 2' desktopSize={4} tabletSize={4} />
            <InputPhone name='phone3' label='Telefone 3' desktopSize={4} tabletSize={4} />
            <Input name='email1' label='E-mail 1' maxLength={60} desktopSize={6} tabletSize={4} />
            <Input name='email2' label='E-mail 2' maxLength={60} desktopSize={6} tabletSize={4} />
          </FormContext>
        </Grid>
      </Modal>

      <ConfirmDialog
        text='Deseja realmente excluir o contato? Essa ação não poderá ser desfeita'
        visible={contactId && !visibleModal ? true : false}
        confirmAction={async () => {
          if (contactId) {
            if (await handleRemoveContact(id, contactId)) {
              await loadContacts()
              setContactId(null)
            }
          }
        }}
        cancelAction={async () => setContactId(null)}
      />
    </>
  )
}

export default Component
