import React, { useState } from 'react'
import { Select, Text } from 'react-md'

import { getLastRow, getStartRow, onChangeRowsPerPage } from 'components/data-table/actions'
import { DataTableRequest } from 'components/data-table/types'

import NavigateBefore from './navigate-before'
import NavigateNext from './navigate-next'

interface Props {
  request: DataTableRequest | undefined
  setRequest: (value: React.SetStateAction<DataTableRequest | undefined>) => void
  total: number
}

const DataTablePagination: React.FC<Props> = ({ request, setRequest, total }) => {
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)

  return (
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
      <Text type='subtitle-2' style={{ marginRight: 10, marginTop: 15 }}>
        Linhas por página
      </Text>
      <Select
        id='select-pagination'
        theme={'outline'}
        options={[10, 20, 30, 40, 50]}
        value={rowsPerPage.toString()}
        onChange={value => onChangeRowsPerPage(request, parseInt(value), setRequest, setRowsPerPage)}
        disableMovementChange={true}
        dense={true}
        inline={false}
        style={{ width: 100, margin: 5 }}
      />
      <Text type='subtitle-2' style={{ marginRight: 10, marginTop: 15 }}>
        {getStartRow(request, rowsPerPage)}-{getLastRow(request, total, rowsPerPage)} de {total}
      </Text>
      <NavigateBefore request={request} setRequest={setRequest} rowsPerPage={rowsPerPage} />
      <NavigateNext request={request} setRequest={setRequest} rowsPerPage={rowsPerPage} total={total} />
    </div>
  )
}

export default DataTablePagination
