import maintenanceScheduleApi from 'services/inventory/maintenance-schedule'
import technicalVisitApi from 'services/technical-visit/technical-visit'

export const handleListActions = async (equipmentId: string): Promise<Array<any>> => {
  let res = await maintenanceScheduleApi.listActionsByEquipmentId(equipmentId)

  return res?.data ?? []
}

export const handleSave = async (values: any) => {
  let res = await technicalVisitApi.addMaintenanceAction(values)

  return res?.data?.success
}
