import { trackPromise } from 'react-promise-tracker'

import api from 'utils/api'

const url = '/token'
const Api = {
  login: (request: any) => trackPromise(api.post(url, request)),
}

export default Api
