import React, { useEffect } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { Card, CardActions, CardContent, FontIcon, Grid } from 'react-md'

import { handleSubmit, Input, Select, InputNumber, InputDecimal, SelectBoolean } from 'components/form'
import { MaintenanceScheduleAutocomplete } from 'components/auto-complete'
import { Cell, PageTitle, ButtonSuccess } from 'components/core'
import { VoltageOptions } from 'types/core/voltage'

import { defaultValues, validationSchema } from './config'
import { handleSave } from './actions'

const Component: React.FC = () => {
  const form = useForm({ defaultValues })

  useEffect(() => {
    document.title = 'Novo Equipamento'
  }, [])

  return (
    <Grid>
      <Cell>
        <PageTitle title='Novo Equipamento' />
        <Card fullWidth>
          <CardContent>
            <FormContext {...form}>
              <Grid>
                <MaintenanceScheduleAutocomplete name='maintenanceSchedule' label='Agenda de Manutenção' desktopSize={3} tabletSize={4} />
                <Input name='model' label='Modelo *' desktopSize={3} tabletSize={4} />
                <Input name='manufacturerName' label='Fabricante *' desktopSize={3} tabletSize={4} />
                <SelectBoolean name='maintenanceContract' label='Contrato de Manutenção *' desktopSize={3} tabletSize={4} />
                <Input name='serialNumber' label='Número de Série *' desktopSize={3} tabletSize={4} />
                <InputNumber name='yearManufacture' label='Ano de Fabricação *' maxLength={4} desktopSize={3} tabletSize={4} />
                <Select name='voltage' label='Voltagem' options={VoltageOptions} desktopSize={2} tabletSize={4} />
                <InputDecimal name='flowRate' label='Vazão M³/m' desktopSize={2} tabletSize={4} />
                <InputNumber name='dewPoint' label='Ponto de Orvalho' desktopSize={2} tabletSize={4} />
                <InputNumber name='airReservoirVolume' label='Volume do Reservatório de Ar' desktopSize={3} tabletSize={4} />
                <InputNumber name='oxygenReservoirVolume' label='Volume do Reservatório de Oxigênio' desktopSize={3} tabletSize={4} />
                <Input name='partNumber' label='Part Number' desktopSize={2} tabletSize={4} />
                <InputDecimal name='pressure' label='Presão' desktopSize={2} tabletSize={4} />
                <Input name='degreePurity' label='Grau de Pureza' desktopSize={3} tabletSize={4} />
                <Input name='address' label='Endereço' desktopSize={3} tabletSize={4} />
              </Grid>
            </FormContext>
          </CardContent>
          <CardActions>
            <ButtonSuccess
              text='Salvar'
              icon={<FontIcon>done</FontIcon>}
              disabled={!form.formState.dirty}
              onClick={async () =>
                await handleSubmit(form, validationSchema, async () => {
                  if (await handleSave(form.getValues())) form.reset(defaultValues, { dirty: false })
                })
              }
            />
          </CardActions>
        </Card>
      </Cell>
    </Grid>
  )
}

export default Component
