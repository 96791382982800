import React, { useState } from 'react'
import { Card, CardActions, CardContent, FontIcon, Table, TableBody, TableCell, TableContainer, TableHeader, TableRow, useToggle } from 'react-md'

import { CustomButton, EditButton } from 'components/data-table'
import { ButtonCustom, Callout } from 'components/core'
import { CalloutType } from 'components/core/callout'

import CreateAction from './components/add'
import EditAction from './components/edit'

import AddHoursAction from './components/add-hours'
import SubtractHoursAction from './components/subtract-hours'

interface ComponentProps {
  maintenanceScheduleId: string
  maintenanceActions: Array<any> | null
  loadMaintenanceSchedule: () => Promise<void>
}

const Component: React.FC<ComponentProps> = ({ maintenanceScheduleId, maintenanceActions, loadMaintenanceSchedule }) => {
  const [visibleAddAction, enableAddAction, disableAddAction] = useToggle(false)
  const [visibleUpdateAction, enableUpdateAction, disableUpdateAction] = useToggle(false)
  const [visibleAddHoursAction, enableAddHoursAction, disableAddHoursAction] = useToggle(false)
  const [visibleSubtractHoursAction, enableSubtractHoursAction, disableSubtractHoursAction] = useToggle(false)
  const [maintenanceActionId, setMaintenanceActionId] = useState<string | null>()

  return (
    <>
      <Card fullWidth>
        <CardContent>
          {(!maintenanceActions || maintenanceActions.length === 0) && (
            <Callout text={'Nenhuma ação de manutenção localizada'} type={CalloutType.Info} />
          )}
          {maintenanceActions && maintenanceActions.length > 0 && (
            <TableContainer>
              <Table fullWidth>
                <TableHeader>
                  <TableRow>
                    <TableCell>Descrição</TableCell>
                    <TableCell>Período de Manutenção em Horas</TableCell>
                    <TableCell>Período de Manutenção em Dias</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {maintenanceActions?.map((data: any, index: number) => (
                    <TableRow key={index}>
                      <TableCell>{data.description}</TableCell>
                      <TableCell>{data.maintenancePeriodInHours}</TableCell>
                      <TableCell>{data.maintenancePeriodInDays}</TableCell>
                      <TableCell align='right'>
                        <CustomButton
                          id={`add-hours-${index}`}
                          icon={'more_time'}
                          tooltip={'Adicionar Horas de Manutenção'}
                          whiteThemeBackgroundColor={'#81c784'}
                          darkThemeBackgroundColor={'#2e7d32'}
                          action={async () => {
                            setMaintenanceActionId(data.id)
                            enableAddHoursAction()
                          }}
                        />
                        <CustomButton
                          id={`delete-hours-${index}`}
                          icon={'history'}
                          tooltip={'Subtrair Horas de Manutenção'}
                          whiteThemeBackgroundColor={'#ff8a65'}
                          darkThemeBackgroundColor={'#d84315'}
                          action={async () => {
                            setMaintenanceActionId(data.id)
                            enableSubtractHoursAction()
                          }}
                        />
                        <EditButton
                          id={`edit-${index}`}
                          action={() => {
                            setMaintenanceActionId(data.id)
                            enableUpdateAction()
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </CardContent>
        <CardActions>
          <ButtonCustom
            text='Nova Ação de Manutenção'
            icon={<FontIcon>more_time</FontIcon>}
            whiteThemeBackgroundColor={'#7986cb'}
            darkThemeBackgroundColor={'#303f9f'}
            onClick={async () => enableAddAction()}
          />
        </CardActions>
      </Card>

      <CreateAction
        maintenanceScheduleId={maintenanceScheduleId}
        visibleModal={visibleAddAction}
        disableModal={disableAddAction}
        loadMaintenanceSchedule={loadMaintenanceSchedule}
      />

      <EditAction
        maintenanceScheduleId={maintenanceScheduleId}
        actionId={maintenanceActionId}
        maintenanceAction={maintenanceActions?.find(x => x.id === maintenanceActionId)}
        visibleModal={visibleUpdateAction}
        disableModal={disableUpdateAction}
        loadMaintenanceSchedule={loadMaintenanceSchedule}
        onHide={() => setMaintenanceActionId(null)}
      />

      <AddHoursAction
        maintenanceScheduleId={maintenanceScheduleId}
        actionId={maintenanceActionId}
        visibleModal={visibleAddHoursAction}
        disableModal={disableAddHoursAction}
        loadMaintenanceSchedule={loadMaintenanceSchedule}
        onHide={() => setMaintenanceActionId(null)}
      />

      <SubtractHoursAction
        maintenanceScheduleId={maintenanceScheduleId}
        actionId={maintenanceActionId}
        visibleModal={visibleSubtractHoursAction}
        disableModal={disableSubtractHoursAction}
        loadMaintenanceSchedule={loadMaintenanceSchedule}
        onHide={() => setMaintenanceActionId(null)}
      />
    </>
  )
}

export default Component
