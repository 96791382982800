import React from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { Grid } from 'react-md'

import { handleSubmit, Input, InputNumber } from 'components/form'
import { Modal } from 'components/core'

import { defaultValues, validationSchema } from './config'
import { handleSave } from './actions'

interface ComponentProps {
  maintenanceScheduleId: string
  visibleModal: boolean
  disableModal: () => void
  loadMaintenanceSchedule: () => Promise<void>
}

const Component: React.FC<ComponentProps> = ({ maintenanceScheduleId, visibleModal, disableModal, loadMaintenanceSchedule }) => {
  const form = useForm({ defaultValues })

  return (
    <Modal
      title={'Criação de Ação de Manutenção'}
      visible={visibleModal}
      confirmTitle={'Salvar'}
      confirmDisabled={!form.formState.dirty}
      confirmAction={async () => {
        await handleSubmit(form, validationSchema, async () => {
          await handleSave({ maintenanceScheduleId, ...form.getValues() })
          form.reset(defaultValues, { dirty: false })
          await loadMaintenanceSchedule()
          disableModal()
        })
      }}
      closeTitle={'Fechar'}
      closeAction={async () => {
        form.reset(defaultValues, { dirty: false })
        disableModal()
      }}>
      <Grid>
        <FormContext {...form}>
          <Input name='description' label='Descrição *' desktopSize={12} tabletSize={8} />
          <InputNumber name='maintenancePeriodInHours' label='Período de Manutenção em Horas *' desktopSize={6} tabletSize={4} />
          <InputNumber name='maintenancePeriodInDays' label='Período de Manutenção em Dias *' desktopSize={6} tabletSize={4} />
        </FormContext>
      </Grid>
    </Modal>
  )
}

export default Component
