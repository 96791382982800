import React, { useEffect, useState } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { Card, CardActions, CardContent, FontIcon, FormMessage, Grid, Text } from 'react-md'

import { DestinationExpenseOptions, ExpenseTypeOptions, PayableDocumentTypeOptions } from 'types/financial/payable'
import { ClientAutoComplete, SupplierAutoComplete } from 'components/auto-complete'
import { Cell, PageTitle, ButtonSuccess } from 'components/core'
import { handleSubmit, Input, Select } from 'components/form'
import { CompanySelect } from 'components/select'

import { defaultValues, validationSchema } from './config'
import Installment from './components/installment'
import { handleSave } from './actions'

const Component: React.FC = () => {
  const form = useForm({ defaultValues })
  const [toogleClear, setToogleClear] = useState(false)

  useEffect(() => {
    document.title = 'Novo Lançamento - Contas a Pagar'
  }, [])

  return (
    <Grid>
      <Cell>
        <PageTitle title='Novo Lançamento - Contas a Pagar' />
        <Card fullWidth>
          <CardContent>
            <Grid>
              <Cell>
                <Text type='headline-6' margin='none'>
                  Informações Principais
                </Text>
              </Cell>
              <Cell>
                <Grid>
                  <FormContext {...form}>
                    <CompanySelect name='companyId' label='Empresa *' desktopSize={4} tabletSize={8} />
                    <SupplierAutoComplete name='supplier' label='Fornecedor *' desktopSize={4} tabletSize={8} />
                    <ClientAutoComplete name='client' label='Cliente' desktopSize={4} tabletSize={8} />
                    <Select
                      name='destinationExpense'
                      label='Destino da Despesa *'
                      options={DestinationExpenseOptions}
                      desktopSize={3}
                      tabletSize={4}
                    />
                    <Select name='expenseType' label='Tipo de Gasto *' options={ExpenseTypeOptions} desktopSize={3} tabletSize={4} />
                    <Select name='documentType' label='Tipo do Documento *' options={PayableDocumentTypeOptions} desktopSize={3} tabletSize={4} />
                    <Input name='documentNumber' label='Número do Documento' desktopSize={3} tabletSize={4} />
                    <Input name='observation' label='Observações' desktopSize={12} tabletSize={8} />
                  </FormContext>
                </Grid>
              </Cell>
              <Cell>
                <Text type='headline-6' margin='none'>
                  Parcelas
                </Text>
              </Cell>
              <Cell>
                <Installment form={form} toogleClear={toogleClear} />
                {form.errors.installments && (
                  <Cell>
                    <FormMessage id={'message-error-permissions'} error style={{ paddingLeft: 0 }}>
                      Nenhuma parcela informada
                    </FormMessage>
                  </Cell>
                )}
              </Cell>
            </Grid>
          </CardContent>
          <CardActions>
            <ButtonSuccess
              text='Salvar'
              icon={<FontIcon>done</FontIcon>}
              disabled={!form.formState.dirty}
              onClick={async () =>
                await handleSubmit(form, validationSchema, async () => {
                  if (await handleSave(form.getValues())) {
                    form.reset(defaultValues, { dirty: false })
                    setToogleClear(!toogleClear)
                  }
                })
              }
            />
          </CardActions>
        </Card>
      </Cell>
    </Grid>
  )
}

export default Component
