import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Switch as SwitchTemplate } from 'react-md'
import { usePromiseTracker } from 'react-promise-tracker'

import Cell from 'components/core/cell'

import { FieldProps } from './types'

interface SwitchProps extends FieldProps {
  labelTrue: string
  labelFalse: string
  defaultChecked: boolean
  colorTrue?: string
  colorFalse?: string
}

export const Switch = ({ name, labelTrue, labelFalse, defaultChecked, disabled, desktopSize, tabletSize, ...rest }: SwitchProps) => {
  const { promiseInProgress } = usePromiseTracker()
  const form = useFormContext()
  const value = form.watch(name)

  return (
    <Cell tabletSize={tabletSize} desktopSize={desktopSize}>
      <Controller
        as={SwitchTemplate}
        id={name}
        name={name}
        control={form.control}
        error={form.errors[name] ? true : false}
        label={value ? labelTrue : labelFalse}
        disabled={disabled || promiseInProgress}
        onChange={([selected]) => {
          if (form.errors[name]) form.clearError(name)
          return selected
        }}
        {...rest}
      />
    </Cell>
  )
}

export const SwitchGroup = ({ form, name, labelTrue, labelFalse, defaultChecked, disabled, ...rest }: SwitchProps) => {
  const { promiseInProgress } = usePromiseTracker()
  const value = form.watch(name)

  return (
    <Controller
      as={SwitchTemplate}
      id={name}
      name={name}
      control={form.control}
      error={form.errors[name] ? true : false}
      label={value ? labelTrue : labelFalse}
      disabled={disabled || promiseInProgress}
      onChange={([selected]) => {
        if (form.errors[name]) form.clearError(name)
        return selected
      }}
      {...rest}
    />
  )
}
