import Yup from 'utils/yup'

export const defaultValues = {
  description: null,
  equipmentType: null,
}

export const validationSchema = Yup.object().shape({
  description: Yup.string().nullable().max(60, 'O modelo deve ter no máximo 60 caracteres'),
  equipmentType: Yup.string().nullable(),
})
