import React, { useEffect, useState } from 'react'

import { Modal } from 'components/core'

import { handleSave, handleListActions } from './actions'
import { FormContext, useForm } from 'react-hook-form'
import { defaultValues, validationSchema } from './config'
import { handleSubmit, Select } from 'components/form'
import { Grid } from 'react-md'

interface ComponentProps {
  technicalVisitId: string
  technicalVisitEquipmentId: string | undefined
  equipmentId: string | undefined
  visibleModal: boolean
  disableModal: () => void
  loadMaintenanceActions: () => Promise<void>
}

const Component: React.FC<ComponentProps> = ({
  technicalVisitId,
  technicalVisitEquipmentId,
  equipmentId,
  visibleModal,
  disableModal,
  loadMaintenanceActions,
}) => {
  const form = useForm({ defaultValues })
  const [actions, setActions] = useState<Array<any>>([])

  useEffect(() => {
    const load = async () => {
      if (actions.length == 0 && equipmentId && visibleModal) {
        setActions([...[{ value: null, label: '' }], ...(await handleListActions(equipmentId))])
      }
    }

    load()

    return () => {}
  }, [equipmentId, visibleModal, actions])

  return (
    <Modal
      title={'Inclusão de Ação de Manutenção'}
      visible={visibleModal}
      confirmTitle={'Salvar'}
      confirmDisabled={!form.formState.dirty}
      confirmAction={async () => {
        await handleSubmit(form, validationSchema, async () => {
          await handleSave({
            id: technicalVisitId,
            equipmentId: technicalVisitEquipmentId,
            ...form.getValues(),
          })
          await loadMaintenanceActions()
          form.reset(defaultValues, { dirty: false })
          disableModal()
        })
      }}
      closeTitle={'Fechar'}
      closeAction={async () => {
        form.reset(defaultValues, { dirty: false })
        disableModal()
      }}>
      <Grid>
        <FormContext {...form}>
          <Select name='maintenanceActionId' label='Ação de Manutenção *' options={actions} desktopSize={12} tabletSize={8} />
        </FormContext>
      </Grid>
    </Modal>
  )
}

export default Component
