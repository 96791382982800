import React, { useEffect, useState } from 'react'
import { TextField, FormMessage } from 'react-md'
import { usePromiseTracker } from 'react-promise-tracker'
import { useFormContext } from 'react-hook-form'

import Cell from 'components/core/cell'

import { FieldProps } from './types'

interface InputNumberProps extends FieldProps {
  negativeNumbers?: boolean
}

export const InputNumber = ({ name, desktopSize, tabletSize, disabled, negativeNumbers, ...rest }: InputNumberProps) => {
  const { promiseInProgress } = usePromiseTracker()
  const form = useFormContext()
  const [text, setText] = useState<string>('')
  const formValue = form.watch(name)

  useEffect(() => {
    form.register({ name })
  }, [form.register])

  useEffect(() => {
    setText(formValue ? String(formValue) : '')
  }, [formValue])

  return (
    <Cell tabletSize={tabletSize} desktopSize={desktopSize}>
      <TextField
        id={name}
        name={name}
        theme={'underline'}
        error={form.errors[name] ? true : false}
        disabled={disabled || promiseInProgress}
        value={text}
        onChange={selected => {
          if (form.errors[name]) form.clearError(name)
          if (selected.target.value) {
            if (negativeNumbers) {
              if (selected.target.value.startsWith('-')) form.setValue(name, -parseInt(selected.target.value.replace(/\D/g, '')))
              else form.setValue(name, parseInt(selected.target.value.replace(/\D/g, '')))
            } else {
              form.setValue(name, parseInt(selected.target.value.replace(/\D/g, '')))
            }
          } else form.setValue(name, null)
        }}
        {...rest}
      />
      {form.errors[name] && (
        <FormMessage id={name} error style={{ paddingLeft: 0 }}>
          {form.errors[name].message}
        </FormMessage>
      )}
    </Cell>
  )
}
