import React, { useEffect, useState } from 'react'
import { TextField, FormMessage } from 'react-md'
import { usePromiseTracker } from 'react-promise-tracker'

import Cell from 'components/core/cell'

import { FieldProps } from './types'
import { useFormContext } from 'react-hook-form'

export const InputDocument = ({ name, desktopSize, tabletSize, disabled, ...rest }: FieldProps) => {
  const { promiseInProgress } = usePromiseTracker()
  const form = useFormContext()
  const [text, setText] = useState<string>('')
  const formValue = form.watch(name)

  useEffect(() => {
    form.register({ name })
  }, [form.register])

  useEffect(() => {
    setText(formValue ? formatDocument(String(formValue)) : '')
  }, [formValue])

  const formatDocument = (value: string): string => {
    if (value.length > 11) {
      return value.replace(/\D/g, '').replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})+?$/, '$1.$2.$3/$4-$5')
    } else if (value.length <= 11) {
      return value.replace(/\D/g, '').replace(/^(\d{3})(\d{3})(\d{3})(\d{2})+?$/, '$1.$2.$3-$4')
    }

    return ''
  }

  return (
    <Cell tabletSize={tabletSize} desktopSize={desktopSize}>
      <TextField
        id={name}
        name={name}
        theme={'underline'}
        error={form.errors[name] ? true : false}
        disabled={disabled || promiseInProgress}
        value={text ?? ''}
        onChange={selected => {
          if (form.errors[name]) form.clearError(name)
          if (selected.target.value) form.setValue(name, selected.target.value.replace(/\D/g, ''))
          else form.setValue(name, null)
        }}
        {...rest}
      />
      {form.errors[name] && (
        <FormMessage id={name} error style={{ paddingLeft: 0 }}>
          {form.errors[name].message}
        </FormMessage>
      )}
    </Cell>
  )
}
