import React, { useEffect, useState } from 'react'
import { TableCell, TableRow, useToggle } from 'react-md'
import { format, parseISO } from 'date-fns'

import { CustomButton, DataTable, EditButton } from 'components/data-table'
import { PermissionDescriptionEnum } from 'types/system/profile'
import { DataTableResult } from 'components/data-table/types'
import { useAuth } from 'contexts/auth/auth-context'

import { handleEdit, handleGet, handleGetTechnicalVisitDones } from './actions'

import MaintenanceActionsDone from './components/maintenance-actions-done'
import TechnicalVisitequipmentFinish from './components/technical-visit-equipment-finish'

interface ComponentProps {
  id: string
}

const Component: React.FC<ComponentProps> = ({ id }) => {
  const auth = useAuth()
  const [technicalVisitId, setTechnicalVisitId] = useState<any>()
  const [technicalVisitEquipment, setTechnicalVisitEquipment] = useState<any>({})
  const [filter, setFilter] = useState<any>()
  const [result, setResult] = useState<DataTableResult>({ total: 0, results: [] })
  const [visibleActions, enableActions, disableActions] = useToggle(false)
  const [visibleFinish, enableFinish, disableFinish] = useToggle(false)

  useEffect(() => {
    setFilter({ equipmentId: id })
  }, [id])

  return (
    <>
      <DataTable
        filter={filter}
        header={[{ label: 'Cliente' }, { label: 'Técnico Responsável' }, { label: 'Número da Visita' }, { label: 'Data da Visita' }, { label: '' }]}
        result={result}
        setResult={setResult}
        search={handleGetTechnicalVisitDones}
        total={result ? result.total : 0}
        showEmptyMessageAlert={false}
        emptyMessage={'Nenhuma visita técnica realizada'}
        wrapEmptyMessageWithCard={true}>
        {result &&
          result.results &&
          result.results.map((data: any, index) => (
            <TableRow key={index}>
              <TableCell>{data.clientName}</TableCell>
              <TableCell>{data.technicianName}</TableCell>
              <TableCell>{data.visitNumber}</TableCell>
              <TableCell>{format(parseISO(data.visitDate), "dd'/'MM'/'yyyy")}</TableCell>
              <TableCell align='right'>
                <CustomButton
                  id={`list-finish-${index}`}
                  icon={'task_alt'}
                  tooltip={'Finalização do Equipamento'}
                  whiteThemeBackgroundColor={'#ff8a65'}
                  darkThemeBackgroundColor={'#d84315'}
                  action={async () => {
                    await handleGet(id).then(res => {
                      setTechnicalVisitEquipment(res)
                      enableFinish()
                    })
                  }}
                />
                <CustomButton
                  id={`list-action-${index}`}
                  icon={'more_time'}
                  tooltip={'Ações de Manutenção'}
                  whiteThemeBackgroundColor={'#7986cb'}
                  darkThemeBackgroundColor={'#303f9f'}
                  action={async () => {
                    setTechnicalVisitId(data.technicalVisitId)
                    enableActions()
                  }}
                />
                {auth.user?.permissions.includes(PermissionDescriptionEnum.TechnicalVisit) && (
                  <EditButton id={`edit-${index}`} tooltip={'Editar Visita Técnica'} action={() => handleEdit(data.technicalVisitId)} />
                )}
              </TableCell>
            </TableRow>
          ))}
      </DataTable>

      <MaintenanceActionsDone equipmentId={id} technicalVisitId={technicalVisitId} visibleModal={visibleActions} disableModal={disableActions} />

      <TechnicalVisitequipmentFinish technicalVisitEquipment={technicalVisitEquipment} visibleModal={visibleFinish} disableModal={disableFinish} />
    </>
  )
}

export default Component
