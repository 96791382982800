import React, { useEffect, useState } from 'react'
import {
  Card,
  CardActions,
  CardContent,
  FontIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader,
  TableRow,
  Text,
  useToggle,
} from 'react-md'

import { getEquipmentStatusDescription } from 'types/inventory/equipment'
import { ConfirmDialog, Callout, ButtonSuccess } from 'components/core'
import { CustomButton, DeleteButton } from 'components/data-table'
import { CalloutType } from 'components/core/callout'

import { handleRemoveEquipment, handleGetEquipments } from './actions'

import TechnicalStart from './components/technical-start'
import AddEquipment from './components/add-equipment'

interface ComponentProps {
  id: string
}

const Component: React.FC<ComponentProps> = ({ id }) => {
  const [visibleAddModal, enableAddModal, disableAddModal] = useToggle(false)
  const [equipments, setEquipments] = useState<Array<any> | null>(null)
  const [technicalStartEquipmentId, setTechnicalStartEquipmentId] = useState<string | null>(null)
  const [removeEquipment, setRemoveEquipment] = useState<any>(null)

  useEffect(() => {
    loadEquipments()
  }, [id])

  const loadEquipments = async () => setEquipments(await handleGetEquipments(id))

  return (
    <>
      <Card fullWidth>
        <CardContent>
          {(!equipments || equipments.length == 0) && <Callout text={'Nenhum equipamento localizado'} type={CalloutType.Info} />}
          {equipments && equipments.length > 0 && (
            <TableContainer>
              <Table fullWidth>
                <TableHeader>
                  <TableRow>
                    <TableCell>Modelo</TableCell>
                    <TableCell>Contrato de Manutenção</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {equipments.map((data: any, index) => (
                    <TableRow key={index}>
                      <TableCell>{data.model}</TableCell>
                      <TableCell>{data.maintenanceContract ? 'Sim' : 'Não'}</TableCell>
                      <TableCell>{getEquipmentStatusDescription(data.equipmentStatus)}</TableCell>
                      <TableCell align='right'>
                        <CustomButton
                          id={`open-${index}`}
                          tooltip={'Editar Equipamento'}
                          action={async () => {
                            window.open(`/inventory/equipment/edit/${data.equipmentId}`)
                          }}
                          icon={'precision_manufacturing'}
                          whiteThemeBackgroundColor={'#64b5f6'}
                          darkThemeBackgroundColor={'#1565c0'}
                        />
                        <CustomButton
                          id={`edit-${index}`}
                          tooltip={'Partida Técnica'}
                          action={async () => setTechnicalStartEquipmentId(data.equipmentId)}
                          icon={'flash_on'}
                          whiteThemeBackgroundColor={'#ffd54f'}
                          darkThemeBackgroundColor={'#ff8f00'}
                        />
                        <DeleteButton id={`delete-${index}`} action={() => setRemoveEquipment({ equipmentId: data.equipmentId, id: data.id })} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </CardContent>
        <CardActions>
          <ButtonSuccess text='Novo Equipamento' icon={<FontIcon>add</FontIcon>} onClick={async () => enableAddModal()} />
        </CardActions>
      </Card>

      <TechnicalStart
        clientId={id}
        id={technicalStartEquipmentId}
        onSuccess={async () => {
          await loadEquipments()
          setTechnicalStartEquipmentId(null)
        }}
        onClose={() => setTechnicalStartEquipmentId(null)}
      />

      <AddEquipment clientId={id} visibleModal={visibleAddModal} disableModal={disableAddModal} onSuccess={async () => await loadEquipments()} />

      <ConfirmDialog
        text='Deseja realmente excluir o equipamento? Essa ação não poderá ser desfeita'
        visible={removeEquipment ? true : false}
        confirmAction={async () => {
          if (removeEquipment) {
            if (await handleRemoveEquipment(id, removeEquipment.equipmentId, removeEquipment.id)) {
              await loadEquipments()
              setRemoveEquipment(null)
            }
          }
        }}
        cancelAction={async () => setRemoveEquipment(null)}
      />
    </>
  )
}

export default Component
