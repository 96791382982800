import React, { useEffect } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { Card, CardActions, CardContent, FontIcon, Grid } from 'react-md'

import { handleSubmit, Input, InputDatePicker, InputDecimal, Select } from 'components/form'
import { ClientAutoComplete, SupplierAutoComplete } from 'components/auto-complete'
import { Cell, PageTitle, ButtonSuccess } from 'components/core'

import { StatusOfPaymentOrReceveidMadeOptions, StatusOptions } from './types'
import { defaultValues, validationSchema } from './config'
import { getReport } from './actions'
import { CompanySelect } from 'components/select'

const Component: React.FC = () => {
  const form = useForm({ defaultValues })

  useEffect(() => {
    document.title = 'Relatório Financeiro'
  }, [])

  return (
    <Grid>
      <Cell>
        <PageTitle title='Relatório Financeiro' />
        <Card fullWidth>
          <CardContent>
            <Grid>
              <FormContext {...form}>
                <CompanySelect name='companyId' label='Empresa' desktopSize={4} tabletSize={8} />
                <ClientAutoComplete name='client' label='Cliente' desktopSize={4} tabletSize={4} />
                <SupplierAutoComplete name='supplier' label='Fornecedor' desktopSize={4} tabletSize={4} />
                <Select name='status' label='Status' options={StatusOptions} desktopSize={3} tabletSize={4} />
                <Select
                  name='statusOfPaymentOrReceveidMade'
                  label='Status de Recebimento/Pagamento'
                  options={StatusOfPaymentOrReceveidMadeOptions}
                  desktopSize={3}
                  tabletSize={4}
                />
                <Input name='documentNumber' label='Número do Documento' desktopSize={2} tabletSize={4} />
                <InputDecimal name='initialValue' label='Valor Inicial' desktopSize={2} tabletSize={4} />
                <InputDecimal name='finalValue' label='Valor Final' desktopSize={2} tabletSize={4} />
                <InputDatePicker name='initialDueDate' label='Data de Vencimento Inicial *' desktopSize={3} tabletSize={4} />
                <InputDatePicker name='finalDueDate' label='Data de Vencimento Final *' desktopSize={3} tabletSize={4} />
                <InputDatePicker name='initialPaymentOrReceveidDate' label='Data de Recebimento/Pagamento Inicial' desktopSize={3} tabletSize={4} />
                <InputDatePicker name='finalPaymentOrReceveidDate' label='Data de Recebimento/Pagamento Final' desktopSize={3} tabletSize={4} />
              </FormContext>
            </Grid>
            <CardActions>
              <ButtonSuccess
                text='Exportar'
                icon={<FontIcon>download</FontIcon>}
                onClick={async () => await handleSubmit(form, validationSchema, () => getReport(form.getValues()))}
              />
            </CardActions>
          </CardContent>
        </Card>
      </Cell>
    </Grid>
  )
}

export default Component
