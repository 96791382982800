import React, { useState } from 'react'
import { Grid, useToggle } from '@react-md/utils'
import { Button, FontIcon, Table, TableBody, TableCell, TableContainer, TableHeader, TableRow, Text, Tooltipped } from 'react-md'

import { useAuth } from 'contexts/auth/auth-context'
import { EditButton } from 'components/data-table'
import { Theme } from 'contexts/auth/types'
import { Cell } from 'components/core'

import { getServicePerformedDescription, getVisitTypeDescription } from 'types/technical-visit/technical-visit'

import FinishEquipment from './components/finish-equipment'

interface ComponentProps {
  technicalVisitId: string
  equipments: Array<any>
  loadTechnicalVisit: () => Promise<void>
}

const Component: React.FC<ComponentProps> = ({ technicalVisitId, equipments, loadTechnicalVisit }) => {
  const { theme } = useAuth()
  const [equipment, setEquipment] = useState<any>()
  const [visibleFinish, enableFinish, disableFinish] = useToggle(false)

  return (
    <>
      <Grid>
        <Cell>
          <Text type='headline-6' margin='none'>
            Equipamentos
          </Text>
        </Cell>
        <Cell>
          {equipments && (
            <TableContainer>
              <Table fullWidth>
                <TableHeader>
                  <TableRow>
                    <TableCell>Equipamento</TableCell>
                    <TableCell>Tipo da Visita</TableCell>
                    <TableCell>Serviço Realizado</TableCell>
                    <TableCell>Horímetro</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {equipments.map((data: any, index: number) => (
                    <TableRow key={index}>
                      <TableCell>{data.equipmentModel}</TableCell>
                      <TableCell>{getVisitTypeDescription(data.visitType)}</TableCell>
                      <TableCell>{getServicePerformedDescription(data.servicePerformed)}</TableCell>
                      <TableCell>{data.hourmeter}</TableCell>
                      <TableCell align='right'>
                        {data.done && (
                          <Tooltipped id={`action-done-${index}`} tooltip={'Equipamento Finalizado'} position={'above'}>
                            <Button
                              id={`button-done-${index}`}
                              buttonType='icon'
                              themeType='contained'
                              style={
                                theme === Theme.Dark
                                  ? { backgroundColor: '#00695C', color: '#FFFFFF', margin: 2 }
                                  : { backgroundColor: '#4DB6AC', color: '#212121', margin: 2 }
                              }>
                              <FontIcon>check_circle</FontIcon>
                            </Button>
                          </Tooltipped>
                        )}

                        {!data.done && (
                          <Tooltipped id={`action-done-${index}`} tooltip={'Equipamento não Finalizado'} position={'above'}>
                            <Button
                              id={`button-not-done-${index}`}
                              buttonType='icon'
                              themeType='contained'
                              style={
                                theme === Theme.Dark
                                  ? { backgroundColor: '#d32f2f', color: '#FFFFFF', margin: 2 }
                                  : { backgroundColor: '#e57373', color: '#212121', margin: 2 }
                              }>
                              <FontIcon>do_not_disturb_on</FontIcon>
                            </Button>
                          </Tooltipped>
                        )}
                        <EditButton
                          id={`edit-${index}`}
                          tooltip={'Finalizar Equipamento'}
                          action={() => {
                            setEquipment(equipments.find(x => x.equipmentId === data.equipmentId))
                            enableFinish()
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Cell>
      </Grid>

      {equipment && (
        <FinishEquipment
          technicalVisitId={technicalVisitId}
          equipment={equipment}
          visibleModal={visibleFinish}
          disableModal={disableFinish}
          loadTechnicalVisit={loadTechnicalVisit}
        />
      )}
    </>
  )
}

export default Component
