import React, { useEffect, useState } from 'react'
import { Route } from 'react-router-dom'

import CompanyEdit from 'pages/financial/company/edit'
import CompanyNew from 'pages/financial/company/new'
import CompanySearch from 'pages/financial/company/search'

import PayableEdit from 'pages/financial/payable/edit'
import PayableNew from 'pages/financial/payable/new'
import PayableSearch from 'pages/financial/payable/search'

import ReceivableEdit from 'pages/financial/receivable/edit'
import ReceivableNew from 'pages/financial/receivable/new'
import ReceivableSearch from 'pages/financial/receivable/search'

import SupplierEdit from 'pages/financial/supplier/edit'
import SupplierNew from 'pages/financial/supplier/new'
import SupplierSearch from 'pages/financial/supplier/search'

import ReportFinancial from 'pages/financial/report/financial'
import ExpenseByClient from 'pages/financial/report/expense-by-client'

import { MenuPermission } from 'components/layout/components/menu/types'
import { useAuth } from 'contexts/auth/auth-context'

const Routes: React.FC = () => {
  const { user, getMenuPermission } = useAuth()
  const [menuPermission, setMenuPermission] = useState<MenuPermission>()

  useEffect(() => {
    setMenuPermission(getMenuPermission())
  }, [user?.permissions])

  return (
    <>
      {menuPermission?.financial.company && (
        <>
          <Route path='/financial/company/edit/:id' exact component={CompanyEdit} />
          <Route path='/financial/company/new' exact component={CompanyNew} />
          <Route path='/financial/company/search' exact component={CompanySearch} />
        </>
      )}

      {menuPermission?.financial.payable && (
        <>
          <Route path='/financial/payable/edit/:id' exact component={PayableEdit} />
          <Route path='/financial/payable/new' exact component={PayableNew} />
          <Route path='/financial/payable/search' exact component={PayableSearch} />
        </>
      )}

      {menuPermission?.financial.receivable && (
        <>
          <Route path='/financial/receivable/edit/:id' exact component={ReceivableEdit} />
          <Route path='/financial/receivable/new' exact component={ReceivableNew} />
          <Route path='/financial/receivable/search' exact component={ReceivableSearch} />
        </>
      )}

      {menuPermission?.financial.supplier && (
        <>
          <Route path='/financial/supplier/edit/:id' exact component={SupplierEdit} />
          <Route path='/financial/supplier/new' exact component={SupplierNew} />
          <Route path='/financial/supplier/search' exact component={SupplierSearch} />
        </>
      )}

      {menuPermission?.financial.report && (
        <>
          <Route path='/financial/report/financial' exact component={ReportFinancial} />
          <Route path='/financial/report/expense-by-client' exact component={ExpenseByClient} />
        </>
      )}
    </>
  )
}

export default Routes
