import api from 'services/inventory/equipment'
import { DataTableRequest } from 'components/data-table/types'

export const handleSearch = async (request: DataTableRequest) => {
  let res = await api.search(request)

  return res?.data ?? []
}

export const handleEdit = (id: string) => {
  window.open(`/inventory/equipment/edit/${id}`)
}

export const handleDelete = async (id: string) => {
  let res = await api.delete(id)

  return res?.data?.success
}
