import Yup from 'utils/yup'

export const defaultValues = {
  companyId: null,
  client: null,
  initialDueDate: null,
  finalDueDate: null,
  status: null,
  documentNumber: null,
  documentType: null,
  initialReceivableDate: null,
  finalReceivableDate: null,
  statusOfReceiptMade: null,
}

export const validationSchema = Yup.object().shape({
  companyId: Yup.string().nullable(),
  client: Yup.object({
    id: Yup.string().nullable(),
  }).nullable(),
  initialDueDate: Yup.date().nullable(),
  finalDueDate: Yup.date().nullable(),
  status: Yup.string().nullable(),
  documentNumber: Yup.string().nullable(),
  documentType: Yup.string().nullable(),
  initialReceivableDate: Yup.date().nullable(),
  finalReceivableDate: Yup.date().nullable(),
  statusOfReceiptMade: Yup.string().nullable(),
})
