import React from 'react'
import { TextField } from 'react-md'

import Cell from 'components/core/cell'
import { FieldProps } from 'components/form/types'

interface LabelProps extends FieldProps {
  label: string
  value: Date | string | null | undefined
}

export const TimeLabel = React.memo(({ name, label, value, desktopSize, tabletSize }: LabelProps) => {
  return (
    <Cell tabletSize={tabletSize} desktopSize={desktopSize}>
      <TextField id={name} name={name} theme={'underline'} disabled={true} label={label} value={value ? String(value).substring(0, 5) : ''} />
    </Cell>
  )
})
