import Yup from 'utils/yup'

export const defaultValues = {
  visitType: null,
  servicePerformed: null,
  hourmeter: null,
  adjustmentHours: null,
  observation: null,
}

export const validationSchema = Yup.object().shape({
  visitType: Yup.string().nullable().required(),
  servicePerformed: Yup.string().nullable().required(),
  hourmeter: Yup.number().nullable().required(),
  adjustmentHours: Yup.number().nullable(),
  observation: Yup.string().nullable(),
})
