import Yup from 'utils/yup'

export const defaultValues = {
  companyId: null,
  supplier: {
    id: null,
  },
  client: {
    id: null,
  },
  initialDueDate: null,
  finalDueDate: null,
  status: null,
  documentNumber: null,
  documentType: null,
  initialPaymentDate: null,
  finalPaymentDate: null,
  destinationExpense: null,
  statusOfReceiptMade: null,
  expenseType: null,
}

export const validationSchema = Yup.object().shape({
  companyId: Yup.string().nullable(),
  supplier: Yup.object({
    id: Yup.string().nullable(),
  }),
  client: Yup.object({
    id: Yup.string().nullable(),
  }),
  initialDueDate: Yup.date().nullable(),
  finalDueDate: Yup.date().nullable(),
  status: Yup.string().nullable(),
  documentNumber: Yup.string().nullable(),
  documentType: Yup.string().nullable(),
  initialPaymentDate: Yup.date().nullable(),
  finalPaymentDate: Yup.date().nullable(),
  destinationExpense: Yup.string().nullable(),
  expenseType: Yup.string().nullable(),
  statusOfReceiptMade: Yup.string().nullable(),
})
