export const getEquipmentStatusDescription = (status: string): string => {
  return EquipmentStatusOptions.filter(x => x.value === status?.toString())[0]?.label
}
export interface EquipmentStatusOption {
  value: EquipmentStatusEnum | null
  label: string
}

export enum EquipmentStatusEnum {
  InStock = 'InStock',
  Leased = 'Leased',
  Proprietary = 'Proprietary',
  Sold = 'Sold',
}

export const EquipmentStatusOptions: Array<EquipmentStatusOption> = [
  { value: null, label: '' },
  { value: EquipmentStatusEnum.InStock, label: 'Em Estoque' },
  { value: EquipmentStatusEnum.Leased, label: 'Locado' },
  { value: EquipmentStatusEnum.Proprietary, label: 'Do Cliente' },
  { value: EquipmentStatusEnum.Sold, label: 'Vendido' },
]
