import React, { useState } from 'react'
import { Table, TableBody, TableCell, TableContainer, TableRow, useToggle } from 'react-md'

import { Callout, ConfirmDialog, Modal } from 'components/core'
import { DeleteButton } from 'components/data-table'
import { CalloutType } from 'components/core/callout'

import { handleRemoveMaintenanceInspection } from './actions'

import AddInspection from './components/add'

interface ComponentProps {
  technicalVisitId: string
  technicalVisitEquipmentId: string | undefined
  equipmentId: string | undefined
  maintenanceInspections: Array<any> | undefined
  visibleModal: boolean
  disableModal: () => void
  loadMaintenanceInspections: () => Promise<void>
}

const Component: React.FC<ComponentProps> = ({
  technicalVisitId,
  technicalVisitEquipmentId,
  equipmentId,
  maintenanceInspections,
  visibleModal,
  disableModal,
  loadMaintenanceInspections,
}) => {
  const [inspectionId, setInspectionId] = useState<string | null>(null)
  const [visibleAdd, enableAdd, disableAdd] = useToggle(false)

  return (
    <>
      <Modal
        title={'Itens de Inspeção'}
        visible={visibleModal}
        confirmTitle={'Adicionar Item de Inspeção'}
        confirmAction={async () => enableAdd()}
        closeTitle={'Fechar'}
        closeAction={async () => disableModal()}>
        {(!maintenanceInspections || maintenanceInspections.length == 0) && (
          <Callout text={'Nenhum item de inspeção localizado'} type={CalloutType.Info} />
        )}
        {maintenanceInspections && maintenanceInspections.length > 0 && (
          <TableContainer>
            <Table fullWidth>
              <TableBody>
                {maintenanceInspections?.map((inspection, index) => (
                  <TableRow key={index}>
                    <TableCell grow>{inspection.label}</TableCell>
                    <TableCell align='right'>
                      <DeleteButton id='delete-inspection-button' action={() => setInspectionId(inspection.value)} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Modal>

      <AddInspection
        technicalVisitId={technicalVisitId}
        technicalVisitEquipmentId={technicalVisitEquipmentId}
        equipmentId={equipmentId}
        visibleModal={visibleAdd}
        disableModal={disableAdd}
        loadMaintenanceInspections={loadMaintenanceInspections}
      />

      <ConfirmDialog
        text='Deseja realmente excluir o item de inspeção? Essa ação não poderá ser desfeita'
        visible={inspectionId ? true : false}
        confirmAction={async () => {
          if (inspectionId) {
            if (technicalVisitEquipmentId && (await handleRemoveMaintenanceInspection(technicalVisitId, technicalVisitEquipmentId, inspectionId))) {
              await loadMaintenanceInspections()
              setInspectionId(null)
            }
          }
        }}
        cancelAction={async () => setInspectionId(null)}
      />
    </>
  )
}

export default Component
