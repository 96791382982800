import React, { useEffect, useState } from 'react'
import { Card, CardActions, CardContent, FontIcon, TableCell, TableRow, useToggle } from 'react-md'

import { DataTableResult } from 'components/data-table/types'
import { CustomButton, DataTable, DeleteButton } from 'components/data-table'

import { handleGetEquipments, handleGetMaintenanceActions, handleGetMaintenanceInspections, handleRemoveEquipment } from './actions'
import { ButtonEdit, ConfirmDialog } from 'components/core'

import AddEquipment from './components/add-equipment'
import ListMaintenanceAction from './components/maintenance-action'
import ListMaintenanceInspection from './components/maintenance-inspection'

interface ComponentProps {
  id: string
  clientId: string
}

const Component: React.FC<ComponentProps> = ({ id, clientId }) => {
  const [filter, setFilter] = useState<any>()
  const [result, setResult] = useState<DataTableResult>({ total: 0, results: [] })
  const [toogleUpdate, setToogleUpdate] = useState<boolean>(false)
  const [deleteId, setDeleteId] = useState<string>()
  const [technicalVisitEquipmentId, setTechnicalVisitEquipmentId] = useState<string>()
  const [equipmentId, setEquipmentId] = useState<string>()

  const [maintenanceActions, setMaintenanceActions] = useState<Array<any>>()
  const [maintenanceInspections, setMaintenanceInspections] = useState<Array<any>>()

  const [visibleAddEquipment, enableAddEquipment, disableAddEquipment] = useToggle(false)
  const [visibleListMaintenanceActions, enableListMaintenanceActions, disableListMaintenanceActions] = useToggle(false)
  const [visibleListMaintenanceInspections, enableListMaintenanceInspections, disableListMaintenanceInspections] = useToggle(false)

  useEffect(() => {
    setFilter({ technicalVisitId: id })
  }, [id])

  useEffect(() => {
    if (equipmentId && visibleListMaintenanceActions) loadMaintenanceActions()
  }, [equipmentId, visibleListMaintenanceActions])

  useEffect(() => {
    if (equipmentId && visibleListMaintenanceInspections) loadMaintenanceInspections()
  }, [equipmentId, visibleListMaintenanceInspections])

  const loadMaintenanceActions = async () => {
    if (technicalVisitEquipmentId) setMaintenanceActions(await handleGetMaintenanceActions(id, technicalVisitEquipmentId))
  }

  const loadMaintenanceInspections = async () => {
    if (technicalVisitEquipmentId) setMaintenanceInspections(await handleGetMaintenanceInspections(id, technicalVisitEquipmentId))
  }

  return (
    <>
      <Card fullWidth>
        <CardContent>
          <DataTable
            filter={filter}
            header={[{ label: 'Equipamento' }, { label: '' }]}
            result={result}
            setResult={setResult}
            search={handleGetEquipments}
            total={result ? result.total : 0}
            showEmptyMessageAlert={false}
            emptyMessage={'Nenhuma equipamento localizado'}
            update={toogleUpdate}
            wrapTableWithCard={false}>
            {result &&
              result.results &&
              result.results.map((data: any, index) => (
                <TableRow key={index}>
                  <TableCell>{data.equipmentModel}</TableCell>
                  <TableCell align='right'>
                    <CustomButton
                      id={`list-action-${index}`}
                      icon={'more_time'}
                      tooltip={'Ações de Manutenção'}
                      whiteThemeBackgroundColor={'#7986cb'}
                      darkThemeBackgroundColor={'#303f9f'}
                      action={async () => {
                        setTechnicalVisitEquipmentId(data.id)
                        setEquipmentId(data.equipmentId)
                        enableListMaintenanceActions()
                      }}
                    />
                    <CustomButton
                      id={`list-inspection-${index}`}
                      icon={'add_task'}
                      tooltip={'Itens de Inspeção'}
                      whiteThemeBackgroundColor={'#4dd0e1'}
                      darkThemeBackgroundColor={'#00838f'}
                      action={async () => {
                        setTechnicalVisitEquipmentId(data.id)
                        setEquipmentId(data.equipmentId)
                        enableListMaintenanceInspections()
                      }}
                    />
                    <DeleteButton id={`delete-${index}`} action={() => setDeleteId(data.id)} />
                  </TableCell>
                </TableRow>
              ))}
          </DataTable>
        </CardContent>
        <CardActions>
          <ButtonEdit text='Adicionar Equipamento' icon={<FontIcon>add</FontIcon>} onClick={async () => enableAddEquipment()} />
        </CardActions>
      </Card>

      <AddEquipment
        technicalVisitId={id}
        clientId={clientId}
        visibleModal={visibleAddEquipment}
        disableModal={disableAddEquipment}
        loadParts={() => setToogleUpdate(!toogleUpdate)}
      />

      <ListMaintenanceAction
        technicalVisitId={id}
        technicalVisitEquipmentId={technicalVisitEquipmentId}
        equipmentId={equipmentId}
        maintenanceActions={maintenanceActions}
        visibleModal={visibleListMaintenanceActions}
        disableModal={disableListMaintenanceActions}
        loadMaintenanceActions={loadMaintenanceActions}
      />

      <ListMaintenanceInspection
        technicalVisitId={id}
        technicalVisitEquipmentId={technicalVisitEquipmentId}
        equipmentId={equipmentId}
        maintenanceInspections={maintenanceInspections}
        visibleModal={visibleListMaintenanceInspections}
        disableModal={disableListMaintenanceInspections}
        loadMaintenanceInspections={loadMaintenanceInspections}
      />

      <ConfirmDialog
        text='Deseja realmente excluir o equipamento da visita técnica? Essa ação não poderá ser desfeita'
        visible={deleteId ? true : false}
        confirmAction={async () => {
          if (deleteId) if (await handleRemoveEquipment(id, deleteId)) setToogleUpdate(!toogleUpdate)
          setDeleteId(undefined)
        }}
        cancelAction={async () => setDeleteId(undefined)}
      />
    </>
  )
}

export default Component
