import React, { useState, useEffect } from 'react'
import { Card, CardContent, Table, TableBody, TableRow, TableCell, CardActions, FileInput, FontIcon } from 'react-md'

import { CustomButton, DeleteButton } from 'components/data-table'
import { Callout, ConfirmDialog } from 'components/core'
import { CalloutType } from 'components/core/callout'

import { handleAddFile, handleGetFile, handleGetFiles, handleRemoveFile } from './actions'
interface ComponentProps {
  id: string
}

interface File {
  id: string
  fileName: string
  fileBase64: string
}

const Component: React.FC<ComponentProps> = ({ id }) => {
  const [files, setFiles] = useState<Array<File>>([])
  const [deleteId, setDeleteId] = useState<string>()

  useEffect(() => {
    getFiles()
  }, [id])

  const getFiles = async () => setFiles(await handleGetFiles(id))

  const addFile = async (e: any) => {
    let file = e.target.files[0]
    let fileName = e.target.files[0].name
    let reader = new FileReader()
    reader.readAsBinaryString(file)
    reader.onload = async () => {
      if (reader && reader.result) {
        if (
          await handleAddFile({
            technicalVisitId: id,
            fileName: fileName,
            fileBase64: btoa(reader.result.toString()),
          })
        ) {
          await getFiles()
        }
      }
    }
  }

  return (
    <>
      <Card fullWidth>
        <CardContent>
          {(!files || files.length === 0) && <Callout text={'Nenhum arquivo localizado'} type={CalloutType.Info} />}
          {files && (
            <Table fullWidth>
              <TableBody>
                {files.map((data: File, index) => (
                  <TableRow key={index}>
                    <TableCell>{data.fileName}</TableCell>
                    <TableCell align='right'>
                      <CustomButton
                        id={`download-${index}`}
                        tooltip={'Download'}
                        action={async () => await handleGetFile(data.fileName, id, data.id)}
                        icon={'download'}
                        whiteThemeBackgroundColor={'#64b5f6'}
                        darkThemeBackgroundColor={'#1565c0'}
                      />
                      <DeleteButton id={`delete-${index}`} action={() => setDeleteId(data.id)} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </CardContent>
        <CardActions>
          <FileInput
            id='import-input'
            theme={'secondary'}
            themeType={'contained'}
            buttonType={'text'}
            accept={'.pdf,.doc,.docx,.xls,.xlsx'}
            icon={<FontIcon>description</FontIcon>}
            onChange={async event => {
              if (event?.target?.files?.[0]) await addFile(event)
            }}>
            {'Upload de Arquivo'}
          </FileInput>
        </CardActions>
      </Card>

      <ConfirmDialog
        text='Deseja realmente excluir o arquivo? Essa ação não poderá ser desfeita'
        visible={deleteId ? true : false}
        confirmAction={async () => {
          if (deleteId && (await handleRemoveFile(id, deleteId))) setFiles(files.filter(x => x.id != deleteId))
          setDeleteId(undefined)
        }}
        cancelAction={async () => setDeleteId(undefined)}
      />
    </>
  )
}

export default Component
