interface StatusOption {
  value: StatusEnum | null
  label: string
}

enum StatusEnum {
  PayableOrReceivable = 'PayableOrReceivable',
  InDelay = 'InDelay',
  PaidOrReceived = 'PaidOrReceived',
}

export const StatusOptions: Array<StatusOption> = [
  { value: null, label: '' },
  { value: StatusEnum.PayableOrReceivable, label: 'A Receber/A Pagar' },
  { value: StatusEnum.InDelay, label: 'Em Atraso' },
  { value: StatusEnum.PaidOrReceived, label: 'Recebido/Pago' },
]

interface StatusOfPaymentOrReceveidMadeOption {
  value: StatusOfPaymentOrReceveidMadeEnum | null
  label: string
}

enum StatusOfPaymentOrReceveidMadeEnum {
  PerformedInDelay = 'PerformedInDelay',
  PerformedOnDeadline = 'PerformedOnDeadline',
}

export const StatusOfPaymentOrReceveidMadeOptions: Array<StatusOfPaymentOrReceveidMadeOption> = [
  { value: null, label: '' },
  { value: StatusOfPaymentOrReceveidMadeEnum.PerformedInDelay, label: 'Realizado em Atraso' },
  { value: StatusOfPaymentOrReceveidMadeEnum.PerformedOnDeadline, label: 'Realizado no Prazo' },
]
