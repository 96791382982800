import Yup from 'utils/yup'

export const addressDefaultValues = {
  street: null,
  number: null,
  complement: null,
  neighborhood: null,
  city: null,
  state: null,
  zipCode: null,
}

export const addressValidationSchema = Yup.object().shape({
  street: Yup.string().nullable().required().max(90, 'O logradouro deve ter no máximo 90 caracteres'),
  number: Yup.string().nullable().required().max(20, 'O número deve ter no máximo 20 caracteres'),
  complement: Yup.string().nullable().max(50, 'O complemento deve ter no máximo 50 caracteres'),
  neighborhood: Yup.string().nullable().required().max(60, 'O bairro deve ter no máximo 60 caracteres'),
  city: Yup.string().nullable().required().max(60, 'A cidade deve ter no máximo 60 caracteres'),
  state: Yup.string().nullable().required(),
  zipCode: Yup.number().nullable().required(),
})
