import React, { useEffect } from 'react'
import Bounce from 'react-reveal/Bounce'
import { Grid, Card, CardContent, FontIcon } from 'react-md'
import { FormContext, useForm } from 'react-hook-form'

import { handleSubmit, Input } from 'components/form'
import { Cell, ButtonCustom } from 'components/core'
import { useAuth } from 'contexts/auth/auth-context'
import { history } from 'utils/history'

import Logo from 'components/core/logo'

import { defaultValues, validationSchema } from './config'

const Component: React.FC = () => {
  const { login } = useAuth()
  const form = useForm({ defaultValues })

  const callSubmit = async () => {
    await handleSubmit(form, validationSchema, async () => await login(form.getValues()))
  }

  useEffect(() => {
    document.title = 'Login'
  }, [])

  return (
    <Bounce bottom>
      <Grid>
        <Cell tabletSize={6} tabletStart={2} desktopSize={4} desktopStart={5}>
          <Card fullWidth>
            <CardContent>
              <FormContext {...form}>
                <Logo />
                <Input
                  name='login'
                  label='Usuário *'
                  onKeyPress={(e: any) => {
                    if (e.key === 'Enter') callSubmit()
                  }}
                  rightChildren={<FontIcon>account_box</FontIcon>}
                />
                <Input
                  name='password'
                  label='Senha *'
                  type='password'
                  onKeyPress={(e: any) => {
                    if (e.key === 'Enter') callSubmit()
                  }}
                  rightChildren={<FontIcon>vpn_key</FontIcon>}
                />
                <ButtonCustom
                  text='Login'
                  icon={<FontIcon>done</FontIcon>}
                  disabled={!form.formState.dirty}
                  whiteThemeBackgroundColor={'#0277bd'}
                  darkThemeBackgroundColor={'#01579b'}
                  onClick={async () => await callSubmit()}
                  style={{ width: '100%' }}
                />
                <ButtonCustom
                  text='Esqueci Minha Senha'
                  icon={<FontIcon>restore</FontIcon>}
                  whiteThemeBackgroundColor={'#e0e0e0'}
                  darkThemeBackgroundColor={'#424242'}
                  onClick={async () => history.push('/forgot-password/send')}
                  style={{ width: '100%' }}
                />
              </FormContext>
            </CardContent>
          </Card>
        </Cell>
      </Grid>
    </Bounce>
  )
}

export default Component
