import api from 'services/inventory/part'

export const handleGet = async (id: string) => {
  let res = await api.get(id)

  return res?.data?.data
}

export const handleSave = async (values: any) => {
  let res = await api.update(values)

  return res?.data?.success
}

export const handleAddQuantity = async (values: any) => {
  let res = await api.addQuantity(values)

  return res?.data?.success
}

export const handleRemoveQuantity = async (values: any) => {
  let res = await api.removeQuantity(values)

  return res?.data?.success
}
