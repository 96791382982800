import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { FormContext, useForm } from 'react-hook-form'
import { Card, CardActions, CardContent, FontIcon, Grid, Text } from 'react-md'

import { ButtonLog, Cell, PageTitle, ButtonSuccess } from 'components/core'
import { Input, InputDocument, Switch } from 'components/form'
import { addressDefaultValues, addressValidationSchema } from 'components/address/config'
import Address from 'components/address'

import { defaultValues, validationSchema } from './config'
import { handleGet, handleSave, handleValidateForms } from './actions'

const Component: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const form = useForm({ defaultValues })
  const addressForm = useForm({ defaultValues: addressDefaultValues })

  useEffect(() => {
    document.title = 'Edição de Empresa'
  }, [])

  useEffect(() => {
    const load = async () => {
      const res = await handleGet(id)
      if (res) {
        form.reset(res)
        addressForm.reset(res.address)
      }
    }

    load()

    return () => {}
  }, [id])

  return (
    <Grid>
      <Cell>
        <PageTitle title='Edição de Empresa' />
        <Card fullWidth>
          <CardContent>
            <Grid>
              <Cell>
                <Text type='headline-6' margin='none'>
                  Informações Gerais
                </Text>
              </Cell>
              <Cell>
                <Grid>
                  <FormContext {...form}>
                    <Input name='fantasyName' label='Nome Fantasia *' desktopSize={6} tabletSize={4} />
                    <Input name='companyName' label='Razão Social *' desktopSize={6} tabletSize={4} />
                    <InputDocument name='document' label='Cnpj *' maxLength={18} desktopSize={3} tabletSize={4} />
                    <Input name='ie' label='IE' desktopSize={3} tabletSize={4} />
                    <Switch name='active' labelTrue='Ativo' labelFalse='Inativo' defaultChecked={false} inline desktopSize={3} tabletSize={4} />
                  </FormContext>
                </Grid>
              </Cell>
              <Cell>
                <Text type='headline-6' margin='none'>
                  Endereço
                </Text>
              </Cell>
              <Cell>
                <Grid>
                  <Address form={addressForm} />
                </Grid>
              </Cell>
            </Grid>
          </CardContent>
          <CardActions>
            <ButtonSuccess
              text='Salvar'
              icon={<FontIcon>done</FontIcon>}
              disabled={!form.formState.dirty && !addressForm.formState.dirty}
              onClick={async () => {
                if (
                  await handleValidateForms(
                    { form: form, validationSchema: validationSchema },
                    { form: addressForm, validationSchema: addressValidationSchema },
                  )
                ) {
                  if (await handleSave({ id: id, ...form.getValues(), address: addressForm.getValues() })) {
                    form.reset(form.getValues(), { dirty: false })
                    addressForm.reset(addressForm.getValues(), { dirty: false })
                  }
                }
              }}
            />
            <ButtonLog entity='Company' id={id} />
          </CardActions>
        </Card>
      </Cell>
    </Grid>
  )
}

export default Component
