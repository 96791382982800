import Yup from 'utils/yup'

export const defaultValues = {
  part: {
    id: null,
  },
  quantity: null,
}

export const validationSchema = Yup.object().shape({
  part: Yup.object({
    id: Yup.string().nullable().required(),
  }),
  quantity: Yup.number().nullable().required().min(1, 'Quantidade inválida'),
})
