export const makeId = () => {
  let id = ''
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  for (var i = 0; i < 12; i++) id += characters.charAt(Math.floor(Math.random() * 36))
  return id
}

export const getProposalStatusDescription = (status: string): string => {
  return ProposalStatusOptions.filter(x => x.value === status?.toString())[0]?.label
}

export const getChanceClosingDescription = (status: string): string => {
  return ChanceClosingOptions.filter(x => x.value === status?.toString())[0]?.label
}

export interface ProposalStatusOption {
  value: ProposalStatusEnum | null
  label: string
}

export enum ProposalStatusEnum {
  InProcess = 'InProcess',
  ClosedByCustomer = 'ClosedByCustomer',
  Win = 'Win',
  Lose = 'Lose',
}

export const ProposalStatusOptions: Array<ProposalStatusOption> = [
  { value: null, label: '' },
  { value: ProposalStatusEnum.InProcess, label: 'Em Processo' },
  { value: ProposalStatusEnum.ClosedByCustomer, label: 'Encerrado Pelo Cliente' },
  { value: ProposalStatusEnum.Win, label: 'Ganhamos' },
  { value: ProposalStatusEnum.Lose, label: 'Perdemos' },
]

export interface ChanceClosingOption {
  value: ChanceClosingEnum | null
  label: string
}

export enum ChanceClosingEnum {
  Cold = 'Cold',
  Warm = 'Warm',
  Hot = 'Hot',
}

export const ChanceClosingOptions: Array<ChanceClosingOption> = [
  { value: null, label: '' },
  { value: ChanceClosingEnum.Cold, label: 'Frio' },
  { value: ChanceClosingEnum.Warm, label: 'Morno' },
  { value: ChanceClosingEnum.Hot, label: 'Quente' },
]
