export interface RegionOption {
  value: RegionEnum | null
  label: string
}

export enum RegionEnum {
  Midwest = 'Midwest',
  NorthEast = 'NorthEast',
  North = 'North',
  Southeast = 'Southeast',
  South = 'South',
}

export interface BusinessModelOption {
  value: BusinessModelEnum | null
  label: string
}

export enum BusinessModelEnum {
  Location = 'Location',
  Sale = 'Sale',
}

export interface SituationOption {
  value: SituationEnum | null
  label: string
}

export enum SituationEnum {
  Prospect = 'Prospect',
  Client = 'Client',
  Inactive = 'Inactive',
}

export const RegionOptions: Array<RegionOption> = [
  { value: null, label: '' },
  { value: RegionEnum.Midwest, label: 'Centro-Oeste' },
  { value: RegionEnum.NorthEast, label: 'Nordeste' },
  { value: RegionEnum.North, label: 'Norte' },
  { value: RegionEnum.Southeast, label: 'Sudeste' },
  { value: RegionEnum.South, label: 'Sul' },
]

export const BusinessModelOptions: Array<BusinessModelOption> = [
  { value: null, label: '' },
  { value: BusinessModelEnum.Location, label: 'Locação' },
  { value: BusinessModelEnum.Sale, label: 'Venda' },
]

export const SituationOptions: Array<SituationOption> = [
  { value: null, label: '' },
  { value: SituationEnum.Prospect, label: 'Prospecto' },
  { value: SituationEnum.Client, label: 'Cliente' },
  { value: SituationEnum.Inactive, label: 'Inativo' },
]
