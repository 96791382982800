import React from 'react'
import { TextField } from 'react-md'

import Cell from 'components/core/cell'
import { FieldProps } from 'components/form/types'

interface LabelProps extends FieldProps {
  label: string
  value: number | null | undefined
}

export const NumberLabel = React.memo(({ name, label, value, desktopSize, tabletSize }: LabelProps) => {
  return (
    <Cell tabletSize={tabletSize} desktopSize={desktopSize}>
      <TextField id={name} name={name} theme={'underline'} disabled={true} label={label} value={String(value ?? '')} />
    </Cell>
  )
})
