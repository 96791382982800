import React, { useEffect, useState } from 'react'
import { Card, CardContent, Table, TableBody, TableCell, TableContainer, TableHeader, TableRow } from 'react-md'
import { parseISO } from 'date-fns'
import { format } from 'date-fns'

import { getProposalStatusDescription } from 'types/sale/proposal'
import { CalloutType } from 'components/core/callout'
import { formatCurrency } from 'types/core/currency'
import { EditButton } from 'components/data-table'
import { Callout } from 'components/core'

import { handleGetProposals, handleEditProposal } from './actions'

interface ComponentProps {
  id: string
}

const Component: React.FC<ComponentProps> = ({ id }) => {
  const [proposals, setProposals] = useState<Array<any> | null>(null)

  useEffect(() => {
    loadProposals()
  }, [id])

  const loadProposals = async () => setProposals(await handleGetProposals(id))

  return (
    <Card fullWidth>
      <CardContent>
        {(!proposals || proposals.length == 0) && <Callout text={'Nenhuma proposta localizada'} type={CalloutType.Info} />}
        {proposals && proposals.length > 0 && (
          <TableContainer>
            <Table fullWidth>
              <TableHeader>
                <TableRow>
                  <TableCell>Data de FollowUp</TableCell>
                  <TableCell>Data do Último Contato</TableCell>
                  <TableCell>Descrição</TableCell>
                  <TableCell>Valor Total</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                {proposals.map((data: any, index) => (
                  <TableRow key={index}>
                    <TableCell>{data.followUpDate ? format(parseISO(data.followUpDate), "dd'/'MM'/'yyyy") : null}</TableCell>
                    <TableCell>{data.lastContactDate ? format(parseISO(data.lastContactDate), "dd'/'MM'/'yyyy' 'HH:mm:ss") : null}</TableCell>
                    <TableCell>{data.description}</TableCell>
                    <TableCell>{formatCurrency(data.totalValue)}</TableCell>
                    <TableCell>{getProposalStatusDescription(data.proposalStatus)}</TableCell>
                    <TableCell align='right'>
                      <EditButton id={`edit-${index}`} action={() => handleEditProposal(data.id)} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </CardContent>
    </Card>
  )
}

export default Component
