import { useEffect } from 'react'
import { Select as SelectTemplate, FormMessage } from 'react-md'
import { usePromiseTracker } from 'react-promise-tracker'
import { useFormContext } from 'react-hook-form'

import Cell from 'components/core/cell'

import { FieldProps } from './types'

interface SelectProps extends FieldProps {
  options: Array<any>
}

export const Select = ({ name, options, desktopSize, tabletSize, disabled, ...rest }: SelectProps) => {
  const { promiseInProgress } = usePromiseTracker()
  const form = useFormContext()
  const value = form.watch(name)

  useEffect(() => {
    form.register({ name })
  }, [form.register])

  return (
    <Cell tabletSize={tabletSize} desktopSize={desktopSize}>
      <SelectTemplate
        id={name}
        name={name}
        theme={'underline'}
        options={options}
        disableLeftAddon={true}
        value={value ? value?.toString() : ''}
        error={form.errors[name] ? true : false}
        disabled={disabled || promiseInProgress}
        onChange={select => {
          if (form.errors[name]) form.clearError(name)
          form.setValue(name, select == '' ? null : select)
        }}
        {...rest}
      />
      {form.errors[name] && (
        <FormMessage id={name} error style={{ paddingLeft: 0 }}>
          {form.errors[name].message}
        </FormMessage>
      )}
    </Cell>
  )
}
