import { LayoutNavigationItem, LayoutNavigationTree } from 'react-md'

import { MenuPermission } from 'components/layout/components/menu/types'

interface getMenuProps {
  permissions: MenuPermission
  menu: LayoutNavigationTree<LayoutNavigationItem>
  getMenu: (id: string, parent: string | null, title: string, icon: string, path: string | null) => LayoutNavigationItem
}

export const getSaleMenu = ({ permissions, menu, getMenu }: getMenuProps) => {
  if (permissions.sale.proposal) {
    menu['/sale/proposal'] = getMenu('/sale/proposal', null, 'Propostas', 'request_quote', null)
    menu['/sale/proposal/new'] = getMenu('/sale/proposal/new', '/sale/proposal', 'Nova', 'add', '/sale/proposal/new')
    menu['/sale/proposal/search'] = getMenu('/sale/proposal/search', '/sale/proposal', 'Pesquisa', 'search', '/sale/proposal/search')
  }
}
