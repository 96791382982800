import React, { useEffect } from 'react'
import { Grid } from '@react-md/utils'
import { FormContext, useForm } from 'react-hook-form'
import { Card, CardActions, CardContent, FontIcon } from 'react-md'

import { getTechnicalVisitStatusDescription } from 'types/technical-visit/technical-visit'

import { ButtonCustom, ButtonLog, ButtonSuccess } from 'components/core'
import { handleSubmit, InputDatePicker, InputTime } from 'components/form'
import { NumberLabel, TextLabel } from 'components/label'
import { TechnicalVisitStatusEnum } from 'types/technical-visit/technical-visit'

import { handleGetIcs, handleGetPdf, handleSave } from './actions'
import { defaultValues, validationSchema } from './config'

interface ComponentProps {
  id: string
  technicalVisit: any
}

const Component: React.FC<ComponentProps> = ({ id, technicalVisit }) => {
  const form = useForm({ defaultValues })

  useEffect(() => form.reset(technicalVisit), [technicalVisit])

  return (
    <Card fullWidth>
      <CardContent>
        <FormContext {...form}>
          <Grid>
            <TextLabel name='clientFantasyName' label='Cliente' value={technicalVisit.clientFantasyName} desktopSize={6} tabletSize={4} />
            <TextLabel name='technicianName' label='Técnico' value={technicalVisit.technicianName} desktopSize={6} tabletSize={4} />
            <NumberLabel name='number' label='Número' value={technicalVisit.number} desktopSize={3} tabletSize={4} />
            <TextLabel
              name='technicalVisitStatus'
              label='Status *'
              value={getTechnicalVisitStatusDescription(technicalVisit.technicalVisitStatus)}
              desktopSize={3}
              tabletSize={4}
            />
            <InputDatePicker name='visitDate' label='Data da Visita *' desktopSize={3} tabletSize={4} />
            <InputTime name='visitTime' label='Horário da Visita *' desktopSize={3} tabletSize={4} />
          </Grid>
        </FormContext>
      </CardContent>
      <CardActions>
        <ButtonSuccess
          text='Salvar'
          icon={<FontIcon>done</FontIcon>}
          disabled={!form.formState.dirty}
          onClick={async () =>
            await handleSubmit(form, validationSchema, async () => {
              if (await handleSave({ ...form.getValues(), id: id })) form.reset(form.getValues(), { dirty: false })
            })
          }
        />
        <ButtonCustom
          text='Finalizar Visita'
          icon={<FontIcon>task_alt</FontIcon>}
          disabled={technicalVisit.technicalVisitStatus !== TechnicalVisitStatusEnum.Opened}
          whiteThemeBackgroundColor={'#ff8a65'}
          darkThemeBackgroundColor={'#d84315'}
          onClick={async () => {
            window.open(`/technical-visit/finish/${id}`)
          }}
        />
        <ButtonCustom
          text='Download PDF'
          icon={<FontIcon>download</FontIcon>}
          whiteThemeBackgroundColor={'#7986cb'}
          darkThemeBackgroundColor={'#303f9f'}
          onClick={async () => await handleGetPdf(id)}
        />
        <ButtonCustom
          text='Download ICS'
          icon={<FontIcon>event</FontIcon>}
          whiteThemeBackgroundColor={'#4dd0e1'}
          darkThemeBackgroundColor={'#00838f'}
          onClick={async () => await handleGetIcs(id)}
        />
        <ButtonLog entity='TechnicalVisit' id={id} />
      </CardActions>
    </Card>
  )
}

export default Component
