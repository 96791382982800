import { trackPromise } from 'react-promise-tracker'

import api from 'utils/api'
import { ApiResponse } from 'utils/types'
import { DataTableRequest } from 'components/data-table/types'
import { AxiosRequestConfig } from 'axios'

const headerConfig: AxiosRequestConfig = {
  responseType: 'arraybuffer',
}

const url = '/v1/proposal'
const Api = {
  get: (id: string): Promise<ApiResponse> => trackPromise<ApiResponse>(api.get(`${url}/${id}`)),
  getFile: (proposalId: string, fileId: string): Promise<any> =>
    trackPromise<ApiResponse>(api.get(`${url}/${proposalId}/file/${fileId}`, headerConfig)),
  getFilesById: (id: string): Promise<ApiResponse> => trackPromise<ApiResponse>(api.get(`${url}/${id}/file`)),
  create: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(url, request)),
  addFile: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/file`, request)),
  search: (request: DataTableRequest): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/search`, request)),
  update: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.put(url, request)),
  delete: (id: string): Promise<ApiResponse> => trackPromise<ApiResponse>(api.delete(`${url}/${id}`)),
  removeFile: (proposalId: string, id: string): Promise<ApiResponse> => trackPromise<ApiResponse>(api.delete(`${url}/${proposalId}/file/${id}`)),
}

export default Api
