import { trackPromise } from 'react-promise-tracker'

import api from 'utils/api'
import { ApiResponse } from 'utils/types'
import { DataTableRequest } from 'components/data-table/types'
import { AxiosRequestConfig } from 'axios'

const headerConfig: AxiosRequestConfig = {
  responseType: 'arraybuffer',
}

const url = '/v1/technical-visit'
const Api = {
  get: (id: string): Promise<ApiResponse> => trackPromise<ApiResponse>(api.get(`${url}/${id}`)),
  getPdf: (id: string): Promise<any> => trackPromise<ApiResponse>(api.get(`${url}/${id}/pdf`, headerConfig)),
  getIcs: (id: string): Promise<any> => trackPromise<ApiResponse>(api.get(`${url}/${id}/ics`, headerConfig)),
  getFile: (technicalVisitId: string, fileId: string): Promise<any> =>
    trackPromise<ApiResponse>(api.get(`${url}/${technicalVisitId}/file/${fileId}`, headerConfig)),
  getFilesById: (id: string): Promise<ApiResponse> => trackPromise<ApiResponse>(api.get(`${url}/${id}/file`)),
  listMaintenanceAction: (id: string, technicalVisitEquipmentId: string): Promise<ApiResponse> =>
    trackPromise<ApiResponse>(api.get(`${url}/${id}/technical-visit-equipment/${technicalVisitEquipmentId}/maintenance-action`)),
  listMaintenanceInspection: (id: string, technicalVisitEquipmentId: string): Promise<ApiResponse> =>
    trackPromise<ApiResponse>(api.get(`${url}/${id}/technical-visit-equipment/${technicalVisitEquipmentId}/maintenance-inspection`)),
  create: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(url, request)),
  addEquipment: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/equipment`, request)),
  addMaintenanceAction: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/equipment/maintenance-action`, request)),
  addMaintenanceInspection: (request: any): Promise<ApiResponse> =>
    trackPromise<ApiResponse>(api.post(`${url}/equipment/maintenance-inspection`, request)),
  addPart: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/part`, request)),
  addFile: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/file`, request)),
  search: (request: DataTableRequest): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/search`, request)),
  searchEquipment: (request: DataTableRequest): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/search/equipment`, request)),
  searchPart: (request: DataTableRequest): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/search/part`, request)),
  update: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.put(url, request)),
  delete: (id: string): Promise<ApiResponse> => trackPromise<ApiResponse>(api.delete(`${url}/${id}`)),
  removeEquipment: (id: string, equipmentId: string): Promise<ApiResponse> =>
    trackPromise<ApiResponse>(api.delete(`${url}/${id}/equipment/${equipmentId}`)),
  removeMaintenanceAction: (id: string, equipmentId: string, actionId: string): Promise<ApiResponse> =>
    trackPromise<ApiResponse>(api.delete(`${url}/${id}/equipment/${equipmentId}/action/${actionId}`)),
  removeMaintenanceInspection: (id: string, equipmentId: string, inspectionId: string): Promise<ApiResponse> =>
    trackPromise<ApiResponse>(api.delete(`${url}/${id}/equipment/${equipmentId}/inspection/${inspectionId}`)),
  removePart: (id: string, partId: string, quantity: number): Promise<ApiResponse> =>
    trackPromise<ApiResponse>(api.delete(`${url}/${id}/part/${partId}/${quantity}`)),
  removeFile: (technicalVisitId: string, id: string): Promise<ApiResponse> =>
    trackPromise<ApiResponse>(api.delete(`${url}/${technicalVisitId}/file/${id}`)),
}

export default Api
