import clientApi from 'services/sale/client'
import equipmentApi from 'services/inventory/equipment'

export const handleGetEquipment = async (clientId: string, equipmentId: string) => {
  let res = await clientApi.getTechnicalStartByEquipmentId(clientId, equipmentId)

  return res?.data?.data
}

export const handleCreateOrUpdateTechnicalStart = async (values: any) => {
  let res = await equipmentApi.createOrUpdateTechnicalStart(values)

  return res?.data?.success
}
