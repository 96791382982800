import { trackPromise } from 'react-promise-tracker'

import { ApiResponse } from 'utils/types'
import api from 'utils/api'

const url = '/v1/technical-visit'
const Api = {
  get: (id: string): Promise<ApiResponse> => trackPromise<ApiResponse>(api.get(`${url}/${id}/finish`)),
  getNextVisitEquipment: (id: string): Promise<ApiResponse> => trackPromise<ApiResponse>(api.get(`${url}/${id}/equipment/next-technical-visit`)),
  finishEquipment: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/equipment/finish`, request)),
  finishTechnicalVisit: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/finish`, request)),
}

export default Api
