export const getEquipmentTypeDescription = (status: string): string => {
  return EquipmentTypeOptions.filter(x => x.value === status?.toString())[0]?.label
}
export interface EquipmentTypeOption {
  value: EquipmentTypeEnum | null
  label: string
}

export enum EquipmentTypeEnum {
  Generator = 'Generator',
  Compressor = 'Compressor',
  RefrigerationDryer = 'RefrigerationDryer',
  AdsorptionDryer = 'AdsorptionDryer',
  Purger = 'Purger',
  LineFilter = 'LineFilter',
  CoalTower = 'CoalTower',
  PumpVacuum = 'PumpVacuum',
  Filler = 'Filler',
}

export const EquipmentTypeOptions: Array<EquipmentTypeOption> = [
  { value: null, label: '' },
  { value: EquipmentTypeEnum.Generator, label: 'Gerador' },
  { value: EquipmentTypeEnum.Compressor, label: 'Compressor' },
  { value: EquipmentTypeEnum.RefrigerationDryer, label: 'Secador de Refrigeração' },
  { value: EquipmentTypeEnum.AdsorptionDryer, label: 'Secadir de Adsorção' },
  { value: EquipmentTypeEnum.Purger, label: 'Purgador' },
  { value: EquipmentTypeEnum.LineFilter, label: 'Filtro de Linha' },
  { value: EquipmentTypeEnum.CoalTower, label: 'Torre de Carvão' },
  { value: EquipmentTypeEnum.PumpVacuum, label: 'Bomba de Vácuo' },
  { value: EquipmentTypeEnum.Filler, label: 'Envasadora' },
]
