import { trackPromise } from 'react-promise-tracker'

import api from 'utils/api'
import { ApiResponse } from 'utils/types'
import { DataTableRequest } from 'components/data-table/types'

const url = '/v1/maintenance-schedule'
const Api = {
  get: (id: string): Promise<ApiResponse> => trackPromise<ApiResponse>(api.get(`${url}/${id}`)),
  listActionsByEquipmentId: (equipmentId: string): Promise<ApiResponse> =>
    trackPromise<ApiResponse>(api.get(`${url}/equipment/${equipmentId}/action`)),
  listInspectionByEquipmentId: (equipmentId: string): Promise<ApiResponse> =>
    trackPromise<ApiResponse>(api.get(`${url}/equipment/${equipmentId}/inspection`)),
  create: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(url, request)),
  createAction: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/action`, request)),
  createInspection: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/inspection`, request)),
  search: (request: DataTableRequest): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/search`, request)),
  autocomplete: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/autocomplete`, request)),
  update: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.put(url, request)),
  updateAction: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.put(`${url}/action`, request)),
  addHoursAction: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.put(`${url}/action/add-hours`, request)),
  subtractHoursAction: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.put(`${url}/action/subtract-hours`, request)),
  delete: (id: string): Promise<ApiResponse> => trackPromise<ApiResponse>(api.delete(`${url}/${id}`)),
  removeInspection: (maintenanceScheduleId: string, inspectionId: string): Promise<ApiResponse> =>
    trackPromise<ApiResponse>(api.delete(`${url}/${maintenanceScheduleId}/inspection/${inspectionId}`)),
}

export default Api
