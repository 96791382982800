import Yup from 'utils/yup'

export const defaultValues = {
  responsibleTechnician: {
    id: null,
  },
  workRegime: null,
  date: null,
  voltage: null,
  chainR: null,
  chainS: null,
  chainT: null,
  motorThermalRegulation: null,
  compressorCableGauge: null,
  compressorTemperatureInRelief: null,
  compressorTemperatureAtLoad: null,
  purgersTested: null,
  airReservoirFillingTime: null,
  airReservoirDischargeTime: null,
  oxygenReservoirFillingTime: null,
  dryerDewPoint: null,
  generatorDewPoint: null,
  generatorPurityDegree: null,
}

export const validationSchema = Yup.object().shape({
  responsibleTechnician: Yup.object({
    id: Yup.string().nullable().required(),
  }),
  workRegime: Yup.number().nullable().required(),
  date: Yup.date().nullable().required(),
  voltage: Yup.string().nullable(),
  chainR: Yup.number().nullable(),
  chainS: Yup.number().nullable(),
  chainT: Yup.number().nullable(),
  motorThermalRegulation: Yup.number().nullable(),
  compressorCableGauge: Yup.number().nullable(),
  compressorTemperatureInRelief: Yup.number().nullable(),
  compressorTemperatureAtLoad: Yup.number().nullable(),
  purgersTested: Yup.string().nullable(),
  airReservoirFillingTime: Yup.string().nullable(),
  airReservoirDischargeTime: Yup.string().nullable(),
  oxygenReservoirFillingTime: Yup.string().nullable(),
  dryerDewPoint: Yup.number().nullable(),
  generatorDewPoint: Yup.number().nullable(),
  generatorPurityDegree: Yup.number().nullable(),
})
