import React from 'react'
import { Text, Grid } from 'react-md'
import { FormContext, FormContextValues } from 'react-hook-form'

import { Input, InputDatePicker, InputDateTimePicker, InputDecimal, Select } from 'components/form'
import { Cell } from 'components/core'

import { ChanceClosingOptions, ProposalStatusOptions } from 'types/sale/proposal'
import { TextLabel } from 'components/label'

interface ComponentProps {
  form: FormContextValues<any>
  proposal: any
}

const Component: React.FC<ComponentProps> = ({ form, proposal }) => {
  return (
    <Grid>
      <Cell>
        <Text type='headline-6' margin='none'>
          Informações Gerais
        </Text>
      </Cell>
      <Cell>
        <Grid>
          <FormContext {...form}>
            <TextLabel name='clientFantasyName' label='Cliente' value={proposal.clientFantasyName} desktopSize={6} tabletSize={4} />
            <Input name='description' label='Descrição *' desktopSize={6} tabletSize={4} />
            <InputDatePicker name='followUpDate' label='Data de Follow-Up' desktopSize={3} tabletSize={4} />
            <InputDateTimePicker name='lastContactDate' label='Último Contato' desktopSize={3} tabletSize={4} />
            <InputDecimal name='totalValue' label='Valor Total *' desktopSize={2} tabletSize={4} />
            <Select name='chanceClosing' label='Chance de Fechamento *' options={ChanceClosingOptions} desktopSize={2} tabletSize={4} />
            <Select name='proposalStatus' label='Status *' options={ProposalStatusOptions} desktopSize={2} tabletSize={4} />
          </FormContext>
        </Grid>
      </Cell>
    </Grid>
  )
}

export default Component
