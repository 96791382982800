import React from 'react'
import { Card, CardContent, Text } from 'react-md'

interface Props {
  text: string
  type: CalloutType
}

export enum CalloutType {
  Success = '#81C784',
  Info = '#4FC3F7',
  Warning = '#FFF176',
  Error = '#E57373',
}

const Callout: React.FC<Props> = ({ text, type }) => {
  return (
    <>
      <Card style={{ backgroundColor: type }} fullWidth>
        <CardContent style={{ paddingBottom: '1rem' }}>
          <Text type='body-2' style={{ color: 'black' }}>
            {text}
          </Text>
        </CardContent>
      </Card>
    </>
  )
}

export default React.memo(Callout)
