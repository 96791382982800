import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Card, CardActions, CardContent, FontIcon } from 'react-md'

import { addressDefaultValues, addressValidationSchema } from 'components/address/config'
import { ButtonLog, ButtonSuccess } from 'components/core'

import { infoDefaultValues, infoValidationSchema } from './info/config'
import { handleGet, handleSave, handleValidateForms } from './actions'

import Address from './address'
import Info from './info'

interface ComponentProps {
  id: string
}

const Component: React.FC<ComponentProps> = ({ id }) => {
  const infoForm = useForm({ defaultValues: infoDefaultValues })
  const addressForm = useForm({ defaultValues: addressDefaultValues })

  useEffect(() => {
    const load = async () => {
      const res = await handleGet(id)
      if (res) {
        infoForm.reset(res)
        addressForm.reset(res.address)
      }
    }

    load()

    return () => {}
  }, [id])

  return (
    <Card fullWidth>
      <CardContent>
        <Info form={infoForm} />
        <Address form={addressForm} />
      </CardContent>
      <CardActions>
        <ButtonSuccess
          text='Salvar'
          icon={<FontIcon>done</FontIcon>}
          disabled={!infoForm.formState.dirty && !addressForm.formState.dirty}
          onClick={async () => {
            if (
              await handleValidateForms(
                { form: infoForm, validationSchema: infoValidationSchema },
                { form: addressForm, validationSchema: addressValidationSchema },
              )
            ) {
              if (await handleSave({ id: id, ...infoForm.getValues(), address: addressForm.getValues() })) {
                infoForm.reset(infoForm.getValues(), { dirty: false })
                addressForm.reset(addressForm.getValues(), { dirty: false })
              }
            }
          }}
        />
        <ButtonLog entity='Client' id={id} />
      </CardActions>
    </Card>
  )
}

export default Component
