import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { FormContext, useForm } from 'react-hook-form'
import { Grid, Card, CardActions, CardContent, FontIcon, TabsManager, TabPanel, Tabs, TabPanels } from 'react-md'

import { ButtonLog, Cell, PageTitle, ButtonSuccess } from 'components/core'
import { handleSubmit, Input } from 'components/form'

import { defaultValues, validationSchema } from './config'
import { handleSave, handleGet } from './actions'

import Permissions from './components/permissions'

const Component: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const form = useForm({ defaultValues })
  const [permissions, setPermissions] = useState<Array<any>>([])

  useEffect(() => {
    document.title = 'Edição de  Perfil'
    form.register({ name: 'permissions' })
  }, [form.register])

  useEffect(() => {
    loadProfile()
  }, [id])

  const loadProfile = () => {
    handleGet(id).then(res => {
      if (res) {
        form.reset(res)
        setPermissions(res.permissions.sort((a: any, b: any) => a.permission > b.permission))
      }
    })
  }

  return (
    <Grid>
      <Cell>
        <PageTitle title='Edição de Perfil' />
        <TabsManager tabs={['Informações Gerais', 'Permissões']} tabsId='profile-tabs'>
          <Tabs />
          <TabPanels persistent={true}>
            <TabPanel>
              <Card fullWidth>
                <CardContent>
                  <FormContext {...form}>
                    <Grid>
                      <Input name='name' label='Nome *' desktopSize={12} tabletSize={8} />
                    </Grid>
                  </FormContext>
                </CardContent>
                <CardActions align='end'>
                  <ButtonSuccess
                    text='Salvar'
                    icon={<FontIcon>done</FontIcon>}
                    disabled={!form.formState.dirty}
                    onClick={async () => {
                      await handleSubmit(form, validationSchema, async () => {
                        if (await handleSave({ ...form.getValues(), id: id })) form.reset(form.getValues(), { dirty: false })
                      })
                    }}
                  />
                  <ButtonLog entity='Profile' id={id} />
                </CardActions>
              </Card>
            </TabPanel>
            <TabPanel>
              <Permissions id={id} permissionList={permissions} loadProfile={loadProfile} />
            </TabPanel>
          </TabPanels>
        </TabsManager>
      </Cell>
    </Grid>
  )
}

export default Component
